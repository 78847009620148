<template>
  <div>
    <div class="mb-16 flex">
      <router-link class="flex" to="/">
        <img
          width="63"
          height="45"
          class="mr-4"
          alt="Логотип"
          src="/../img/logo.svg"
        />
        <p class="mr-4 mb-0 font-medium text-[var(--bc)]">
          Design System <span class="block">WMStudio</span>
        </p>
      </router-link>
      <div class="ml-auto mr-2" v-if="false">
        <button
          @click.prevent="chooseDarkTheme"
          class="dark-btn p-1"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path
              d="M12.025 20.5q-3.55 0-6.025-2.475Q3.525 15.55 3.525 12q0-3.4 2.3-5.825 2.3-2.425 5.65-2.625.2 0 .413.012.212.013.412.038-.775.725-1.225 1.737-.45 1.013-.45 2.163 0 2.45 1.725 4.175 1.725 1.725 4.175 1.725 1.175 0 2.175-.45 1-.45 1.7-1.225.05.2.063.412.012.213.012.413-.2 3.35-2.625 5.65-2.425 2.3-5.825 2.3Zm0-1.5q2.2 0 3.95-1.212 1.75-1.213 2.55-3.163-.5.125-1 .2-.5.075-1 .075-3.075 0-5.237-2.162Q9.125 10.575 9.125 7.5q0-.5.075-1t.2-1q-1.95.8-3.162 2.55Q5.025 9.8 5.025 12q0 2.9 2.05 4.95Q9.125 19 12.025 19Zm-.25-6.75Z"
              fill="#5BC9FC"
            />
          </svg>
        </button>
        <button
          @click.prevent="chooseLightTheme"
          class="light-btn p-1"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path
              d="M12 15q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15Zm0 1.5q-1.875 0-3.188-1.312Q7.5 13.875 7.5 12q0-1.875 1.312-3.188Q10.125 7.5 12 7.5q1.875 0 3.188 1.312Q16.5 10.125 16.5 12q0 1.875-1.312 3.188Q13.875 16.5 12 16.5ZM2 12.75q-.325 0-.538-.213-.212-.212-.212-.537 0-.325.212-.538.213-.212.538-.212h2.25q.325 0 .537.212Q5 11.675 5 12q0 .325-.213.537-.212.213-.537.213Zm17.75 0q-.325 0-.538-.213Q19 12.325 19 12q0-.325.212-.538.213-.212.538-.212H22q.325 0 .538.212.212.213.212.538 0 .325-.212.537-.213.213-.538.213ZM12 5q-.325 0-.537-.213-.213-.212-.213-.537V2q0-.325.213-.538.212-.212.537-.212.325 0 .538.212.212.213.212.538v2.25q0 .325-.212.537Q12.325 5 12 5Zm0 17.75q-.325 0-.537-.212-.213-.213-.213-.538v-2.25q0-.325.213-.538Q11.675 19 12 19q.325 0 .538.212.212.213.212.538V22q0 .325-.212.538-.213.212-.538.212ZM6 7.05 4.75 5.825q-.225-.225-.213-.538.013-.312.213-.537.225-.225.538-.225.312 0 .537.225L7.05 6q.225.225.225.525 0 .3-.225.525-.2.225-.5.212-.3-.012-.55-.212Zm12.175 12.2L16.95 18q-.225-.225-.225-.525 0-.3.225-.525.2-.225.5-.212.3.012.55.212l1.25 1.225q.225.225.213.537-.013.313-.213.538-.225.225-.537.225-.313 0-.538-.225ZM16.95 7.05q-.225-.2-.212-.5.012-.3.212-.55l1.225-1.25q.225-.225.538-.213.312.013.537.213.225.225.225.537 0 .313-.225.538L18 7.05q-.225.225-.525.225-.3 0-.525-.225Zm-12.2 12.2q-.225-.225-.225-.538 0-.312.225-.537L6 16.95q.225-.225.525-.225.3 0 .525.225.225.2.213.5-.013.3-.213.55l-1.225 1.25q-.225.225-.537.225-.313 0-.538-.225ZM12 12Z"
              fill="#5BC9FC"
            />
          </svg>
        </button>
      </div>
      <div class="relative ml-auto self-center">
        <button
          type="button"
          class="ui-link"
          @click.prevent="clickDropdownTheme"
        >
          <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="mr-2 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
            ></path>
          </svg>
          Тема
          <span
            class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul class="dropdown-theme" v-if="showDropdownTheme">
          <li>
            <button @click="chooseLightTheme" class="ui-link" id="radioLight">
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-check-24.svg')] filter-none"
              ></span>
              light
              <span class="tc-primary"></span>
              <span class="tc-secondary"></span>
            </button>
          </li>
          <li>
            <button @click="chooseDarkTheme" class="ui-link" id="radioDark">
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-check-24.svg')] filter-none"
              ></span>
              dark
              <span class="tc-primary"></span>
              <span class="tc-secondary"></span>
            </button>
          </li>
          <li>
            <button
              @click="chooseCupcakeTheme"
              class="ui-link"
              id="radioCupcake"
            >
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-check-24.svg')] filter-none"
              ></span>
              cupcake
              <span class="tc-primary"></span>
              <span class="tc-secondary"></span>
            </button>
          </li>
          <li>
            <button @click="chooseForestTheme" class="ui-link" id="radioForest">
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-check-24.svg')] filter-none"
              ></span>
              forest
              <span class="tc-primary"></span>
              <span class="tc-secondary"></span>
            </button>
          </li>
          <li>
            <button
              @click="chooseDraculaTheme"
              class="ui-link"
              id="radioDracula"
            >
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-check-24.svg')] filter-none"
              ></span>
              dracula
              <span class="tc-primary"></span>
              <span class="tc-secondary"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <ul class="m-0 list-none p-0">
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/accordion"
          class="ui-link w-full"
        >
          Accordion
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/alerts"
          class="ui-link w-full"
        >
          Alerts
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/avatar"
          class="ui-link w-full"
        >
          Avatar
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/badges"
          class="ui-link w-full"
        >
          Badges
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/breadcrumb"
          class="ui-link w-full"
        >
          Breadcrumb
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-btn'"
        >
          Buttons
          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-btn'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-close"
            >
              Buttons close
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-icon"
            >
              Buttons icon
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-outline"
            >
              Buttons outline
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-outline-icon"
            >
              Buttons outline icon
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-painted"
            >
              Buttons painted
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/buttons-painted-icon"
            >
              Buttons painted icon
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-cards'"
        >
          Cards
          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-cards'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/cards-art"
            >
              Cards art
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/cards-shop"
            >
              Cards shop
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-checkbox'"
        >
          Checkbox/Radio
          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-checkbox'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/checkboxes"
            >
              Checkboxes
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/radios"
            >
              Radio buttons
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/switches"
            >
              Switches
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/colors"
          class="ui-link w-full"
        >
          Colors
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-fields'"
        >
          <span> Fields</span>
          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-fields'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-datepicker"
            >
              Fields Datepicker
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-fix-text"
            >
              Fix text fields
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-password"
            >
              Password
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-phone"
            >
              Phone fields
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-search"
            >
              Search fields
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-select"
            >
              Select fields
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-range"
            >
              Range
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-textarea"
            >
              Textarea
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input"
            >
              Text fields
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-input-counter"
            >
              Fields counter
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/fields-upload-file"
            >
              Upload a file
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/filters"
          class="ui-link w-full"
        >
          Filters
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/icons"
          class="ui-link w-full"
        >
          Icons
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-links'"
        >
          <span> Links</span>
          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-links'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/links-icon"
            >
              Icon links
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/links-text"
            >
              Text links
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/modals"
          class="ui-link w-full"
        >
          Modals
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/navs-and-tabs"
          class="ui-link w-full"
        >
          <span>Navs & tabs</span>
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/pagination"
          class="ui-link w-full"
        >
          Pagination
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/popover"
          class="ui-link w-full"
        >
          <span>Popover</span>
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/progress"
          class="ui-link w-full"
        >
          Progress
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <button
          type="button"
          class="ui-link w-full"
          @click="activeMenu = 'choice-rating'"
        >
          <span>Rating</span>

          <span
            class="ui-link__ic ml-auto -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          ></span>
        </button>
        <ul v-if="activeMenu === 'choice-rating'" class="m-0 block pl-0 pt-4">
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/rating-scale"
            >
              Rating scale
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/rating-stars"
            >
              Rating stars
            </router-link>
          </li>
          <li class="mb-3">
            <router-link
              class="table text-xs"
              style="color: var(--filter-black)"
              exact-active-class="text-[var(--p)]"
              to="/rating-text"
            >
              Rating text
            </router-link>
          </li>
        </ul>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/skeleton"
          class="ui-link w-full"
        >
          Skeleton
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/table"
          class="ui-link w-full"
        >
          Table
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/toasts"
          class="ui-link w-full"
        >
          Toasts
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/tooltip"
          class="ui-link w-full"
        >
          <span>Tooltip</span>
        </router-link>
      </li>
      <li class="ui-nav-dropdown-item mb-8">
        <router-link
          exact-active-class="text-[var(--p)]"
          to="/typography"
          class="ui-link w-full"
        >
          <span>Typography</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      activeMenu: null,
      showDropdownTheme: null,
    }
  },
  methods: {
    clickDropdownTheme() {
      this.showDropdownTheme = !this.showDropdownTheme
    },
    chooseLightTheme() {
      this.$emit('chooseLightTheme')
    },
    chooseDarkTheme() {
      this.$emit('chooseDarkTheme')
    },
    chooseCupcakeTheme() {
      this.$emit('chooseCupcakeTheme')
    },
    chooseForestTheme() {
      this.$emit('chooseForestTheme')
    },
    chooseDraculaTheme() {
      this.$emit('chooseDraculaTheme')
    },
  },
}
</script>

<style scoped lang="scss">
#app {
  &.dark {
    .dark-btn {
      display: none;
    }
  }

  &:not(.dark) {
    .light-btn {
      display: none;
    }
  }
}
.dropdown-theme {
  position: absolute;
  width: 220px;
  max-height: 300px;
  overflow: auto;
  background: var(--b1);
  top: 100%;
  right: 0;
  padding: 8px;
  border-radius: 6px;
  li {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    .ui-link {
      color: #000;
      width: 100%;
      background: #f0f6f6;
      padding: 8px;
      border-radius: 6px;
      cursor: pointer;
      &.active .ui-link__ic {
        opacity: 1;
      }
      .ui-link__ic {
        opacity: 0;
        filter: none;
      }
      .tc-primary {
        margin-left: auto;
      }
      .tc-primary,
      .tc-secondary {
        width: 12px;
        height: 24px;
        border-radius: 6px;
        margin-right: 4px;
      }
      &#radioLight {
        background: #fff;
        .tc-primary {
          background: #570df8;
        }
        .tc-secondary {
          background: #f000b8;
        }
        #app.light & {
          .ui-link__ic {
            opacity: 1;
          }
        }
      }
      &#radioDark {
        background: #272a2c;
        color: #fff;
        .tc-primary {
          background: #5bc9fc;
        }
        .tc-secondary {
          background: #9747ff;
        }
        #app.dark & {
          .ui-link__ic {
            opacity: 1;
          }
        }
        .ui-link__ic {
          filter: invert(96%) sepia(36%) saturate(0%) hue-rotate(232deg)
            brightness(105%) contrast(102%) !important;
        }
      }
      &#radioCupcake {
        background: #faf7f5;
        #app.cupcake & {
          .ui-link__ic {
            opacity: 1;
          }
        }
        .tc-primary {
          background: #65c3c8;
        }
        .tc-secondary {
          background: #ef9fbc;
        }
      }
      &#radioForest {
        background: #141010;
        color: #cac9c9;
        #app.forest & {
          .ui-link__ic {
            opacity: 1;
          }
        }
        .tc-primary {
          background: #1eb854;
        }
        .tc-secondary {
          background: #1db990;
        }
        .ui-link__ic {
          filter: brightness(0) saturate(100%) invert(94%) sepia(0%)
            saturate(1727%) hue-rotate(13deg) brightness(89%) contrast(85%) !important;
        }
      }
      &#radioDracula {
        background: #272935;
        color: #f8f8f2;
        #app.dracula & {
          .ui-link__ic {
            opacity: 1;
          }
        }
        .tc-primary {
          background: #ff7ac6;
        }
        .tc-secondary {
          background: #bf95f9;
        }
        .ui-link__ic {
          filter: invert(96%) sepia(36%) saturate(0%) hue-rotate(232deg)
            brightness(105%) contrast(102%) !important;
        }
      }
    }
  }
}
</style>
