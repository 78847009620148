<template>
  <div class="ui-popover">
    <div class="ui-popover-container">
      <p class="mb-4 text-lg font-semibold">{{ title }}</p>
      <p class="mb-0">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uiPopover',
  props: {
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
}
</script>
