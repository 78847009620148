<template>
  <div class="ui-input">
    <label class="ui-input__label">
      {{ label }}
    </label>
    <div class="ui-input__field">
      <date-picker
        :class="{ error: error }"
        format="DD.MM.YYYY"
        :lang="lang"
        :confirm="false"
        v-model="time"
        valueType="format"
      ></date-picker>
    </div>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: 'uiDatepicker',
  components: { DatePicker },
  data() {
    return {
      time: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>
<style lang="scss">
.mx-datepicker {
  width: 100%;

  &.error {
    .mx-input-wrapper {
      .mx-input {
        border: 1px solid var(--e);
      }
    }
  }

  .mx-input-wrapper {
    position: relative;
    width: 100%;

    .mx-input {
      width: 100%;
      border: 1px solid var(--b4);
      border-radius: 6px;
      padding: 0 48px 0 16px;
      height: 56px;
      background: transparent;
      color: var(--bc);
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }

    .mx-icon-calendar,
    .mx-icon-clear {
      width: 24px;
      height: 24px;
      right: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      filter: var(--filter-black);

      svg {
        display: none;
      }
    }
    .mx-icon-calendar {
      background-image: url('@/assets/img/ic-datepicker-24.svg');
    }
    .mx-icon-clear {
      background-image: url('@/assets/img/ic-cross-24.svg');
    }
  }
}

.mx-datepicker-main.mx-datepicker-popup {
  font-family: Open Sans, sans-serif;
  .mx-datepicker-content {
    .mx-datepicker-body {
      .mx-calendar.mx-calendar-panel-date {
        .mx-calendar-header {
        }

        .mx-calendar-content {
          .mx-table.mx-table-date {
            thead {
              tr {
                th {
                }
              }
            }

            tbody {
              tr {
                &.mx-date-row {
                }

                td {
                  &.cell {
                  }

                  &.not-current-month {
                  }

                  &.today {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mx-calendar-header-label {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: var(--p);
    font-weight: 600;
  }
  .mx-btn {
    font-size: 16px;
    line-height: 24px;
    color: var(--p);
    font-weight: 600;

    &.mx-btn-icon-double-left,
    &.mx-btn-icon-double-right {
      width: 24px;
      height: 24px;
      font-size: 0;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: none;
    }

    &.mx-btn-icon-left,
    &.mx-btn-icon-right {
      width: 24px;
      height: 24px;
      font-size: 0;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: flex;
    }

    .mx-icon-left:before,
    .mx-icon-double-left:before {
      transform: rotate(-45deg) scale(1);
    }
    .mx-icon-right:before,
    .mx-icon-double-right:before {
      transform: rotate(135deg) scale(1);
    }

    .mx-icon-left,
    .mx-icon-right,
    .mx-icon-double-left,
    .mx-icon-double-right {
      &:before {
        width: 10px;
        height: 10px;
        border-width: 2px 0 0 2px;
        border-color: var(--p);
        top: 0;
      }
      &:after {
        display: none;
      }
    }
  }
  .mx-table {
    thead {
      background: transparent;
      th {
        height: 22px;
        line-height: 22px;
        font-size: 10px;
        text-align: center;
        font-weight: 600;
        color: var(--b4);
      }
    }
    tbody {
      tr {
        td {
          background: transparent;
          font-size: 14px;
          color: var(--bc);
          font-weight: 600;
          border: none;
          div {
            border: 1px solid transparent;
            width: 24px;
            height: 24px;
            border-radius: 6px;
            margin: auto;
          }
          &.today {
            div {
              border-color: var(--p);
            }
          }
          &:hover {
            background: transparent;
            div {
              border-color: var(--pf);
            }
          }
          &.active {
            div {
              border-color: var(--p);
              background: var(--p);
              color: var(--b1);
            }
          }
          &.not-current-month {
            color: var(--b4);
          }
        }
      }
    }

    &.mx-table-month,
    &.mx-table-year {
      td {
        font-size: 12px;
        border: none;

        background: transparent;
        padding: 4px 3px;
        div {
          background-color: #f0f6f6;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
        }

        &.active {
          color: var(--b1);
          div {
            background-color: var(--p);
          }
        }
        &:hover {
          color: var(--b1);
          div {
            background-color: var(--pf);
          }
        }
      }
    }
  }
  .mx-calendar {
    &.mx-calendar-panel-month {
      .mx-btn {
        &.mx-btn-icon-double-left,
        &.mx-btn-icon-double-right {
          display: flex;
        }
      }
    }
  }
}
</style>
