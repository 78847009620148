<template>
  <div class="ui-input">
    <label class="ui-input__label" :for="id">{{ label }}</label>
    <div class="ui-input__field">
      <input
        class="ui-input__field--input"
        :class="inputClass"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        :id="id"
      />
      <button
        type="button"
        class="ui-input__field--btn bg-[url('./img/ic-search-24.svg')]"
      ></button>
      <button
        v-if="false"
        type="button"
        class="ui-btn-primary ui-btn-sm ui-btn absolute right-1 top-[50%] translate-y-[-50%]"
      >
        <span class="ui-btn__ic bg-[url('./img/ic-search-24.svg')]"></span>
        Все результаты
      </button>
    </div>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
    <div class="ui-input__dropdown">
      <div class="mb-4 w-full">
        <p class="ui-input__dropdown--title" v-if="sectionItems">
          Открыть раздел:
        </p>
        <div class="flex w-full flex-wrap items-center">
          <a
            class="ui-badge ui-badge-lg ui-badge-primary mr-4 mb-2"
            v-for="sectionItem in sectionItems"
            :key="sectionItem.id"
            :href="sectionItem.href"
          >
            {{ sectionItem.text }}
          </a>
        </div>
      </div>
      <div class="w-full">
        <a
          class="ui-input__dropdown--link"
          v-for="linkItem in linkItems"
          :key="linkItem.id"
          :href="linkItem.href"
        >
          <span class="ui-input__dropdown--link-img">
            <img
              v-if="linkItem.src"
              class="h-full w-full rounded-md"
              :src="linkItem.src"
              :alt="linkItem.alt"
            />
          </span>
          <span class="m-0 text-base"> {{ linkItem.text }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiInputSearch',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    sectionItems: {
      type: Array,
    },
    linkItems: {
      type: Array,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[var(--b2)]': this.variant === 'error',
      }
    },
  },
}
</script>
