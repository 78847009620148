<template>
  <div class="ui-skeleton-img-wrap">
    <transition name="fade">
      <div v-if="!src || loading" class="ui-skeleton-img"></div>
    </transition>

    <img
      v-show="!loading"
      :src="src"
      :alt="alt"
      :title="title"
      @load="completeLoading"
    />
  </div>
</template>
<script>
export default {
  name: 'uiSkeletonImg',
  props: {
    src: {
      type: String,
      require: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    completeLoading() {
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.ui-skeleton-img-wrap {
  border-radius: inherit;
}
.ui-skeleton-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #dddbdd;
  border-radius: inherit;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer2 5s infinite;
    content: '';
  }

  @keyframes shimmer2 {
    100% {
      transform: translateX(100%);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
