<template>
  <div class="w-full">
    <div class="mb-5 w-full">
      <p class="mb-3 font-medium">Ширина (см)</p>
      <div class="grid grid-cols-2 gap-3">
        <div class="ui-input-unite" @click="focusInputFrom">
          <label class="ui-input-unite__label" for="filterFrom">от</label>
          <input
            class="ui-input-unite__file"
            v-model="valueFrom"
            ref="inputFrom"
            id="filterFrom"
          />
        </div>
        <div class="ui-input-unite" @click="focusInputBefore">
          <label class="ui-input-unite__label" for="filterBefore">до</label>
          <input
            class="ui-input-unite__file"
            v-model="valueBefore"
            ref="inputBefore"
            id="filterBefore"
          />
        </div>
      </div>
    </div>
    <div class="mb-5 w-full" v-for="item in itemsFilter" :key="item.text">
      <p v-if="item.title" class="mb-5 font-medium">
        {{ item.title }}
      </p>
      <div
        class="mb-3 w-full"
        v-for="(link, index) of item.checks"
        :key="link.id"
      >
        <label
          class="ui-checkbox-primary ui-checkbox"
          :for="link.id"
          :class="{ hidden: index > 3 && item.isShow }"
        >
          <input
            :id="link.id"
            :disabled="link.disabled"
            type="checkbox"
            class="ui-checkbox__input"
          />
          {{ link.text }} ({{ link.count }})
        </label>
      </div>
      <button
        @click="toggleExpand(item)"
        class="ui-link mt-2 text-xs"
        type="button"
        v-if="item.checks.length > 4"
      >
        <span v-if="item.isShow">Еще {{ item.checks.length - 4 }}</span>
        <span v-else>Свернуть</span>
        <span
          class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          :class="{ 'rotate-180': !item.isShow }"
        >
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filterSidebar',
  data() {
    return {
      valueFrom: '10',
      valueBefore: '100',
      itemsFilter: [
        {
          title: 'Тип продукта',
          checks: [
            { id: 'id1', text: 'Шкафы', count: '10', disabled: false },
            { id: 'id2', text: 'Стулья', count: '87', disabled: true },
            { id: 'id3', text: 'Кровати', count: '456', disabled: false },
            { id: 'id4', text: 'Стол', count: '2', disabled: false },
            { id: 'id5', text: 'Тумба', count: '22', disabled: false },
          ],
          isShow: true,
        },
        {
          title: 'Тип продукта 2',
          checks: [
            { id: 'id6', text: 'Шкафы', count: '23', disabled: false },
            { id: 'id7', text: 'Стулья', count: '56', disabled: false },
          ],
          isShow: true,
        },
      ],
    }
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },

    focusInputFrom() {
      let inputFrom = this.$refs.inputFrom
      let endFrom = inputFrom.value.length
      inputFrom.focus()
      inputFrom.setSelectionRange(endFrom, endFrom)
    },

    focusInputBefore() {
      let inputBefore = this.$refs.inputBefore
      let endBefore = inputBefore.value.length
      inputBefore.focus()
      inputBefore.setSelectionRange(endBefore, endBefore)
    },
  },
}
</script>
