<template>
  <common-wrapper titleWrapper="Cards art">
    <template>
      <common-group titleGroup="Horizontal">
        <template>
          <common-item condition="Горизонтальная карточка новости">
            <div v-if="loading">
              <ui-card-art v-for="n in 1" :key="n" :loading="loading" />
            </div>
            <div v-else>
              <ui-card-art
                v-for="card in cards"
                :key="card.index"
                :href="card.href"
                :title="card.title"
                :img="card.img"
                :alt="card.alt"
                :text="card.text"
                :date="card.date"
                :name="card.name"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-art"&gt;
  &lt;a href="#" class="ui-card-art-img"&gt;
    &lt;img alt="alt" src="img"/&gt;
  &lt;/a&gt;
  &lt;div class="ui-card-art-body"&gt;
    &lt;div class="mb-3 w-full"&gt;
      &lt;a href="#" class="text-lg"&gt;Заголовок&lt;/a&gt;
    &lt;/div&gt;
    &lt;p class="mb-3"&gt;Однозначно, представители ...&lt;/p&gt;
    &lt;div class="mt-auto flex w-full justify-between"&gt;
      &lt;p class="text-xs"&gt;09.09.2022&lt;/p&gt;
      &lt;p class="text-xs"&gt;Анна Иванова&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>

&lt;ui-card-art
    v-for="card in cards"
    :key="card.index"
    :href="card.href"
    :title="card.title"
    :img="card.img"
    :alt="card.alt"
    :text="card.text"
    :date="card.date"
    :name="card.name"
/&gt;

&lt;script&gt;
import uiCardArt from '@/components/ui/uiCardArt.vue'

export default {
  name: 'CardArt',
  components: {
    'ui-card-art': uiCardArt,
  },
  data(){
    return{
      cards:[
        {
          title:'Заголовок новости',
          href:'#',
          alt:'alt',
          img:'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          text:'Однозначно, представители современных социальных резервов представляют собой не что иное, как квинтэссенцию победы маркетинга над разумом.',
          date:'09.09.2022',
          name:'Анна Иванова',
        }
      ],
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-card-art" :class="cardClass"&gt;
    &lt;a :href="href" class="ui-card-art-img"&gt;
      &lt;img :alt="alt" :src="img"/&gt;
    &lt;/a&gt;
    &lt;div class="ui-card-art-body"&gt;
      &lt;div class="mb-3 w-full"&gt;
        &lt;a :href="href" class="text-lg">&#123; &#123; title &#125; &#125; &lt;/a&gt;
      &lt;/div&gt;
      &lt;p class="mb-3"&gt;&#123; &#123; text &#125; &#125; &lt;/p&gt;
      &lt;div class="mt-auto flex w-full justify-between"&gt;
        &lt;p class="text-xs"&gt;&#123; &#123; date &#125; &#125; &lt;/p&gt;
        &lt;p class="text-xs"&gt;&#123; &#123; name &#125; &#125; &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "uiCardArt",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    img: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed:{
    cardClass() {
      return {
        'vertical': this.variant === 'vertical',
      }
    }
  }
}
&lt;/script&gt;
               </pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group titleGroup="Vertical">
        <template>
          <common-item condition="Вертикальная карточка новости">
            <div v-if="loading">
              <ui-card-art
                v-for="n in 1"
                :key="n"
                :loading="loading"
                variant="vertical"
              />
            </div>
            <div v-else>
              <ui-card-art
                href="#"
                title="Заголовок"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
                alt="alt"
                text="Однозначно, представители современных социальных резервов представляют собой не что иное, как квинтэссенцию победы маркетинга над разумом."
                date="09.09.2022"
                name="Анна Иванова"
                variant="vertical"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-art vertical"&gt;
  &lt;a href="#" class="ui-card-art-img"&gt;
    &lt;img alt="alt" src="img"/&gt;
  &lt;/a&gt;
  &lt;div class="ui-card-art-body"&gt;
    &lt;div class="mb-3 w-full"&gt;
      &lt;a href="#" class="text-lg"&gt;Заголовок&lt;/a&gt;
    &lt;/div&gt;
    &lt;p class="mb-3"&gt;Однозначно, представители ...&lt;/p&gt;
    &lt;div class="mt-auto flex w-full justify-between"&gt;
      &lt;p class="text-xs"&gt;09.09.2022&lt;/p&gt;
      &lt;p class="text-xs"&gt;Анна Иванова&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiCardArt from '@/components/ui/uiCardArt.vue'

export default {
  name: 'CardArt',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-card-art': uiCardArt,
  },
  data() {
    return {
      loading: true,
      cards: [
        {
          title: 'Заголовок новости',
          href: '#',
          alt: 'alt',
          img: 'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          text: 'Однозначно, представители современных социальных резервов представляют собой не что иное, как квинтэссенцию победы маркетинга над разумом.',
          date: '09.09.2022',
          name: 'Анна Иванова',
        },
      ],
    }
  },
  methods: {
    timeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
  },
  mounted() {
    this.timeLoading()
  },
}
</script>
