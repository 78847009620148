<template>
  <common-wrapper titleWrapper="Switches">
    <template>
      <common-group>
        <template>
          <common-item condition="Пассивный">
            <ui-switch
              variant="ui-switch-primary"
              :checked="false"
              id="switch"
              text="Первый вариант"
              name="name"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-switch ui-switch-primary" for="switch"&gt;
  &lt;input id="switch" type="checkbox" class="ui-switch__input"/&gt;
  &lt;span class="ui-switch__text"&gt; text &lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-switch
    variant="ui-switch-primary"
    :checked="false"
    :disabled="false"
    id="switch"
    text="Первый вариант"
    name="name"
/&gt;

&lt;script&gt;
import uiSwitch from '@/components/ui/uiSwitch.vue'

export default {
  name: 'CheckboxAndRadioSwitches',
  components: {
    'ui-switch': uiSwitch,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;label class="ui-switch" :for="id" :class="switchClass" &gt;
    &lt;input
        :id="id"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        class="ui-switch__input"
    /&gt;
    &lt;span class="ui-switch__text"&gt;&#123; &#123; text &#125; &#125;&lt;/span&gt;
  &lt;/label&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiSwitch',
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    text: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    switchClass() {
      return {
        'ui-switch-primary': this.variant === 'ui-switch-primary',
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Активный">
            <ui-switch
              variant="ui-switch-primary"
              :checked="true"
              id="switch2"
              text="Первый вариант"
              name="name"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-switch ui-switch-primary" for="switch"&gt;
  &lt;input id="switch" type="checkbox" class="ui-switch__input"/&gt;
  &lt;span class="ui-switch__text"&gt; text &lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Disabled">
            <ui-switch
              variant="ui-switch-primary"
              id="switch3"
              text="Первый вариант"
              name="name"
              disabled
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-switch ui-switch-primary" for="switch"&gt;
  &lt;input disabled id="switch" type="checkbox" class="ui-switch__input"/&gt;
  &lt;span class="ui-switch__text"&gt; text &lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiSwitch from '@/components/ui/uiSwitch.vue'

export default {
  name: 'CheckboxAndRadioSwitches',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-switch': uiSwitch,
  },
}
</script>
