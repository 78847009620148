<template>
  <label class="ui-checkbox" :for="id" :class="checkboxClass">
    <input
      :id="id"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      class="ui-checkbox__input"
    />
    {{ text }}
  </label>
</template>
<script>
export default {
  name: 'uiCheckbox',
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    text: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    checkboxClass() {
      return {
        'ui-checkbox-primary': this.variant === 'ui-checkbox-primary',
      }
    },
  },
}
</script>
