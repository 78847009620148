<template>
  <button
    type="button"
    class="ui-btn-ic"
    :class="buttonClass"
    :disabled="disabled"
  >
    <img class="ui-btn-ic__img" :src="src" :alt="alt" />
  </button>
</template>

<script>
export default {
  name: 'uiButtonIcon',
  props: {
    variant: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    src: {
      required: true,
      type: String,
      default: null,
    },
    alt: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        'ui-btn-ic-primary': this.variant === 'ui-btn-ic-primary',
        'ui-btn-ic-sm': this.small === true,
      }
    },
  },
}
</script>

<style scoped></style>
