<template>
  <common-wrapper
    title-wrapper="Badges"
    description-wrapper="Используйте badges в качестве элементов, чтобы отображать счетчики или метки отдельно или внутри других компонентов."
  >
    <template>
      <common-group title-group="Значок primary">
        <template>
          <common-item condition="Основной">
            <ui-badge text="Хлебобулочные изделия" variant="ui-badge-primary" />
            <template #html-slot>
              <pre>
&lt;div class="ui-badge ui-badge-primary"&gt; Хлебобулочные изделия &lt;/div&gt;</pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-badge text="Хлебобулочные изделия " variant="ui-badge-primary"/&gt;

&lt;script&gt;
import uiBadge from '@/components/ui/uiBadge.vue'

export default {
  name: 'BadgesPage',
  components: {
    'ui-badge': uiBadge,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-badge" :class="badgeClass"&gt;
    &#123; &#123; text &#125; &#125;
    &lt;button v-if="close" type="button" class="ui-badge__close"&gt;&lt;/button&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "uiBadge",
  props:{
    close: {
      type: Boolean,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: true,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    large: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    badgeClass() {
      return {
        'ui-badge-sm': this.small === true,
        'ui-badge-lg': this.large === true,
        'ui-badge-primary': this.variant === 'ui-badge-primary',
        'ui-badge-secondary': this.variant === 'ui-badge-secondary',
      }
    },
  },
}
&lt;/script&gt;
&lt;style lang="scss" scoped&gt;
.router-link-active{
  .ui-badge{
    margin-bottom: 0;
  }
  &:hover{
    .ui-badge-primary{
      background-color: var(--ph);
    }
    .ui-badge-secondary{
      background-color: var(--sh);
    }
  }
  &:active{
    .ui-badge-primary{
      background-color: var(--pf);
    }
    .ui-badge-secondary{
      background-color: var(--sf);
    }
  }
}
&lt;/style&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="С крестиком">
            <ui-badge
              text="Хлебобулочные изделия "
              variant="ui-badge-primary"
              :close="true"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-badge ui-badge-primary" &gt;
  &lt;span&gt;Хлебобулочные изделия&lt;/span&gt;
  &lt;button type="button" class="ui-badge__close" &gt;&lt;/button&gt;
&lt;/div&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="Ссылка">
            <router-link to="/badges" tag="a">
              <ui-badge
                text="Хлебобулочные изделия"
                variant="ui-badge-primary"
              />
            </router-link>

            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-badge ui-badge-primary" &gt; Хлебобулочные изделия &lt;/a&gt;</pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;router-link to="/badges" tag="a"&gt;
  &lt;ui-badge text="Хлебобулочные изделия" variant="ui-badge-primary" /&gt;
&lt;/router-link&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>

      <common-group
        title-group="Размеры"
        text-group="Используй класс размера такие как: large, small"
      >
        <template>
          <common-item condition="Основной">
            <ui-badge text="размер 48px" variant="ui-badge-primary" large />
            <ui-badge text="размер 32px" variant="ui-badge-primary" />
            <ui-badge text="размер 24px" variant="ui-badge-primary" small />
            <template #html-slot>
              <pre>
&lt;div class="ui-badge ui-badge-primary ui-badge-lg"&gt;размер 48px&lt;/div&gt;
&lt;div class="ui-badge ui-badge-primary"&gt;размер 32px&lt;/div&gt;
&lt;div class="ui-badge ui-badge-primary ui-badge-sm"&gt;размер 24px&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
  &lt;ui-badge text="размер 48px"
               variant="ui-badge-primary"
               large
  /&gt;

  &lt;ui-badge text="размер 32px"
               variant="ui-badge-primary"
  /&gt;

  &lt;ui-badge text="размер 24px"
               variant="ui-badge-primary"
               small
  /&gt;
</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>

      <common-group
        title-group="Скругление"
        text-group="Используй класс скруглния с любым значением, такие как: rounded-[6px], rounded-[9px], rounded-lg и тд."
      >
        <template>
          <common-item condition="Основной">
            <ui-badge
              text="размер 48px"
              variant="ui-badge-primary"
              class="rounded-[6px]"
              large
            />
            <ui-badge
              text="размер 32px"
              variant="ui-badge-primary"
              class="rounded-[6px]"
            />
            <ui-badge
              text="размер 24px"
              variant="ui-badge-primary"
              class="rounded-[6px]"
              small
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-badge ui-badge-primary rounded-[6px] ui-badge-lg"&gt;размер 48px&lt;/div&gt;
&lt;div class="ui-badge ui-badge-primary rounded-[6px]"&gt;размер 32px&lt;/div&gt;
&lt;div class="ui-badge ui-badge-primary rounded-[6px] ui-badge-sm"&gt;размер 24px&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
  &lt;ui-badge text="размер 48px"
               class="rounded-[6px]"
               variant="ui-badge-primary"
               large
  /&gt;

  &lt;ui-badge text="размер 32px"
               class="rounded-[6px]"
               variant="ui-badge-primary"
  /&gt;

  &lt;ui-badge text="размер 24px"
               class="rounded-[6px]"
               variant="ui-badge-primary"
               small
  /&gt;
</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiBadge from '@/components/ui/uiBadge.vue'

export default {
  name: 'BadgesPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-badge': uiBadge,
  },
}
</script>
