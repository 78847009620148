<template>
  <div class="ui-alert" :class="alertClass">
    <div class="wrapper">
      <p class="ui-alert__title">
        {{ title }}
      </p>
      <p class="ui-alert__text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uiAlert',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    alertClass() {
      return {
        'ui-alert-danger': this.variant === 'ui-alert-danger',
        'ui-alert-info': this.variant === 'ui-alert-info',
        'ui-alert-success': this.variant === 'ui-alert-success',
      }
    },
  },
}
</script>
