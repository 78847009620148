<template>
  <common-wrapper
    titleWrapper="Paginator"
    description-wrapper="Используйте элемент разбивки на страницы, чтобы указать серию содержимого на разных страницах."
  >
    <template>
      <common-group>
        <template>
          <common-item condition="Меньше 6 страниц">
            <ui-pagination />
            <template #html-slot>
              <pre>
&lt;nav aria-label="Page navigation"&gt;
  &lt;ul class="ui-pagination"&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]" href="#" &gt;
        &lt;span class="sr-only"&gt;Previous&lt;/span&gt;
      &lt;/a&gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;1&lt;/a &gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item active"&gt;
      &lt;a class="ui-pagination__item--link" href="#"  aria-current="page" &gt;2&lt;/a &gt;
    &lt;/li&gt;
    &lt;li  class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;3&lt;/a &gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;4&lt;/a&gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
     &lt;a class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]" href="#" &gt;
       &lt;span class="sr-only"&gt;Next&lt;/span&gt;
     &lt;/a&gt;
    &lt;/li&gt;
  &lt;/ul&gt;
&lt;/nav&gt;
                </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>

&lt;ui-pagination /&gt;
&lt;script&gt;
import uiPagination from '@/components/ui/uiPagination.vue'

export default {
  name: 'PaginationPage',
  components: {
    'ui-pagination': uiPagination,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;nav aria-label="Page navigation"&gt;
    &lt;ul class="ui-pagination"&gt;
      &lt;li class="ui-pagination__item"&gt;
        &lt;a
            class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
            href="#"
        &gt;
          &lt;span class="sr-only"&gt;Previous&lt;/span&gt;
        &lt;/a&gt;
      &lt;/li&gt;
      &lt;li class="ui-pagination__item"&gt;
        &lt;a class="ui-pagination__item--link" href="#"&gt;1&lt;/a&gt;
      &lt;/li&gt;
      &lt;li class="ui-pagination__item active"&gt;
        &lt;a
            class="ui-pagination__item--link"
            href="#"
            aria-current="page"
        &gt;2&lt;/a
        &gt;
      &lt;/li&gt;
      &lt;li class="ui-pagination__item"&gt;
        &lt;a class="ui-pagination__item--link" href="#"&gt;3&lt;/a&gt;
      &lt;/li&gt;
      &lt;li class="ui-pagination__item"&gt;
        &lt;a class="ui-pagination__item--link" href="#"&gt;4&lt;/a&gt;
      &lt;/li&gt;

      &lt;li v-if="more" class="ui-pagination__item"&gt;
        &lt;span class="bg-transparent ui-pagination__item--link"
        &gt;...&lt;/span
        &gt;
      &lt;/li&gt;
      &lt;li v-if="more" class="ui-pagination__item"&gt;
        &lt;a class="ui-pagination__item--link" href="#"&gt;{{ more }}&lt;/a&gt;
      &lt;/li&gt;

      &lt;li class="ui-pagination__item"&gt;
        &lt;a
            class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
            href="#"
        &gt;
          &lt;span class="sr-only"&gt;Next&lt;/span&gt;
        &lt;/a&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/nav&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiPagination',
  props: {
    more: {
      required: false,
      type: String,
    },
  },
}
&lt;/script&gt;

              </pre>
            </template>
          </common-item>
          <common-item condition="6 и более страниц">
            <ui-pagination more="44" />
            <template #html-slot>
              <pre>
&lt;nav aria-label="Page navigation"&gt;
  &lt;ul class="ui-pagination"&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]" href="#" &gt;
        &lt;span class="sr-only"&gt;Previous&lt;/span&gt;
      &lt;/a&gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;1&lt;/a &gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link bg-[#5BC9FC]" href="#"  aria-current="page" &gt;2&lt;/a &gt;
    &lt;/li&gt;
    &lt;li  class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;3&lt;/a &gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;4&lt;/a&gt;
    &lt;/li&gt;

    &lt;li class="ui-pagination__item"&gt;
      &lt;span class="ui-pagination__item--link bg-transparent" &gt;...&lt;/span&gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
      &lt;a class="ui-pagination__item--link" href="#" &gt;440&lt;/a &gt;
    &lt;/li&gt;
    &lt;li class="ui-pagination__item"&gt;
     &lt;a class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]" href="#" &gt;
       &lt;span class="sr-only"&gt;Next&lt;/span&gt;
     &lt;/a&gt;
    &lt;/li&gt;
  &lt;/ul&gt;
&lt;/nav&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiPagination from '@/components/ui/uiPagination.vue'

export default {
  name: 'PaginationPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-pagination': uiPagination,
  },
}
</script>
