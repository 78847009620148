<template>
  <common-wrapper
    title-wrapper="Datepicker"
    description-wrapper="Используем vue2-datepicker или air-datepicker"
  >
    <template>
      <common-group title-group="vue2-datepicker">
        <template>
          <common-item condition="Пустое поле">
            <ui-datepicker label="Выберите дату" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label for="date" class="ui-input__label"&gt;
    Выберите дату
  &lt;/label&gt; 
  &lt;div class="ui-input__field"&gt;
    &lt;date-picker
      :class="{ error: error }" 
      format="DD.MM.YYYY" 
      :lang="lang" 
      :confirm="false" 
      v-model="time" 
      valueType="format" 
    &gt;&lt;/date-picker&gt;
  &lt;/div&gt;
  &lt;span  class="ui-text-invalid block pt-3 "&gt;
    error 
  &lt;/span&gt; 
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-datepicker label="Выберите дату" /&gt;

&lt;script&gt;
import uiDatepicker from '@/components/ui/uiDatepicker.vue'

export default {
  name: 'FieldsDatepicker',
  components: {
    'ui-datepicker': uiDatepicker,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label for="date" class="ui-input__label"&gt;
      &#123;&#123; label &#125; &#125;
    &lt;/label&gt;
    &lt;div class="ui-input__field"&gt;
      &lt;date-picker
          :class="&#123; error: error &#125;"
          format="DD.MM.YYYY"
          :lang="lang"
          :confirm="false"
          v-model="time"
          valueType="format"
      /&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3 "&gt;&#123; &#123;error&#125; &#125;&lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: 'uiDatepicker',
  components: {DatePicker},
  data() {
    return {
      time: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
}
&lt;/script&gt;

&lt;style lang="scss"&gt;

.mx-datepicker {
  width: 100%;

  &.error {
    .mx-input-wrapper {
      .mx-input {
        border: 1px solid var(--e);
      }
    }
  }

  .mx-input-wrapper {
    position: relative;
    width: 100%;

    .mx-input {
      width: 100%;
      border: 1px solid var(--b4);
      border-radius: 6px;
      padding: 0 48px 0 16px;
      height: 56px;
      background: transparent;
      color: var(--bc);
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }

    .mx-icon-calendar,
    .mx-icon-clear {
      width: 24px;
      height: 24px;
      right: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      filter: var(--filter-black);

      svg {
        display: none;
      }
    }
    .mx-icon-calendar {
      background-image: url('@/assets/img/ic-datepicker-24.svg');
    }
    .mx-icon-clear {
      background-image: url('@/assets/img/ic-cross-24.svg');
    }
  }
}

.mx-datepicker-main.mx-datepicker-popup {
  font-family: Open Sans, sans-serif;
  .mx-datepicker-content {
    .mx-datepicker-body {
      .mx-calendar.mx-calendar-panel-date {
        .mx-calendar-header {
        }

        .mx-calendar-content {
          .mx-table.mx-table-date {
            thead {
              tr {
                th {
                }
              }
            }

            tbody {
              tr {
                &.mx-date-row {
                }

                td {
                  &.cell {
                  }

                  &.not-current-month {
                  }

                  &.today {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mx-calendar-header-label {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: var(--p);
    font-weight: 600;
  }
  .mx-btn {
    font-size: 16px;
    line-height: 24px;
    color: var(--p);
    font-weight: 600;

    &.mx-btn-icon-double-left,
    &.mx-btn-icon-double-right {
      width: 24px;
      height: 24px;
      font-size: 0;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: none;
    }

    &.mx-btn-icon-left,
    &.mx-btn-icon-right {
      width: 24px;
      height: 24px;
      font-size: 0;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: flex;
    }

    .mx-icon-left:before,
    .mx-icon-double-left:before {
      transform: rotate(-45deg) scale(1);
    }
    .mx-icon-right:before,
    .mx-icon-double-right:before {
      transform: rotate(135deg) scale(1);
    }

    .mx-icon-left,
    .mx-icon-right,
    .mx-icon-double-left,
    .mx-icon-double-right {
      &:before {
        width: 10px;
        height: 10px;
        border-width: 2px 0 0 2px;
        border-color: var(--p);
        top: 0;
      }
      &:after {
        display: none;
      }
    }
  }
  .mx-table {
    thead {
      background: transparent;
      th {
        height: 22px;
        line-height: 22px;
        font-size: 10px;
        text-align: center;
        font-weight: 600;
        color: var(--b4);
      }
    }
    tbody {
      tr {
        td {
          background: transparent;
          font-size: 14px;
          color: var(--bc);
          font-weight: 600;
          border: none;
          div {
            border: 1px solid transparent;
            width: 24px;
            height: 24px;
            border-radius: 6px;
            margin: auto;
          }
          &.today {
            div {
              border-color: var(--p);
            }
          }
          &:hover {
            background: transparent;
            div {
              border-color: var(--pf);
            }
          }
          &.active {
            div {
              border-color: var(--p);
              background: var(--p);
              color: var(--b1);
            }
          }
          &.not-current-month {
            color: var(--b4);
          }
        }
      }
    }

    &.mx-table-month,
    &.mx-table-year {
      td {
        font-size: 12px;
        border: none;

        background: transparent;
        padding: 4px 3px;
        div {
          background-color: #f0f6f6;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
        }

        &.active {
          color: var(--b1);
          div {
            background-color: var(--p);
          }
        }
        &:hover {
          color: var(--b1);
          div {
            background-color: var(--pf);
          }
        }
      }
    }
  }
  .mx-calendar {
    &.mx-calendar-panel-month {
      .mx-btn {
        &.mx-btn-icon-double-left,
        &.mx-btn-icon-double-right {
          display: flex;
        }
      }
    }
  }
}
&lt;/style&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-datepicker label="Выберите дату" error="Обязательное поле" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label for="date" class="ui-input__label"&gt;
    Выберите дату
  &lt;/label&gt;
  &lt;div class="ui-input__field"&gt;
    &lt;date-picker
      :class="{ error: error }"
      format="DD.MM.YYYY"
      :lang="lang"
      :confirm="false"
      v-model="time"
      valueType="format"
    &gt;&lt;/date-picker&gt;
  &lt;/div&gt;
  &lt;span  class="ui-text-invalid block pt-3 "&gt;
    error
  &lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="air-datepicker">
        <template>
          <common-item condition="Пустое поле">
            <ui-datepicker label="Выберите дату" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label for="date" class="ui-input__label"&gt;
    Выберите дату
  &lt;/label&gt;
  &lt;div class="ui-input__field"&gt;
    &lt;input class="ui-input__field--input datepicker" type="text" id="date" /&gt;
  &lt;/div&gt;
  &lt;span  class="ui-text-invalid block pt-3 "&gt;
    error
  &lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #cssSlot>
              <pre>
        .air-datepicker,
        .air-datepicker.-inline-{
            box-shadow: 0 6px 12px rgba(0,0,0,.175);
        }
        .air-datepicker-body--cells.-days-{
            grid-gap: 12px 8px;
        }
        .air-datepicker-nav{
            border: none;
        }
        .air-datepicker-nav--action{
            width: 24px;
            height: 24px;
        }
        .air-datepicker-nav--action:before{
            content: "";
            width: 10px;
            height: 10px;
            border-top: 2px solid;
            border-right: 2px solid;
            border-color: var(--p);
            top: 0;
            position: relative;
            transform: rotate(45deg) scale(0.7);
        }
        .air-datepicker-nav--action:first-child:before{
            transform: rotate(-135deg) scale(0.7);
        }
        .air-datepicker-nav--action svg{
            display: none;
        }
        .air-datepicker-nav--title{
            font-size: 16px;
            line-height: 24px;
            color: var(--p);
            font-weight: 600;
        }
        .air-datepicker-nav--title i {
            font-style: normal;
            color: var(--p);
        }
        .air-datepicker-body--day-names{
            margin: 0 0 8px;
            grid-gap: 12px 8px;
            font-weight: 600;
        }
        .air-datepicker-body--day-name {
            font-size: 10px;
            line-height: 24px;
            height: 24px;
        }
        .air-datepicker-cell:hover{
            border-color: var(--pf);
        }
        .air-datepicker-cell{
            line-height: 1;
            border:  1px solid transparent;
            font-weight: 600;
        }
        .air-datepicker-body--cells.-months-{
            grid-gap: 8px 6px;
        }
        .air-datepicker-body--cells.-months-   .air-datepicker-cell,
        .air-datepicker-body--cells.-years-   .air-datepicker-cell{
            background-color: var(--b5);
            font-size: 12px;
        }
        .air-datepicker-body--cells.-months-   .air-datepicker-cell.-selected-,
        .air-datepicker-body--cells.-months-   .air-datepicker-cell.-current-,
        .air-datepicker-body--cells.-years-   .air-datepicker-cell.-selected-,
        .air-datepicker-body--cells.-years-   .air-datepicker-cell.-current-{
            background: var(--p);
            color: #fff;
        }
        .air-datepicker-body--cells.-months-   .air-datepicker-cell:hover,
        .air-datepicker-body--cells.-years-   .air-datepicker-cell:hover{
            background: var(--pf);
            color: #fff;
        }
        .air-datepicker-body--cells.-years-{
            grid-gap: 8px 6px;
            grid-template-columns: repeat(2,1fr);
        }
        .air-datepicker {
            --adp-font-family: 'Open Sans', sans-serif;
            --adp-font-size: 14px;
            --adp-width: 240px;
            --adp-padding: 6px 12px;
            --adp-background-color: #fff;
            --adp-background-color-hover: #fff;
            --adp-color: var(--bc);
            --adp-color-secondary: var(--b4);
            --adp-accent-color: var(--p);
            --adp-border-radius:0;
            --adp-nav-height: 24px;
            --adp-day-name-color: var(--b4);
            --adp-day-name-color-hover: var(--p);
            --adp-day-cell-height: 24px;
            --adp-background-color-active: transparent;
            --adp-nav-arrow-color: var(--p);
            --adp-color-other-month: var(--b4);
            --adp-color-other-month-hover: var(--b4);
            --adp-border-color-inline: transparent;
            --adp-month-cell-height: 48px;
            --adp-year-cell-height: 36px;
            --adp-pointer-size: 14px;
            --adp-poiner-border-radius: 6px;
            --adp-cell-border-radius: 6px;
            --adp-cell-background-color-selected: var(--p);
            --adp-cell-background-color-selected-hover: var(--p);
        }
              </pre>
            </template>
            <template #jsSlot>
              <pre>
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

let initDatepicker = function () {
    let datepickers = document.getElementsByClassName('datepicker');
    if(datepickers){
        for (let datepicker of datepickers) {
            new AirDatepicker(datepicker,{
                autoClose: true,
            })
        }
    }
}
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-datepicker label="Выберите дату" error="Обязательное поле" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label for="date" class="ui-input__label"&gt;
    Выберите дату
  &lt;/label&gt;
  &lt;div class="ui-input__field"&gt;
     &lt;input class="ui-input__field--input datepicker" type="text" id="date" /&gt;
  &lt;/div&gt;
  &lt;span  class="ui-text-invalid block pt-3 "&gt;
    error
  &lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiDatepicker from '@/components/ui/uiDatepicker.vue'

export default {
  name: 'FieldsDatepicker',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-datepicker': uiDatepicker,
  },
}
</script>
