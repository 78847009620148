<template>
  <common-wrapper
    titleWrapper="Table"
    description-wrapper="Используйте табличный компонент для отображения текста, изображений, ссылок и других элементов внутри структурированного набора данных, состоящего из строк и столбцов ячеек таблицы."
  >
    <template>
      <common-group titleGroup="Auto 1">
        <template>
          <common-item
            condition="Используйте 'table-auto', чтобы разрешить таблице автоматически изменять размер столбцов в соответствии с содержимым ячейки."
          >
            <ui-table :rows="rows" :columns="columns" />
            <template #html-slot>
              <pre>
&lt;table&gt;
    &lt;thead&gt;
      &lt;tr&gt;
        &lt;th&gt;Заголовок&lt;/th&gt;
        &lt;th&gt;Заголовок&lt;/th&gt;
      &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
      &lt;tr&gt;
        &lt;td&gt;Текст внутри ячейки&lt;/td&gt;
        &lt;td&gt;Текст внутри ячейки (длинный текст)&lt;/td&gt;
      &lt;/tr&gt;
    &lt;/tbody&gt;
&lt;/table&gt;
                </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-table :rows="rows" :columns="columns"/&gt;

&lt;script&gt;
import uiTable from "@/components/ui/uiTable.vue";
export default {
  name: 'TablePage',
  components: {
    'ui-table': uiTable,
  },
  data() {
    return {
      rows: [
        { id: 1, 'ФИО': "Иванов И.И.", 'Телефон': '305-917-1301', 'Должность': 'менеджер' },
        { id: 2, 'ФИО': "Петров И.И.", 'Телефон': '210-684-8953', 'Должность': 'менеджер' },
        { id: 3, 'ФИО': "Смирнов И.И.", 'Телефон': '765-338-0312', 'Должность': 'менеджер' },
        { id: 4, 'ФИО': "Кузнецов И.И.", 'Телефон': '714-541-3336', 'Должность': 'менеджер' },
        { id: 5, 'ФИО': "Попов И.И.", 'Телефон': '972-297-6037', 'Должность': 'менеджер' },
        { id: 6, 'ФИО': "Васильев И.И.", 'Телефон': '760-318-8376', 'Должность': 'менеджер' }
      ]
    }
  },
  computed: {
    "columns": function columns() {
      if (this.rows.length === 0) {
        return [];
      }
      return Object.keys(this.rows[0])
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;table&gt;
    &lt;thead&gt;
    &lt;tr&gt;
      &lt;th v-for="col in columns" :key="col.id"&gt;&#123;&#123;col&#125;&#125;&lt;/th&gt;
    &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
    &lt;tr v-for="row in rows" :key="row.id"&gt;
      &lt;td v-for="col in columns" :key="col.id"&gt; &#123; &#123;row[col]&#125; &#125; &lt;/td&gt;
    &lt;/tr&gt;
    &lt;/tbody&gt;
  &lt;/table&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiTable",
  props: {
    rows: {
      type: Array,
    },
    columns: {
      type: Array,
    },
  },

}
&lt;/script&gt;
</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group titleGroup="Fixed">
        <template>
          <common-item
            condition="Используйте класс 'table-fixed', чтобы разрешить таблице игнорировать содержимое и использовать фиксированную ширину для столбцов. Ширина первой строки задает ширину столбца для всей таблицы."
          >
            <ui-table :rows="rows" :columns="columns" class="table-fixed" />
            <template #html-slot>
              <pre>
&lt;table class='table-fixed'&gt;
    &lt;thead&gt;
        &lt;tr&gt;
          &lt;th&gt;Заголовок&lt;/th&gt;
          &lt;th&gt;Заголовок&lt;/th&gt;
        &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
        &lt;tr&gt;
          &lt;td&gt;Текст внутри ячейки&lt;/td&gt;
          &lt;td&gt;Текст внутри ячейки (длинный текст)&lt;/td&gt;
        &lt;/tr&gt;
    &lt;/tbody&gt;
&lt;/table&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiTable from '@/components/ui/uiTable.vue'

export default {
  name: 'TablePage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-table': uiTable,
  },
  data() {
    return {
      rows: [
        {
          id: 1,
          ФИО: 'Иванов И.И.',
          Телефон: '305-917-1301',
          Должность: 'менеджер',
        },
        {
          id: 2,
          ФИО: 'Петров И.И.',
          Телефон: '210-684-8953',
          Должность: 'менеджер',
        },
        {
          id: 3,
          ФИО: 'Смирнов И.И.',
          Телефон: '765-338-0312',
          Должность: 'менеджер',
        },
        {
          id: 4,
          ФИО: 'Кузнецов И.И.',
          Телефон: '714-541-3336',
          Должность: 'менеджер',
        },
        {
          id: 5,
          ФИО: 'Попов И.И.',
          Телефон: '972-297-6037',
          Должность: 'менеджер',
        },
        {
          id: 6,
          ФИО: 'Васильев И.И.',
          Телефон: '760-318-8376',
          Должность: 'менеджер',
        },
      ],
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      return Object.keys(this.rows[0])
    },
  },
}
</script>
