<template>
  <common-wrapper
    title-wrapper="Links"
    description-wrapper="Компонент ссылки можно использовать для установки гиперссылок с одной страницы на другую или на внешний веб-сайт при нажатии на встроенный текстовый элемент."
  >
    <template>
      <common-group title-group="Standard links">
        <template>
          <common-item condition="Пассивное состояние">
            <a href="#" class="ui-link">
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-basket-24.svg')]"
              ></span>
              Добавить в корзину
            </a>
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Пассивное состояние (иконка справа)">
            <a href="#" class="ui-link">
              Добавить в корзину
              <span
                class="ui-link__ic ml-2 bg-[url('./img/ic-basket-24.svg')]"
              ></span>
            </a>
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;
  Добавить в корзину
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] ml-2"&gt;&lt;/span&gt;
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При наведении">
            <a
              href="#"
              class="group inline-flex items-center text-base font-semibold text-[var(--ph)] hover:text-[var(--ph)] active:text-[var(--ph)]"
            >
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-basket-24.svg')]"
                style="filter: var(--filter-primary-h)"
              ></span>

              Добавить в корзину</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <a
              href="#"
              class="group inline-flex items-center text-base font-semibold text-[var(--pf)] hover:text-[var(--pf)] active:text-[var(--pf)]"
            >
              <span
                class="ui-link__ic mr-2 bg-[url('./img/ic-basket-24.svg')]"
                style="filter: var(--filter-primary-f)"
              ></span>

              Добавить в корзину</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Small links">
        <template>
          <common-item condition="Пассивное состояние">
            <a href="#" class="ui-link text-xs">
              <span
                class="min-w-3 ui-link__ic mr-2 h-3 w-3 bg-[url('./img/ic-basket-24.svg')]"
              ></span>
              Добавить в корзину</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При наведении">
            <a
              href="#"
              class="group inline-flex items-center text-xs font-semibold text-[var(--ph)] hover:text-[var(--ph)] active:text-[var(--ph)]"
            >
              <span
                class="min-w-3 ui-link__ic mr-2 h-3 w-3 bg-[url('./img/ic-basket-24.svg')]"
                style="filter: var(--filter-primary-h)"
              ></span>

              Добавить в корзину</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <a
              href="#"
              class="group inline-flex items-center text-xs font-semibold text-[var(--pf)] hover:text-[var(--pf)] active:text-[var(--pf)]"
            >
              <span
                class="min-w-3 ui-link__ic mr-2 h-3 w-3 bg-[url('./img/ic-basket-24.svg')]"
                style="filter: var(--filter-primary-f)"
              ></span>

              Добавить в корзину</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;
  &lt;span class="ui-link__ic bg-[url('./img/ic-basket-24.svg')] mr-2"&gt;&lt;/span&gt;
  Добавить в корзину
&lt;/a&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'LinksIcon',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
}
</script>

<style scoped></style>
