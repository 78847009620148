<template>
  <common-wrapper titleWrapper="Typography">
    <template>
      <common-group title-group="Headlines">
        <template>
          <common-item condition="h1 - h6">
            <template>
              <h1>H1 - Заголовок 52px</h1>
              <h2>H2 - Заголовок 40px</h2>
              <h3>H3 - Заголовок 32px</h3>
              <h4>H4 - Заголовок 24px</h4>
              <h5>H5 - Заголовок 18px</h5>
              <h6>H6 - Заголовок 16px</h6>
            </template>
            <template #html-slot>
              <pre>
&lt;h1&gt;H1 - Заголовок&lt;/h1&gt;
&lt;h2&gt;H2 - Заголовок&lt;/h2&gt;
&lt;h3&gt;H3 - Заголовок&lt;/h3&gt;
&lt;h4&gt;H4 - Заголовок&lt;/h4&gt;
&lt;h5&gt;H5 - Заголовок&lt;/h5&gt;
&lt;h6&gt;H6 - Заголовок&lt;/h6&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Other tags">
        <template>
          <common-item condition="Big">
            <template
              ><big>Быстро создавайте современные веб-сайты</big></template
            >
            <template #html-slot>
              <pre>
  &lt;big&gt;Быстро создавайте современные веб-сайты&lt;/big&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="Paragraph">
            <template><p>Быстро создавайте современные веб-сайты</p></template>
            <template #html-slot>
              <pre>
  &lt;p&gt;Быстро создавайте современные веб-сайты&lt;/p&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="Small">
            <template
              ><small>Быстро создавайте современные веб-сайты</small></template
            >
            <template #html-slot>
              <pre>
&lt;small&gt;Быстро создавайте современные веб-сайты&lt;/small&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="Bold Text">
            <template
              ><strong
                >Быстро создавайте современные веб-сайты</strong
              ></template
            >
            <template #html-slot>
              <pre>
&lt;strong&gt;Быстро создавайте современные веб-сайты&lt;/strong&gt;
&lt;b&gt;Быстро создавайте современные веб-сайты&lt;/b&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Italic">
            <template><i>Быстро создавайте современные веб-сайты</i></template>
            <template #html-slot>
              <pre>
&lt;i&gt;Быстро создавайте современные веб-сайты&lt;/i&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Link">
            <template
              ><a href="#">Быстро создавайте современные веб-сайты</a></template
            >
            <template #html-slot>
              <pre>
&lt;a href="#"&gt;Быстро создавайте современные веб-сайты&lt;/a&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="Цитата">
            <blockquote>
              <p>
                Внезапно, сторонники тоталитаризма в науке подвергнуты целой
                серии независимых исследований. Следует отметить, что
                существующая теория в значительной степени обусловливает
                важность экономической целесообразности принимаемых решений.
              </p>
              <p>
                Предварительные выводы неутешительны: социально-экономическое
                развитие предопределяет высокую востребованность инновационных
                методов управления процессами.
              </p>
              <a href="#">Иван Иванов, телеведущий</a>
            </blockquote>
            <template #html-slot>
              <pre>
&lt;blockquote&gt;
   &lt;p&gt; Внезапно, сторонники тоталитаризма в
              науке подвергнуты целой серии независимых исследований. Следует
              отметить, что существующая теория в значительной степени
              обусловливает важность экономической целесообразности принимаемых
              решений.
   &lt;/p&gt;
   &lt;p&gt; Предварительные выводы неутешительны: социально-экономическое развитие предопределяет
              высокую востребованность инновационных методов управления
              процессами.
   &lt;/p&gt;
   &lt;a href="#">Иван Иванов, телеведущий&lt;/a&gt;
&lt;/blockquote&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Список">
            <ul>
              <li>Вариант один</li>
              <li>Вариант два</li>
              <li>Вариант три</li>
              <li>Вариант четыре</li>
              <li>Вариант пять</li>
            </ul>
            <template #html-slot>
              <pre>
&lt;ul&gt;
     &lt;li>Вариант один &lt;/li&gt;
     &lt;li>Вариант два&lt;/li&gt;
     &lt;li>Вариант три &lt;/li&gt;
     &lt;li>Вариант четыре&lt;/li&gt;
     &lt;li>Вариант пять &lt;/li&gt;
&lt;/ul&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Нумерованный список">
            <ol>
              <li>Вариант один</li>
              <li>Вариант два</li>
              <li>Вариант три</li>
              <li>Вариант четыре</li>
              <li>Вариант пять</li>
            </ol>
            <template #html-slot>
              <pre>
&lt;ol&gt;
     &lt;li>Вариант один &lt;/li&gt;
     &lt;li>Вариант два&lt;/li&gt;
     &lt;li>Вариант три &lt;/li&gt;
     &lt;li>Вариант четыре&lt;/li&gt;
     &lt;li>Вариант пять &lt;/li&gt;
&lt;/ul&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'TypographyPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
}
</script>

<style scoped></style>
