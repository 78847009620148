<script>
import uiInputCounter from '@/components/ui/uiInputCounter.vue'
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'FieldsInputCounter',
  components: {
    'ui-input-counter': uiInputCounter,
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
}
</script>

<template>
  <common-wrapper>
    <template>
      <common-group>
        <template>
          <common-item><ui-input-counter /></common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<style scoped></style>
