import { render, staticRenderFns } from "./uiStars.vue?vue&type=template&id=103abcf6&"
import script from "./uiStars.vue?vue&type=script&lang=js&"
export * from "./uiStars.vue?vue&type=script&lang=js&"
import style0 from "./uiStars.vue?vue&type=style&index=0&id=103abcf6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports