<template>
  <common-wrapper
    titleWrapper="Progress"
    description-wrapper="Используйте компонент индикатора выполнения, чтобы показать скорость выполнения индикатора данных или использовать его в качестве элемента загрузчика."
  >
    <template>
      <common-group>
        <template>
          <common-item condition="Выполнено 0%">
            <template>
              <ui-progress length="0%" />
            </template>
            <template #html-slot>
              <pre>
&lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
  &lt;object
  class="h-2 rounded-md bg-[var(--s)]"
  width='0%'
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-progress length="0%" /&gt;

&lt;script&gt;
import uiProgress from '@/components/ui/uiProgress.vue'

export default {
  name: 'ProgressPage',
  components: {
    'ui-progress': uiProgress,
  },
}
&lt;/script&gt;
</pre
              >
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
    &lt;object class="h-2 rounded-md bg-[var(--s)] :width="length" /&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiProgress',
  props: {
    length: {
      required: true,
      type: String,
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Выполнено 45%">
            <template>
              <ui-progress length="45%" />
            </template>
            <template #html-slot>
              <pre>
&lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
  &lt;object
  class="h-2 rounded-md bg-[var(--s)]"
  width='45%'
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Выполнено 100%">
            <template>
              <ui-progress length="100%" />
            </template>
            <template #html-slot>
              <pre>
&lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
  &lt;object
  class="h-2 rounded-md bg-[var(--s)]"
  width='100%'
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiProgress from '@/components/ui/uiProgress.vue'

export default {
  name: 'ProgressPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-progress': uiProgress,
  },
}
</script>
