<template>
  <common-wrapper title-wrapper="Password Fields">
    <template>
      <common-group>
        <template>
          <common-item
            v-for="item in items"
            :key="item.index"
            :condition="item.condition"
          >
            <template>
              <form action="#">
                <ui-input
                  label="Пароль"
                  :id="item.id"
                  :variant="item.variant"
                  :error="item.error"
                  :disabled="item.disabled"
                  :passwordButton="true"
                  :value="item.passwordValue"
                  :type="item.fieldType"
                  @updateValue="update(item)"
                  @togglePassword="togglePassword(item)"
                />
              </form>
            </template>
            <template #html-slot>
              <pre>
  {{ item.htmlSlot }}
</pre
              >
            </template>
            <template v-if="item.show" #jsSlot>
              <pre>
let initChangePassword = function() &#123;
    const passwordEye = document.getElementsByClassName('eye')
    const passwordField = document.getElementsByClassName('ui-input__field--input' )
    if (passwordEye) &#123;
        for ( let i = 0; i &lt; passwordEye.length; i++ ) &#123;
            passwordEye[i].addEventListener 'click', function()
                passwordField[i].type === 'password' ? passwordField[i].type = 'text' :  passwordField[i].type = 'password'
        &#125;
    &#125;
&#125;
              </pre>
            </template>
            <template v-if="item.show" #vueSlotElement>
              <pre>
&lt;ui-input
    id="id"
    :type="fieldType"
    :passwordButton="true"
    label="Пароль"
    @updateValue="update()"
    @togglePassword="togglePassword()"
/&gt;

&lt;script&gt;
import uiInput from '@/components/ui/uiInput'

export default {
  name: 'FieldsInputPassword',
  data() {
    return {
      fieldType: 'password',
      passwordValue: '',
    }
  },
  components: {
    'ui-input': uiInput,
  },
  methods: {
    togglePassword() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
    },
    update(value) {
      value = event.target.value;
      this.passwordValue = value;
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template v-if="item.show" #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label :for="id" class="ui-input__label"&gt;
      &#123; &#123; label &#125; &#125;
    &lt;/label&gt;
    &lt;div class="ui-input__field"&gt;
      &lt;input
          :id="id"
          :class="inputClass"
          :disabled="disabled"
          :placeholder="placeholder"
          :type="type"
          :value="value"
          @input="updateValue"
          autocomplete="on"
          class="ui-input__field--input"
      /&gt;
      &lt;button
          @click.prevent="togglePassword"
          v-if="passwordButton"
          class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')]"
      &gt;
      &lt;/button&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt; &#123; &#123;
        error
      &#125; &#125; &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiInput',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    passwordButton: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
  methods: {
    updateValue() {
      this.$emit('updateValue')
    },
    togglePassword() {
      this.$emit('togglePassword')
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>
<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiInput from '@/components/ui/uiInput'

export default {
  name: 'FieldsInputPassword',
  data() {
    return {
      fieldType: 'password',
      items: [
        {
          id: 'password',
          condition: 'Пустое поле',
          error: null,
          variant: null,
          disabled: null,
          fieldType: 'password',
          passwordValue: '',
          show: true,
          htmlSlot: `<div class="ui-input">
    <label class="ui-input__label" for="name" >Ваше имя</label >
    <div class="ui-input__field" >
      <input class="ui-input__field--input" type="password" id="name" />
      <button class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')] "></button>
    </div>
  </div>`,
        },
        {
          id: 'password_show_text',
          condition: 'Включен режим «показать пароль»',
          error: null,
          variant: null,
          disabled: null,
          fieldType: 'text',
          passwordValue: '',
          show: false,
          htmlSlot: `<div class="ui-input">
    <label class="ui-input__label" for="name" >Ваше имя</label >
    <div class="ui-input__field" >
      <input class="ui-input__field--input" type="text" id="name" />
      <button class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')] "></button>
    </div>
  </div>`,
        },
        {
          id: 'password_error',
          condition: 'Ошибка',
          error: 'Пароль должен содержать не менее 8 знаков',
          variant: 'error',
          disabled: null,
          fieldType: 'password',
          passwordValue: '',
          show: false,
          htmlSlot: `<div class="ui-input">
    <label class="ui-input__label" for="name" >Ваше имя</label >
    <div class="ui-input__field" >
      <input class="ui-input__field--input error" type="password" id="name" />
      <button class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')] "></button>
    </div>
     <span class="ui-text-invalid block pt-3">error</span>
  </div>`,
        },
        {
          id: 'password_disabled',
          condition: 'Disabled',
          error: null,
          variant: null,
          disabled: true,
          fieldType: 'password',
          passwordValue: '',
          show: false,
          htmlSlot: `<div class="ui-input">
    <label class="ui-input__label" for="name" >Ваше имя</label >
    <div class="ui-input__field" >
      <input class="ui-input__field--input" disabled type="password" id="name" />
      <button class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')] "></button>
    </div>
  </div>`,
        },
      ],
    }
  },
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-input': uiInput,
  },
  methods: {
    update(item, value) {
      value = event.target.value
      item.passwordValue = value
    },
    togglePassword(item) {
      item.fieldType = item.fieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
