var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    light: _vm.lightMode,
    dark: _vm.darkMode,
    cupcake: _vm.cupcakeMode,
    forest: _vm.forestMode,
    dracula: _vm.draculaMode,
  },attrs:{"id":"app"}},[_c('div',{staticClass:"bg-[var(--b2)]"},[_c('div',{staticClass:"grid grid-cols-12"},[_c('div',{staticClass:"col-span-12 px-10 py-6 md:col-span-3"},[_c('side-bar',{on:{"chooseLightTheme":function($event){return _vm.chooseLightTheme()},"chooseDarkTheme":function($event){return _vm.chooseDarkTheme()},"chooseCupcakeTheme":function($event){return _vm.chooseCupcakeTheme()},"chooseForestTheme":function($event){return _vm.chooseForestTheme()},"chooseDraculaTheme":function($event){return _vm.chooseDraculaTheme()}}})],1),_c('div',{staticClass:"col-span-12 md:col-span-9"},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }