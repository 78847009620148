<template>
  <common-wrapper titleWrapper="Vue skeleton">
    <template>
      <common-group text-group="Cards art">
        <template>
          <common-item>
            <div class="grid space-y-10" v-if="loading">
              <ui-card-art v-for="n in 10" :key="n" :loading="loading" />
            </div>
            <div class="grid space-y-10" v-else>
              <ui-card-art
                v-for="card in cards"
                :key="card.index"
                :href="card.title"
                :title="card.title"
                :img="card.image"
                :alt="card.title"
                :text="card.description"
                :date="card.title"
                :name="card.category"
              />
            </div>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>
<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiCardArt from '@/components/ui/uiCardArt.vue'
import axios from 'axios'

export default {
  name: 'SkeletonPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-card-art': uiCardArt,
  },
  data() {
    return {
      cards: null,
      loading: true,
      errored: false,
    }
  },
  mounted() {
    axios
      .get('https://fakestoreapi.com/products')
      .then((response) => {
        this.cards = response.data
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
        this.errored = true
      })
  },
}
</script>
