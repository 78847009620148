<template>
  <div>
    <div class="ui-input">
      <label class="ui-input__label">Количество</label>
      <div class="ui-input__field flex">
        <button class="ui-btn-outline-primary ui-btn" @click="decrement">
          -
        </button>
        <div
          class="ui-input__field--input mx-2 max-w-[100px] justify-center"
          type="text"
        >
          {{ counter }}
        </div>
        <button class="ui-btn-outline-primary ui-btn" @click="increment">
          +
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'

export default {
  name: 'uiInputCounter',
  computed: {
    counter() {
      return store.getters.COUNTER
    },
  },
  methods: {
    increment: function () {
      store.commit('increment')
    },
    decrement: function () {
      store.commit('decrement')
    },
  },
}
</script>
