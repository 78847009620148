<template>
  <common-wrapper
    title-wrapper="Accordion"
    description-wrapper="Используйте компонент аккордеона для отображения скрытой информации на основе состояния свертывания и развертывания дочерних элементов"
  >
    <template>
      <common-group>
        <template>
          <common-item>
            <template>
              <div>
                <ui-accordion :items="items" />
              </div>
            </template>
            <template #html-slot>
              <pre>
&lt;div class="ui-accordion"&gt;
    &lt;div class="ui-accordion-item">
        &lt;button class="ui-accordion__btn" type="button" data-dynamic-toggle="accordion-1">
                Заголовок аккордеона 1
            &lt;span class="ui-accordion__btn--arr">&lt;/span>
        &lt;/button>
        &lt;div class="ui-accordion__dropdown hidden" id="accordion-1">
                А также тщательные исследования конкурентов набирают
        &lt;/div>
    &lt;/div>
    &lt;div class="ui-accordion-item">
        &lt;button class="ui-accordion__btn" type="button" data-dynamic-toggle="accordion-2">
                Заголовок аккордеона 2
            &lt;span class="ui-accordion__btn--arr">&lt;/span>
        &lt;/button>
        &lt;div class="ui-accordion__dropdown hidden" id="accordion-2">
                А также тщательные исследования
        &lt;/div>
    &lt;/div>
&lt;/div>
                </pre
              >
            </template>
            <template #jsSlot>
              <pre>
    let initDynamicToggle = function () {
            let toggleDynamicButtons = document.querySelectorAll("[data-dynamic-toggle]")

            if (toggleDynamicButtons) {
                for (let i = 0; i &lt; toggleDynamicButtons.length; i++) {
                    let dynamicToggle = toggleDynamicButtons[i],
                        dynamicToggleDataset = dynamicToggle.dataset.dynamicToggle,
                        toggleBox = document.getElementById(dynamicToggleDataset)
                    toggleDynamicButtons[i].addEventListener('click', function () {
                        dynamicToggle.classList.toggle("active")
                        toggleBox.classList.toggle("hidden")
                    });
                }
            }
        }
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-accordion :items="items" /&gt;

&lt;script&gt;
import uiAccordion from '@/components/ui/uiAccordion.vue'

export default {
  name: 'AccordionPage',
  components: {
    'ui-accordion': uiAccordion,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Заголовок аккордеона 1',
          content:
              'А также тщательные исследования конкурентов набирают популярность среди определенных слоев населения, а значит, должны быть в равной степени предоставлены сами себе. ' +
              'Как уже неоднократно упомянуто, представители современных социальных резервов, вне зависимости от их уровня, должны быть указаны.',
          isExpand: false,
        },
        {
          id: 2,
          title: 'Заголовок аккордеона 2',
          content:
              'Рыба текст. С другой стороны постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание систем массового участия. Товарищи!' +
              ' рамки и место обучения кадров влечет за собой процесс внедрения и модернизации дальнейших направлений развития.',
          isExpand: false,
        },
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-accordion"&gt;
    &lt;div class="ui-accordion-item"
         v-for="item in items"
         :key="item.id"
    &gt;
      &lt;button
          @click.prevent="toggleExpand(item)"
          class="ui-accordion__btn"
          type="button"
      &gt;
        &#123; &#123; item.title &#125; &#125;
        &lt;span class="ui-accordion__btn--arr" :class="{'rotate-180': item.isExpand}"&gt;&lt;/span&gt;
      &lt;/button&gt;
      &lt;div class="ui-accordion__dropdown"
           :ref="'content' + item.id"
           v-if="item.isExpand"
      &gt;
        &#123; &#123; item.content &#125; &#125;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiAccordion",
  props:{
    items: {
      type: Array
    },
  },
  methods: {
    toggleExpand(item) {
      item.isExpand = !item.isExpand;
    },
  }
}
&lt;/script&gt;  
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiAccordion from '@/components/ui/uiAccordion.vue'

export default {
  name: 'AccordionPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-accordion': uiAccordion,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Заголовок аккордеона 1',
          content:
            'А также тщательные исследования конкурентов набирают популярность среди определенных слоев населения, а значит, должны быть в равной степени предоставлены сами себе. ' +
            'Как уже неоднократно упомянуто, представители современных социальных резервов, вне зависимости от их уровня, должны быть указаны.',
          isExpand: false,
        },
        {
          id: 2,
          title: 'Заголовок аккордеона 2',
          content:
            'Рыба текст. С другой стороны постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание систем массового участия. Товарищи!' +
            ' рамки и место обучения кадров влечет за собой процесс внедрения и модернизации дальнейших направлений развития.',
          isExpand: false,
        },
      ],
      itemsJS: [
        {
          id: 1,
          title: 'Заголовок аккордеона 1',
          content:
            'А также тщательные исследования конкурентов набирают популярность среди определенных слоев населения, а значит, должны быть в равной степени предоставлены сами себе. ' +
            'Как уже неоднократно упомянуто, представители современных социальных резервов, вне зависимости от их уровня, должны быть указаны.',
          isExpand: false,
        },
        {
          id: 2,
          title: 'Заголовок аккордеона 2',
          content:
            'Рыба текст. С другой стороны постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание систем массового участия. Товарищи!' +
            ' рамки и место обучения кадров влечет за собой процесс внедрения и модернизации дальнейших направлений развития.',
          isExpand: false,
        },
      ],
    }
  },
}
</script>

<style scoped></style>
