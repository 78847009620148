<template>
  <div class="flex items-center space-x-2">
    <div class="h-2 w-full rounded-md bg-[var(--b4)]">
      <object class="h-2 rounded-md bg-[var(--s)]" :width="length" />
    </div>
    <div class="min-w-12 block w-12 text-base" v-if="length">
      {{ length }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiRatingScale',
  props: {
    length: {
      type: String,
      required: true,
      default: null,
    },
  },
}
</script>
