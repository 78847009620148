<template>
  <common-wrapper
    title-wrapper="Popover"
    description-wrapper="Используйте всплывающий компонент для отображения подробной информации во всплывающем окне относительно элемента."
  >
    <template>
      <common-group text-group="Для скрытия использую class='hidden'">
        <template>
          <common-item condition="top">
            <div class="relative inline-flex">
              <button
                @click="openPopover"
                type="button"
                class="ui-btn ui-btn-primary"
              >
                Открыть
              </button>
              <ui-popover
                title="Заголовок"
                text="Popover, в отличии от tooltip, содержит справочную информацию которая не относится к иконке на прямую"
                v-if="hide"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="relative inline-block"&gt;
    &lt;button type="button"
            class="ui-btn ui-btn-primary"
            data-dynamic-show="popover-1"
    &gt;Открыть
    &lt;/button&gt;
    &lt;div class="ui-popover hidden" id="popover-1"&gt;
        &lt;div class="ui-popover-container "&gt;
            &lt;p class="mb-4 text-lg font-semibold"&gt;title&lt;/p&gt;
            &lt;p class="mb-0"&gt; text &lt;/p&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
            <template #jsSlot>
              <pre>
          let initDynamicShown = function () {
            let showDynamicButtons = document.querySelectorAll("[data-dynamic-show]"),
                closesDynamicButtons = document.querySelectorAll("[data-close]");

            if (showDynamicButtons) {
                showDynamicButtons.forEach(button => {
                    let dynamicShow = button.dataset.dynamicShow
                    let dynamicBox = document.getElementById(dynamicShow)

                    // открытие
                    button.addEventListener("click", function () {
                        dynamicBox.classList.toggle("hidden")
                    });

                    // закрытие клик вне элемента
                    document.addEventListener('click', (e) => {
                        const withinBoundaries = e.composedPath().includes(dynamicBox);
                        const withinBoundaries2 = e.composedPath().includes(button);

                        !withinBoundaries && !withinBoundaries2 ? dynamicBox.classList.add("hidden") : null

                    });

                    // закрытие атрибутом data-close
                    let closeDynamic = function (close) {
                        close.addEventListener("click", () => {
                            dynamicBox.classList.add("hidden")
                        });
                    }
                    closesDynamicButtons.forEach(close => {
                        closeDynamic(close)
                    });
                });
            }
        }
            </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;button
    @click="openPopover"
    type="button"
    class="ui-btn ui-btn-primary"
&gt;
  Открыть
&lt;/button>
&lt;ui-popover
    title="Заголовок"
    text="Popover, в отличии от tooltip, содержит справочную информацию которая не относится к иконке на прямую"
    v-if="hide"
/&gt;

&lt;script&gt;
import uiPopover from '@/components/ui/uiPopover.vue'

export default {
  name: 'PopoverElement',
  components: {
    'ui-popover': uiPopover,
  },
  data() {
    return {
      hide: false,
    }
  },
  methods: {
    openPopover() {
      this.hide = !this.hide
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-popover"&gt;
    &lt;div class="ui-popover-container"&gt;
      &lt;p class="mb-4 text-lg font-semibold"&gt;&#123; &#123; title &#125; &#125;&lt;/p&gt;
      &lt;p class="mb-0"&gt;&#123; &#123; text &#125; &#125;&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiPopover',
  props: {
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiPopover from '@/components/ui/uiPopover.vue'

export default {
  name: 'PopoverElement',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-popover': uiPopover,
  },
  data() {
    return {
      hide: false,
      hide2: false,
    }
  },
  methods: {
    openPopover() {
      this.hide = !this.hide
    },
    openPopover2() {
      this.hide2 = !this.hide2
    },
  },
}
</script>
