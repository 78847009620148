<template>
  <div
    class="ui-rating-text"
    :class="{
      'bg-[var(--w)]': value >= 3 && value < 4,
      'bg-[var(--su)]': value >= 4,
    }"
  >
    {{ value }}
  </div>
</template>
<script>
export default {
  name: 'uiRatingText',
  props: {
    value: {
      type: [Number, String],
      required: true,
      default: 0,
    },
  },
}
</script>
