<template>
  <common-wrapper
    title-wrapper="Avatar"
    description-wrapper="Используйте компонент аватара, чтобы показать визуальное представление профиля пользователя"
  >
    <template>
      <common-group>
        <template>
          <common-item condition="С фото">
            <ui-avatar src="/../img/logo.svg" alt="avatar" />
            <template #html-slot>
              <pre>
&lt;div class="ui-avatar"&gt;
  &lt;img class="ui-avatar__img" src="/../img/logo.svg" alt="avatar"/&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-avatar src="/../img/logo.svg" alt="avatar" /&gt;

&lt;script&gt;
import uiAvatar from '@/components/ui/uiAvatar.vue'

export default {
  name: 'AvatarElement',
  components: {
    'ui-avatar': uiAvatar,
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-avatar"&gt;
    &lt;img class="ui-avatar__img"
         :src="src"
         :alt="alt"
         v-if="src"
    /&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "uiAvatar",
  props:{
    avatarSrc:{
      type: String,
      required: false,
      default: null,
    },
    alt:{
      type: String,
      required: false,
      default: null,
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Без фото">
            <ui-avatar />
            <template #html-slot>
              <pre>&lt;div class="ui-avatar"&gt;&lt;/div&gt;</pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiAvatar from '@/components/ui/uiAvatar.vue'

export default {
  name: 'AvatarElement',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-avatar': uiAvatar,
  },
}
</script>
