<template>
  <ul class="ui-nav-category">
    <li class="space-y-4" v-for="item in itemsNavCategory" :key="item.text">
      <a v-if="item.href" :href="item.href">{{ item.text }}</a>
      <ul class="space-y-3">
        <li
          :class="{ hidden: index > 3 && item.isShow }"
          v-for="(link, index) of item.links"
          :key="link.text"
        >
          <a class="text-xs" :href="link.href" :class="{ active: link.active }">
            {{ link.text }}
          </a>
        </li>
      </ul>
      <button
        @click="toggleExpand(item)"
        class="ui-link text-xs"
        type="button"
        v-if="item.links.length > 4"
      >
        <span v-if="item.isShow">Еще {{ item.links.length - 4 }}</span>
        <span v-else>Свернуть</span>
        <span
          class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          :class="{ 'rotate-180': !item.isShow }"
        ></span>
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'uiNavCategory',
  props: {
    itemsNavCategory: {
      type: Array,
      links: {
        type: Array,
      },
    },
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },
  },
}
</script>
