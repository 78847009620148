<template>
  <common-wrapper titleWrapper="Buttons outline icons">
    <template>
      <common-group
        titleGroup="Standard"
        textGroup="Второстепенная кнопка для крупных блоков"
      >
        <template>
          <common-item condition="Пассивное состояние">
            <template>
              <ui-button
                variant="ui-btn-outline-primary"
                text="Перейти в каталог"
                icName="bg-[url('./img/ic-basket-24.svg')]"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-button
    variant="ui-btn-outline-primary"
    text="Перейти в каталог"
    icName="bg-[url('./img/ic-basket-24.svg')] "
/&gt;

&lt;script&gt;
import uiButton from '@/components/ui/uiButton'

export default {
  name: 'ButtonsOutlineIcon',
  components: {
    'ui-button': uiButton,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>

&lt;template&gt;
  &lt;button
      :class="buttonClass"
      type="button"
      class="ui-btn"
      :disabled="disabled"
  &gt;
    &lt;span v-if="icName" :class="icName" class="ui-btn__ic">&lt;/span&gt;
    &#123; &#123; text &#125; &#125;
  &lt;/button&gt;
&lt;/template&gt;


&lt;script&gt;
export default {
  name: 'uiButton',
  props: {
    variant: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      required: true,
      type: String,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    icName: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        'ui-btn-primary': this.variant === 'ui-btn-primary',
        'ui-btn-outline-primary': this.variant === 'ui-btn-outline-primary',
        'ui-btn-sm': this.small === true,
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Наведение">
            <template>
              <div class="parents-outline-btn">
                <ui-button
                  class="border-[var(--ph)]"
                  text="Перейти в каталог"
                  icName="bg-[url('./img/ic-basket-24.svg')]"
                />
              </div>
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <template>
              <div class="parents-outline-btn">
                <ui-button
                  class="border-[var(--pf)]"
                  text="Перейти в каталог"
                  icName="bg-[url('./img/ic-basket-24.svg')]"
                />
              </div>
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительная кнопка">
            <template>
              <ui-button
                disabled
                variant="ui-btn-outline-primary"
                text="Перейти в каталог"
                icName="bg-[url('./img/ic-basket-24.svg')]"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button disabled type="button" class="ui-btn ui-btn-outline-primary"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Small buttons"
        textGroup="Второстепенная кнопка для небольших эелемнтов (карточки, отзывы и т.д.)"
      >
        <template>
          <common-item condition="Пассивное состояние">
            <template>
              <ui-button
                small
                variant="ui-btn-outline-primary"
                text="Перейти в каталог"
                icName="bg-[url('./img/ic-basket-24.svg')]"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary ui-btn-sm"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Наведение">
            <template>
              <div class="parents-outline-btn">
                <ui-button
                  small
                  class="border-[var(--ph)]"
                  text="Перейти в каталог"
                  icName="bg-[url('./img/ic-basket-24.svg')]"
                />
              </div>
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary ui-btn-sm"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <template>
              <div class="parents-outline-btn">
                <ui-button
                  small
                  class="border-[var(--pf)]"
                  text="Перейти в каталог"
                  icName="bg-[url('./img/ic-basket-24.svg')]"
                />
              </div>
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn ui-btn-outline-primary ui-btn-sm"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительная кнопка">
            <template>
              <ui-button
                small
                disabled
                variant="ui-btn-outline-primary"
                text="Перейти в каталог"
                icName="bg-[url('./img/ic-basket-24.svg')]"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button disabled type="button" class="ui-btn ui-btn-outline-primary ui-btn-sm"&gt;
  &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
  Перейти в каталог
&lt;/button&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiButton from '@/components/ui/uiButton'

export default {
  name: 'ButtonsOutlineIcon',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-button': uiButton,
  },
}
</script>
<style lang="scss">
// для сайта дизайн система
.parents-outline-btn .ui-btn__ic {
  filter: var(--filter-black);
}
</style>
