<template>
  <common-wrapper titleWrapper="Icon Buttons">
    <template>
      <common-group
        titleGroup="Standard, height 56px"
        textGroup="Кнопка для соц. сетей и дополнительных действий"
      >
        <template>
          <common-item condition="Пассивное состояние">
            <template>
              <ui-btn-ic
                variant="ui-btn-ic-primary"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary" &gt;
  &lt;img src="../img/icons/ic-telegram-24.svg" class="ui-btn-ic__img"&gt;
&lt;/button&gt;</pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-btn-ic
     variant="ui-btn-ic-primary"
     src="../img/icons/ic-telegram-24.svg"
/&gt;

&lt;script&gt;
import uiButtonIcon from '@/components/ui/uiButtonIcon.vue'

export default {
  name: 'ButtonsIcon',
  components: {
    'ui-btn-ic': uiButtonIcon,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;button
      type="button"
      class="ui-btn-ic"
      :class="buttonClass"
      :disabled="disabled"
  &gt;
  &lt;img class="ui-btn-ic__img" :src="src" :alt="alt"&gt;
&lt;/button&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiButtonIcon',
  props: {
    variant: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    src: {
      required: true,
      type: String,
      default: null,
    },
    alt: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        'ui-btn-ic-primary': this.variant === 'ui-btn-ic-primary',
        'ui-btn-ic-sm': this.small === true,
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="При наведении">
            <template>
              <ui-btn-ic
                style="background: var(--ph)"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary" &gt;
  &lt;img src="../img/icons/ic-telegram-24.svg" class="ui-btn-ic__img"&gt;
&lt;/button&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <template>
              <ui-btn-ic
                style="background: var(--pf)"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary" &gt;
  &lt;img src="../img/icons/ic-telegram-24.svg" class="ui-btn-ic__img"&gt;
&lt;/button&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="Недействительная кнопка">
            <template>
              <ui-btn-ic
                disabled
                variant="ui-btn-ic-primary"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button disabled type="button" class="ui-btn-ic ui-btn-ic-primary" &gt;
  &lt;img src="../img/icons/ic-telegram-24.svg" class="ui-btn-ic__img"&gt;
&lt;/button&gt;</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Small buttons, height 40"
        textGroup="Кнопка для соц. сетей и дополнительных действий внутри небольших элементов"
      >
        <template>
          <common-item condition="Пассивное состояние">
            <template>
              <ui-btn-ic
                small
                variant="ui-btn-ic-primary"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm" &gt;
  &lt;img src="../img/icons/ic-telegram-24.svg" class="ui-btn-ic__img"&gt;
&lt;/button&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При наведении">
            <template>
              <ui-btn-ic
                small
                style="background: var(--ph)"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm bg-[url('./img/ic-telegram-24.svg')]"&gt;&lt;/button&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <template>
              <ui-btn-ic
                small
                style="background: var(--pf)"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button type="button" class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm bg-[url('./img/ic-telegram-24.svg')]"&gt;&lt;/button&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="Недействительная кнопка">
            <template>
              <ui-btn-ic
                small
                disabled
                variant="ui-btn-ic-primary"
                src="../img/icons/ic-telegram-24.svg"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;button disabled type="button" class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm bg-[url('./img/ic-telegram-24.svg')]" &gt;&lt;/button&gt;</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiButtonIcon from '@/components/ui/uiButtonIcon.vue'

export default {
  name: 'ButtonsIcon',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-btn-ic': uiButtonIcon,
  },
}
</script>
