<template>
  <common-wrapper
    titleWrapper="Icons"
    description-wrapper="Для изменения цвета иконки используем фильтр, пример filter: brightness(0) saturate(100%) invert(59%) sepia(99%) saturate(447%) hue-rotate(174deg) brightness(107%) contrast(98%);"
  >
    <ui-toasts
      @close="openToasts"
      :class="{ hidden: !hidden }"
      class="max-w-[500px]"
    >
      <template #toasts-slot>
        <p class="mb-2 w-full font-semibold">
          Пример: название иконки
          <span class="text-[var(--p)]">ic-alert-red-24.svg</span>
        </p>
        <ul class="small mb-0 w-full">
          <li class="flex">
            <span class="min-w-10 inline-block w-10 text-[var(--p)]">ic</span>-
            приставка, указывающая, что это иконка
          </li>
          <li class="flex">
            <span class="min-w-10 inline-block w-10 text-[var(--p)]">alert</span
            >- уникальное имя иконки
          </li>
          <li class="flex">
            <span class="min-w-10 inline-block w-10 text-[var(--p)]">red</span>-
            цвет, при его отсутствии используем дефолтный или черный цвет
          </li>
          <li class="flex">
            <span class="min-w-10 inline-block w-10 text-[var(--p)]">24</span>-
            размер в px
          </li>
          <li class="flex">
            <span class="min-w-10 inline-block w-10 text-[var(--p)]">.svg</span
            >- формат
          </li>
        </ul>
      </template>
    </ui-toasts>
    <p class="mb-2">
      Скачать
      <a href="https://disk.yandex.ru/d/GHezA-HbzUY4pA" target="_blank"
        >иконки</a
      >
    </p>
    <p class="mb-5">
      Онлайн
      <a
        href="https://angel-rs.github.io/css-color-filter-generator/"
        target="_blank"
        >фильтр-генератор</a
      >
    </p>
    <template>
      <common-group>
        <template>
          <common-item v-for="ic in icons" :key="ic.index" :condition="ic.name">
            <ul class="w-full">
              <li>
                <div
                  class="h-6 w-6"
                  :id="ic.name"
                  :class="ic.url"
                  style="filter: var(--filter-black)"
                ></div>
              </li>
            </ul>
            <template #html-slot>
              &lt;div class="{{ ic.url }}"&gt;&lt;/div&gt;
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>
<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiToasts from '@/components/ui/uiToasts.vue'

export default {
  name: 'iconsPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-toasts': uiToasts,
  },
  data() {
    return {
      icons: [
        {
          name: 'Alert',
          url: "bg-[url('./img/ic-alert-24.svg')]",
        },
        {
          name: 'Arrow-back',
          url: "bg-[url('./img/ic-arrow-left-24.svg')]",
        },
        {
          name: 'Arrow-down',
          url: "bg-[url('./img/ic-arrow-down-24.svg')]",
        },
        {
          name: 'Arrow forward',
          url: "bg-[url('./img/ic-arrow-right-24.svg')]",
        },
        {
          name: 'Arrow-up',
          url: "bg-[url('./img/ic-arrow-up-24.svg')]",
        },
        {
          name: 'Arrow-navigate-bottom',
          url: "bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]",
        },
        {
          name: 'Attach',
          url: "bg-[url('./img/ic-attach-24.svg')]",
        },
        {
          name: 'Attention',
          url: "bg-[url('./img/ic-attention-24.svg')]",
        },
        {
          name: 'Bag',
          url: "bg-[url('./img/ic-bag-24.svg')]",
        },
        {
          name: 'Basket',
          url: "bg-[url('./img/ic-basket-24.svg')]",
        },
        {
          name: 'Bookmark',
          url: "bg-[url('./img/ic-bookmark-24.svg')]",
        },
        {
          name: 'Burger',
          url: "bg-[url('./img/ic-burger-24.svg')]",
        },
        {
          name: 'Calendar',
          url: "bg-[url('./img/ic-calendar-24.svg')]",
        },
        {
          name: 'Cart',
          url: "bg-[url('./img/ic-cart-24.svg')]",
        },
        {
          name: 'Check',
          url: "bg-[url('./img/ic-check-24.svg')]",
        },
        {
          name: 'Check-white',
          url: "bg-[url('./img/ic-check-white-24.svg')]",
        },
        {
          name: 'Collapse',
          url: "bg-[url('./img/ic-collapse-24.svg')]",
        },
        {
          name: 'Cross',
          url: "bg-[url('./img/ic-cross-24.svg')]",
        },
        {
          name: 'Datepicker',
          url: "bg-[url('./img/ic-datepicker-24.svg')]",
        },
        {
          name: 'Dislike',
          url: "bg-[url('./img/ic-dislike-24.svg')]",
        },
        {
          name: 'Doc',
          url: "bg-[url('./img/ic-doc-24.svg')]",
        },
        {
          name: 'Doc-text',
          url: "bg-[url('./img/ic-doc-text-24.svg')]",
        },
        {
          name: 'Download',
          url: "bg-[url('./img/ic-download-24.svg')]",
        },
        {
          name: 'Email',
          url: "bg-[url('./img/ic-email-24.svg')]",
        },
        {
          name: 'Enter',
          url: "bg-[url('./img/ic-enter-24.svg')]",
        },
        {
          name: 'Exite',
          url: "bg-[url('./img/ic-exite-24.svg')]",
        },
        {
          name: 'Expand',
          url: "bg-[url('./img/ic-expand-24.svg')]",
        },

        {
          name: 'Expand-arrow',
          url: "bg-[url('./img/ic-expand-arr-24.svg')]",
        },
        {
          name: 'Eye',
          url: "bg-[url('./img/ic-eye-24.svg')]",
        },
        {
          name: 'Facebook',
          url: "bg-[url('./img/ic-facebook-24.svg')]",
        },
        {
          name: 'Filters',
          url: "bg-[url('./img/ic-filters-24.svg')]",
        },
        {
          name: 'Google',
          url: "bg-[url('./img/ic-google-24.svg')]",
        },
        {
          name: 'Heart',
          url: "bg-[url('./img/ic-heart-24.svg')]",
        },
        {
          name: 'Heart-fill',
          url: "bg-[url('./img/ic-heart-fill-24.svg')]",
        },
        {
          name: 'Instagram',
          url: "bg-[url('./img/ic-instagram-24.svg')]",
        },
        {
          name: 'Like',
          url: "bg-[url('./img/ic-like-24.svg')]",
        },

        {
          name: 'Loading',
          url: "bg-[url('./img/ic-loading-24.svg')]",
        },
        {
          name: 'Location',
          url: "bg-[url('./img/ic-location-24.svg')]",
        },
        {
          name: 'Mail',
          url: "bg-[url('./img/ic-mail-24.svg')]",
        },
        {
          name: 'Map',
          url: "bg-[url('./img/ic-map-24.svg')]",
        },
        {
          name: 'Message',
          url: "bg-[url('./img/ic-message-24.svg')]",
        },

        {
          name: 'Minus',
          url: "bg-[url('./img/ic-minus-24.svg')]",
        },
        {
          name: 'Ok',
          url: "bg-[url('./img/ic-ok-24.svg')]",
        },
        {
          name: 'Person',
          url: "bg-[url('./img/ic-person-24.svg')]",
        },
        {
          name: 'Phone',
          url: "bg-[url('./img/ic-phone-24.svg')]",
        },
        {
          name: 'Photo',
          url: "bg-[url('./img/ic-photo-24.svg')]",
        },

        {
          name: 'Play',
          url: "bg-[url('./img/ic-play-24.svg')]",
        },
        {
          name: 'Plus',
          url: "bg-[url('./img/ic-plus-24.svg')]",
        },
        {
          name: 'Preview',
          url: "bg-[url('./img/ic-preview-24.svg')]",
        },
        {
          name: 'Quote',
          url: "bg-[url('./img/ic-quote-24.svg')]",
        },
        {
          name: 'Reload',
          url: "bg-[url('./img/ic-reload-24.svg')]",
        },

        {
          name: 'Search',
          url: "bg-[url('./img/ic-search-24.svg')]",
        },
        {
          name: 'Settings',
          url: "bg-[url('./img/ic-settings-24.svg')]",
        },
        {
          name: 'Share',
          url: "bg-[url('./img/ic-share-24.svg')]",
        },
        {
          name: 'Star',
          url: "bg-[url('./img/ic-star-24.svg')]",
        },
        {
          name: 'Star-outline',
          url: "bg-[url('./img/ic-star-outline-24.svg')]",
        },
        {
          name: 'Telegram',
          url: "bg-[url('./img/ic-telegram-24.svg')]",
        },
        {
          name: 'Time',
          url: "bg-[url('./img/ic-time-24.svg')]",
        },
        {
          name: 'Trash',
          url: "bg-[url('./img/ic-trash-24.svg')]",
        },
        {
          name: 'User',
          url: "bg-[url('./img/ic-user-24.svg')]",
        },
        {
          name: 'Vk',
          url: "bg-[url('./img/ic-vk-24.svg')]",
        },
        {
          name: 'Youtube',
          url: "bg-[url('./img/ic-youtube-24.svg')]",
        },
      ],
      hidden: true,
    }
  },
  methods: {
    openToasts() {
      this.hidden = !this.hidden
    },
  },
}
</script>
<style>
#Check-white {
  background-color: #000;
}
</style>
