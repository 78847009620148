<template>
  <common-wrapper title-wrapper="Textarea">
    <template>
      <common-group title-group="Field with headline">
        <template>
          <common-item condition="Пустое поле">
            <ui-textarea id="id1" label="label text" />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
    &lt;label class="ui-textarea__label" for="name" &gt;label text&lt;/label&gt;
    &lt;textarea class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-textarea id="id" label="label text" /&gt;

&lt;script&gt;
import uiTextarea from '@/components/ui/uiTextarea'

export default {
  name: 'FieldsTextarea',
  components: {
    'ui-textarea': uiTextarea,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-textarea"&gt;
    &lt;label
        class="ui-textarea__label"
        :for="id"
    &gt; &#123; &#123; label &#125; &#125; &lt;/label
    &gt;
    &lt;textarea
        class="ui-textarea__field"
        type="text"
        :id="id"
        rows="7"
        :placeholder="placeholder"
        :value="value"
        :class="textareaClass"
        :disabled="disabled"
    &gt;
    &lt;/textarea&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;
                &#123; &#123; error &#125; &#125;
    &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiTextarea',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    textareaClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Заполненное поле">
            <ui-textarea id="id2" label="label text" value="text" />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;label class="ui-textarea__label" for="name" &gt;label text&lt;/label&gt;
  &lt;textarea class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-textarea
              id="id3"
              label="label text"
              error="Введите корректное значение"
              variant="error"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;label class="ui-textarea__label" for="name" &gt;label text&lt;/label&gt;
  &lt;textarea class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
  &lt;span class="m-0 block pt-3 text-base text-[#FF5B5B]"&gt;error&lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-textarea id="id4" disabled="disabled" label="label text" />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;label class="ui-textarea__label" for="name" &gt;label text&lt;/label&gt;
  &lt;textarea disabled class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Placeholder">
        <template>
          <common-item condition="Пустое поле">
            <ui-textarea id="id5" placeholder="placeholder" />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;textarea placeholder="placeholder" class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Заполненное поле">
            <ui-textarea id="id6" value="text" placeholder="placeholder" />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;textarea placeholder="placeholder" class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-textarea
              id="id7"
              placeholder="placeholder"
              error="Введите корректное значение"
              variant="error"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;textarea placeholder="placeholder" class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
  &lt;span class="m-0 block pt-3 text-base text-[#FF5B5B]"&gt;error&lt;/span&gt;
&lt;/div&gt;
             </pre
              >
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-textarea
              id="id8"
              disabled="disabled"
              placeholder="placeholder"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-textarea"&gt;
  &lt;textarea disabled placeholder="placeholder" class="ui-textarea__field" type="text" id="name" rows="7"&gt;&lt;/textarea&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiTextarea from '@/components/ui/uiTextarea'

export default {
  name: 'FieldsTextarea',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-textarea': uiTextarea,
  },
}
</script>
