<template>
  <common-wrapper title-wrapper="Text Fields">
    <template>
      <common-group title-group="Field with headline, height 56px">
        <template>
          <common-item condition="Пустое поле">
            <ui-input id="id1" label="Ваше имя" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-input id="id"
          label="Ваше имя"
          type="text"
          placeholder="Ваше имя"
          :passwordButton="false"
          :disabled="false"
          error="error"
          variant="error"
/&gt;

&lt;script&gt;
import uiInput from '@/components/ui/uiInput'

export default {
  name: 'FieldsInput',
  components: {
    'ui-input': uiInput,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label :for="id" class="ui-input__label"&gt;
      &#123; &#123; label &#125; &#125;
    &lt;/label&gt;
    &lt;div class="ui-input__field"&gt;
      &lt;input
          :id="id"
          :class="inputClass"
          :disabled="disabled"
          :placeholder="placeholder"
          :type="type"
          :value="value"
          @input="updateValue"
          autocomplete="on"
          class="ui-input__field--input"
      /&gt;
      &lt;button
          @click.prevent="togglePassword"
          v-if="passwordButton"
          class="ui-input__field--btn eye bg-[url('./img/ic-eye-24.svg')]"
      &gt;
      &lt;/button&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt; &#123; &#123;
        error
      &#125; &#125; &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiInput',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    passwordButton: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
  methods: {
    updateValue() {
      this.$emit('updateValue')
    },
    togglePassword() {
      this.$emit('togglePassword')
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Заполненное поле">
            <ui-input id="id2" label="Ваше имя" value="Иванов Петр Петрович" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-input
              id="id3"
              variant="error"
              label="Ваше имя"
              value="7457465"
              error="Введите корректное значение"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
  &lt;span class="m-0 block pt-3 text-base text-[#FF5B5B]"&gt;error&lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-input id="id4" :disabled="true" label="Ваше имя" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
  &lt;div class="ui-input__field" &gt;
   &lt;input disabled class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Input Text">
        <template>
          <common-item condition="Пустое поле">
            <ui-input id="id5" placeholder="Ваше имя" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input placeholder="Ваше имя" class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Заполненное поле">
            <ui-input
              id="id6"
              placeholder="Ваше имя"
              value="Иванов Петр Петрович"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input placeholder="Ваше имя" class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-input
              id="id7"
              variant="error"
              value="7457465"
              error="Введите корректное значение"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;div class="ui-input__field" &gt;
   &lt;input placeholder="Ваше имя" class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
  &lt;span class="m-0 block pt-3 text-base text-[#FF5B5B]"&gt;error&lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-input id="id8" :disabled="true" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;div class="ui-input__field" &gt;
    &lt;input disabled placeholder="Ваше имя" class="ui-input__field--input" type="text" id="name" /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiInput from '@/components/ui/uiInput'

export default {
  name: 'FieldsInput',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-input': uiInput,
  },
}
</script>
