<template>
  <common-wrapper
    title-wrapper="Select Fields"
    description-wrapper="Используем vue-select или choices-js"
  >
    <template>
      <common-group title-group="vue-select">
        <template>
          <common-item condition="Не выбрано">
            <ui-select label="Ваш город" :options="options" />
            <template #html-slot>
              <pre>
 &lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name"&gt;Ваш город&lt;/label&gt;
  &lt;v-select :options="options" :class="{ error: error }" :disabled="disabled"&gt;&lt;/v-select&gt;
  &lt;span class="ui-text-invalid block pt-3"&gt;
    error
  &lt;/span&gt;
 &lt;/div&gt;
             </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-select label="Ваш город" :options="options" /&gt;

&lt;script&gt;
import uiSelect from '@/components/ui/uiSelect'

export default {
  name: 'FieldsSelect',
  components: {
    'ui-select': uiSelect,
  },
  data() {
    return {
      options: [
        'Москва',
        'Санкт-Петербург',
        'Екатеринбург',
        'Новосибирск',
        'Ижевск',
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label"&gt; &#123; &#123;label&#125; &#125; &lt;/label&gt;
    &lt;v-select :options="options" :class=" &#123; error: error &#125;" :multiple="multiple" :disabled="disabled"/&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt; &#123; &#123;
        error
      &#125; &#125; &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: 'uiSelect',
  props: {
    error: {
      type: String,
      required: false,
      default: null,
    },
    disabled:{
      type: Boolean,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
     options: {
      type: Array
    },
  }
}
&lt;/script&gt;

&lt;style lang="scss"&gt;
.v-select {
  width: 100%;
  &.error {
    .vs__dropdown-toggle {
      .vs__selected-options {
        border: 1px solid var(--e);
      }
    }
  }
  .vs__dropdown-toggle {
    width: 100%;
    position: relative;
    .vs__selected-options {
      width: 100%;
      border: 1px solid var(--b4);
      border-radius: 6px;
      padding: 0 48px 0 16px;
      height: 56px;
      display: flex;
      align-items: center;
      .vs__selected {
        white-space: nowrap;
        font-size: 16px;
        line-height: 140%;
        color: var(--bc);
        margin-right: 16px;
        display: flex;
        align-items: center;
        .vs__deselect {
          width: 16px;
          height: 16px;
          background-image: url('@/assets/img/ic-cross-24.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 4px;
          filter: var(--filter-black);
          svg {
            display: none;
          }
        }
      }
      .vs__search {
        width: 0;
        background: transparent;
        height: 100%;
        font-size: 16px;
        line-height: 140%;
        color: #000;
      }
    }

    .vs__actions {
      .vs__clear {
        display: none;
      }

      .vs__open-indicator {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url('@/assets/img/ic-arrow-navigate-bottom-24.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 2;
        filter: var(--filter-black);
        path {
          display: none;
        }
      }

      .vs__spinner {
        display: none;
      }
    }
  }

  .vs__dropdown-menu {
    background: #f0f6f6;
    border-radius: 6px;
    margin-top: 8px;

    .vs__dropdown-option {
      padding: 16px 24px;
      font-size: 16px;
      line-height: 140%;
      color: #2d3236;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }

    .vs__dropdown-option--highlight {
      color: #fff;
      background: var(--p);
    }

    .vs__dropdown-option--disabled {
      color: #fff;
      background: var(--b4);
    }
    .vs__no-options{
      color: #000;
      padding: 8px;
    }
  }

  &.vs--disabled {
    .vs__dropdown-toggle .vs__selected-options {
      background: var(--b3);
      border-color: var(--b4);
    }
  }

  &.vs--multiple {
    .vs__dropdown-toggle .vs__selected-options {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      .vs__selected {
        display: inline;
        vertical-align: middle;
        .vs__deselect {
          vertical-align: middle;
        }
      }
    }
  }
}
&lt;/style&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Множественные выбор">
            <ui-select label="Ваш город" :multiple="true" :options="options" />
            <template #html-slot>
              <pre>
 &lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name"&gt;Ваш город&lt;/label&gt;
  &lt;v-select :options="options" :class="{ error: error }" :multiple="true"&gt;&lt;/v-select&gt;
  &lt;span class="ui-text-invalid block pt-3"&gt;
    error
  &lt;/span&gt;
 &lt;/div&gt;
             </pre
              >
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-select
              label="Ваш город"
              error="Обязательное поле"
              :options="options"
            />
            <template #html-slot>
              <pre>
 &lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name"&gt;Ваш город&lt;/label&gt;
  &lt;v-select :options="options" :class="{ error: error }" :disabled="disabled"&gt;&lt;/v-select&gt;
  &lt;span class="ui-text-invalid block pt-3"&gt;
    error
  &lt;/span&gt;
 &lt;/div&gt;
             </pre
              >
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-select label="Ваш город" disabled :options="options" />
            <template #html-slot>
              <pre>
 &lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="name"&gt;Ваш город&lt;/label&gt;
  &lt;v-select :options="options" :class="{ error: error }" :disabled="disabled"&gt;&lt;/v-select&gt;
  &lt;span class="ui-text-invalid block pt-3"&gt;
    error
  &lt;/span&gt;
 &lt;/div&gt;
             </pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="choices-js">
        <common-item condition="Не выбрано">
          <ui-select label="Ваш город" :options="options" />
          <template #html-slot>
            <pre>
 &lt;div class="ui-input"&gt;
     &lt;label class="ui-input__label" for="name">Ваш город &lt;/label&gt;
     &lt;select class="ui-choice"&gt;
         &lt;option&gt; &lt;/option&gt;
         &lt;option value="1"&gt;Санкт-Петербург &lt;/option&gt;
         &lt;option value="2"&gt;Екатеринбург &lt;/option&gt;
         &lt;option value="3"&gt;Новосибирск &lt;/option&gt;
     &lt;/select&gt;
     &lt;span class="ui-text-invalid pt-3">error &lt;/span&gt;
 &lt;/div&gt;
             </pre
            >
          </template>
          <template #cssSlot>
            <pre>
        .choices{
            margin: 0;
        }
        .choices__inner{
            background-color: transparent;
            padding: 16px 48px 16px 16px;
            border: 1px solid var(--b4);
            border-radius: 6px;
            min-height: 56px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
        }
        .choices__list--single{
            padding: 0;
        }
        .choices::after,
        .choices[data-type*=select-one]::after{
            content: "";
            border: none;
            pointer-events: none;
            position: absolute;
            width: 24px;
            height: 24px;
            right: 16px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url("/src/assets/img/ic-arrow-navigate-bottom-24.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 2;
        }
        .choices[data-type*=select-one].is-open::after{
            margin: auto;
        }
        .choices[data-type*=select-one] .choices__inner {
            padding-bottom: 16px;
        }
        .choices[data-type*=select-one] .choices__button{
            display: none;
        }
        .choices__list--multiple .choices__item{
            padding: 0;
            margin: 0 16px 0 0;
        }
        .choices__placeholder {
            display: none;
        }
        .choices__list--dropdown, .choices__list[aria-expanded] {
            margin-top: 8px;
            background-color: var(--b5);
            border:none;
            border-radius: 6px;
        }
        .choices__list--dropdown .choices__item--selectable.is-highlighted,
        .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
            background-color: var(--p);
            color: #fff;
        }
        .choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
            padding: 16px 24px;

            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
        }
        .choices__list--dropdown .choices__item--selectable.is-highlighted::after,
        .choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
            opacity: 1;
            color:var(--b2);
        }
        .choices__list--multiple {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 1ch);
                vertical-align: middle;
        }
        .choices__input {
            background-color: transparent;
            font-size: 16px;
            margin-bottom: 0;
            padding: 0;
                vertical-align: middle;
        }
        .choices__list--multiple .choices__item[data-deletable] {
            padding-right: 0;
        }
        .choices__list--multiple .choices__item {
            display: inline;
            vertical-align: middle;
            border-radius: 0;
            padding: 0;
            font-size: 16px;
            font-weight: normal;
            margin:0 16px 0 0;
            background-color: transparent;
            border: none;
            color: var(--b2);
        }
        .choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
            margin: 0 0 0 4px;
            padding: 0;
            border: none;
            background-image: url('/src/assets/img/ic-cross-24.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 16px;
            opacity: 1;
            border-radius: 0;
        }
        .choices__list--multiple .choices__item.is-highlighted{
            background: transparent;
            border: none;
        }
        .choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
            border-color: var(--b4);
            background-color: var(--b3);
        }
            </pre>
          </template>
          <template #jsSlot>
            <pre>
 let initChoices = function () {
    const uiChoices = document.getElementsByClassName('ui-choice');
    if (uiChoices) {
        for (let uiChoice of uiChoices) {
            new Choices(uiChoice, {
                removeItemButton: true,
                searchEnabled: false,
                loadingText: 'Загрузка...',
                noResultsText: 'Результатов не найдено',
                noChoicesText: 'Нет выбора, из которого можно было бы выбирать',
                itemSelectText: 'Нажмите, чтобы выбрать',
                uniqueItemText: 'Могут быть добавлены только уникальные значения',
                customAddItemText: 'Могут быть добавлены только значения, соответствующие определенным условиям',
            })
        }
    }
}
            </pre>
          </template>
        </common-item>

        <common-item condition="Множественные выбор">
          <ui-select label="Ваш город" :multiple="true" :options="options" />
          <template #html-slot>
            <pre>
 &lt;div class="ui-input"&gt;
     &lt;label class="ui-input__label" for="name">Ваш город &lt;/label&gt;
     &lt;select multiple class="ui-choice"&gt;
         &lt;option&gt; &lt;/option&gt;
         &lt;option value="1"&gt;Санкт-Петербург &lt;/option&gt;
         &lt;option value="2"&gt;Екатеринбург &lt;/option&gt;
         &lt;option value="3"&gt;Новосибирск &lt;/option&gt;
     &lt;/select&gt;
     &lt;span class="ui-text-invalid pt-3">error &lt;/span&gt;
 &lt;/div&gt;
             </pre
            >
          </template>
        </common-item>
        <common-item condition="Ошибка">
          <ui-select
            label="Ваш город"
            error="Обязательное поле"
            :options="options"
          />
          <template #html-slot>
            <pre>
 &lt;div class="ui-input"&gt;
     &lt;label class="ui-input__label" for="name">Ваш город &lt;/label&gt;
     &lt;select class="ui-choice"&gt;
         &lt;option&gt; &lt;/option&gt;
         &lt;option value="1"&gt;Санкт-Петербург &lt;/option&gt;
         &lt;option value="2"&gt;Екатеринбург &lt;/option&gt;
         &lt;option value="3"&gt;Новосибирск &lt;/option&gt;
     &lt;/select&gt;
     &lt;span class="ui-text-invalid block pt-3">error &lt;/span&gt;
 &lt;/div&gt;
             </pre
            >
          </template>
        </common-item>
        <common-item condition="Недействительное поле">
          <ui-select label="Ваш город" disabled :options="options" />
          <template #html-slot>
            <pre>
 &lt;div class="ui-input"&gt;
     &lt;label class="ui-input__label" for="name">Ваш город &lt;/label&gt;
     &lt;select disabled class="ui-choice"&gt;
         &lt;option&gt; &lt;/option&gt;
         &lt;option value="1"&gt;Санкт-Петербург &lt;/option&gt;
         &lt;option value="2"&gt;Екатеринбург &lt;/option&gt;
         &lt;option value="3"&gt;Новосибирск &lt;/option&gt;
     &lt;/select&gt;
     &lt;span class="ui-text-invalid pt-3">error &lt;/span&gt;
 &lt;/div&gt;
             </pre
            >
          </template>
        </common-item>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiSelect from '@/components/ui/uiSelect'

export default {
  name: 'FieldsSelect',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-select': uiSelect,
  },
  data() {
    return {
      options: [
        'Москва',
        'Санкт-Петербург',
        'Екатеринбург',
        'Новосибирск',
        'Ижевск',
      ],
    }
  },
}
</script>
