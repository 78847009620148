<template>
  <common-wrapper
    title-wrapper="Alerts"
    description-wrapper="Показывайте пользователям контекстную информацию с помощью элементов предупреждений"
  >
    <template>
      <common-group>
        <template>
          <common-item condition="Danger alert">
            <ui-alert
              title="Danger alert!"
              text="Change a few things up and try submitting again."
              variant="ui-alert-danger"
            />

            <template #html-slot>
              <pre>
&lt;div class="ui-alert ui-alert-danger"&gt;
  &lt;div class="wrapper"&gt;
    &lt;p class="ui-alert__title"&gt; Danger alert! &lt;/p&gt;
    &lt;p class="ui-alert__text"&gt; Change a few things up and try submitting again.&lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-alert
      title="Danger alert!"
      text="Change a few things up and try submitting again."
      variant="ui-alert-danger"
/&gt;

&lt;script&gt;
  import uiAlert from '@/components/ui/uiAlert.vue'

  export default {
    name: 'alertsElement',
    components: {
      'ui-alert': uiAlert,
    },
  }
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-alert" :class="alertClass"&gt;
    &lt;div class="wrapper"&gt;
      &lt;p class="ui-alert__title"&gt;
        &#123; &#123; title &#125; &#125;
      &lt;/p&gt;
      &lt;p class="ui-alert__text"&gt;
         &#123; &#123; text &#125; &#125;
      &lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiAlert",
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed:{
    alertClass() {
      return {
        'ui-alert-danger': this.variant === 'ui-alert-danger',
        'ui-alert-info': this.variant === 'ui-alert-info',
        'ui-alert-success': this.variant === 'ui-alert-success',
      }
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Info alert">
            <ui-alert
              title="Info alert!"
              text="Change a few things up and try submitting again."
              variant="ui-alert-info"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-alert ui-alert-info"&gt;
  &lt;div class="wrapper"&gt;
    &lt;p class="ui-alert__title"&gt; Info alert! &lt;/p&gt;
    &lt;p class="ui-alert__text"&gt; Change a few things up and try submitting again.  &lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Success alert">
            <ui-alert
              title="Success alert!"
              text="Change a few things up and try submitting again."
              variant="ui-alert-success"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-alert ui-alert-success"&gt;
  &lt;div class="wrapper"&gt;
   &lt;p class="ui-alert__title"&gt; Success alert! &lt;/p&gt;
   &lt;p class="ui-alert__text"&gt; Change a few things up and try submitting again.  &lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiAlert from '@/components/ui/uiAlert.vue'

export default {
  name: 'alertsElement',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-alert': uiAlert,
  },
}
</script>
