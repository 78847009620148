<template>
  <common-wrapper
    title-wrapper="Links"
    description-wrapper="Компонент ссылки можно использовать для установки гиперссылок с одной страницы на другую или на внешний веб-сайт при нажатии на встроенный текстовый элемент."
  >
    <template>
      <common-group title-group="Standard links">
        <template>
          <common-item condition="Пассивное состояние">
            <a href="#" class="ui-link">Перейти в каталог</a>
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При наведении">
            <a
              href="#"
              class="inline-flex text-base font-semibold text-[var(--ph)] hover:text-[var(--ph)] active:text-[var(--ph)]"
              >Перейти в каталог</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <a
              href="#"
              class="inline-flex text-base font-semibold text-[var(--pf)] hover:text-[var(--pf)] active:text-[var(--pf)]"
              >Перейти в каталог</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Small links">
        <template>
          <common-item condition="Пассивное состояние">
            <a href="#" class="ui-link text-xs">Перейти в каталог</a>
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При наведении">
            <a
              href="#"
              class="inline-flex text-xs font-semibold text-[var(--ph)] hover:text-[var(--ph)] active:text-[var(--ph)]"
              >Перейти в каталог</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="При нажатии">
            <a
              href="#"
              class="inline-flex text-xs font-semibold text-[var(--pf)] hover:text-[var(--pf)] active:text-[var(--pf)]"
              >Перейти в каталог</a
            >
            <template #html-slot>
              <pre>
&lt;a href="#" class="ui-link text-xs"&gt;Перейти в каталог&lt;/a&gt;</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'LinksText',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
}
</script>

<style scoped></style>
