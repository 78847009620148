<template>
  <div class="ui-tooltip">
    <div class="ui-tooltip-container">
      <p class="mb-0 text-sm">
        {{ text }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiTooltip',
  props: {
    text: {
      required: true,
      type: String,
    },
  },
}
</script>
