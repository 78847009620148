<template>
  <div class="ui-input">
    <label :for="id" class="ui-input__label">
      {{ label }}
    </label>
    <div class="ui-input__field">
      <input
        :id="id"
        :class="inputClass"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="updateValue"
        class="ui-input__field--input"
        autocomplete="on"
      />
      <button
        @click.prevent="togglePassword"
        v-if="passwordButton"
        class="eye ui-input__field--btn bg-[url('./img/ic-eye-24.svg')]"
      ></button>
    </div>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'uiInput',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    passwordButton: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
  methods: {
    updateValue() {
      this.$emit('updateValue')
    },
    togglePassword() {
      this.$emit('togglePassword')
    },
  },
}
</script>
