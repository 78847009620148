<template>
  <label :for="id" :class="checkboxClass" class="ui-checkbox-btn">
    <input
      :id="id"
      :type="type"
      :checked="checked"
      :disabled="disabled"
      :name="name"
      class="ui-checkbox-btn__input"
    />
    <span class="ui-checkbox-btn__text">{{ text }}</span>
  </label>
</template>

<script>
export default {
  name: 'uiCheckboxButton',
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    type: {
      type: String,
      default: 'checkbox',
      required: false,
    },
    text: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    name: {
      required: false,
      type: String,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    checkboxClass() {
      return {
        'ui-checkbox-primary': this.variant === 'ui-checkbox-primary',
      }
    },
  },
}
</script>
