<template>
  <table>
    <thead>
      <tr>
        <th v-for="col in columns" :key="col.id">{{ col }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in rows" :key="row.id">
        <td v-for="col in columns" :key="col.id">{{ row[col] }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'uiTable',
  props: {
    rows: {
      type: Array,
    },
    columns: {
      type: Array,
    },
  },
}
</script>
