<template>
  <common-wrapper
    titleWrapper="Colors"
    description-wrapper="Используйте квадратные скобки (bg-[#5BC9FC]), чтобы сгенерировать свойство на лету, используя любое произвольное значение."
  >
    <ui-toasts
      @close="openToasts"
      :class="{ hidden: !hidden }"
      class="max-w-[500px]"
    >
      <template #toasts-slot>
        <p class="mb-0 w-full font-semibold">
          Испольуем
          <a
            href="https://developer.mozilla.org/en-US/docs/Web/CSS/--*"
            target="_blank"
            class="ui-link text-[var(--p)]"
          >
            custom properties
          </a>
        </p>
      </template>
    </ui-toasts>
    <template>
      <common-group
        titleGroup="Акцентные цвета"
        text-group="Такие как primary, secondary, accent, neutral"
      >
        <template>
          <common-item condition="primary (основной цвет), переменная var(--p)">
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--p)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--p)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="primary-hover (основной цвет hover), переменная var(--ph)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--ph)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--ph)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="primary-focus (основной цвет focus), переменная var(--pf)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--pf)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--pf)]"&gt;&lt;/div&gt;
            </template>
          </common-item>

          <common-item
            condition="secondary (вторичный цвет), переменная var(--s)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--s)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--s)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="secondary-hover (вторичный цвет hover), переменная var(--sh)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--sh)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--sh)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="secondary-focus (вторичный цвет focus), переменная var(--sf)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--sf)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--sf)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Базовые цвета"
        text-group="От светлого к темному"
      >
        <template>
          <common-item
            condition="base-100 (базовый цвет страницы, используется для пустого фона), переменная var(--b1)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--b1)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--b1)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="base-200 (немного  темнее), переменная var(--b2)"
          >
            <div
              class="bor h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--b2)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--b2)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="base-300 (немного  темнее), переменная var(--b3)"
          >
            <div
              class="bor h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--b3)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--b3)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="base-400 (немного  темнее), переменная var(--b4)"
          >
            <div
              class="bor h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--b4)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--b4)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="base-500 (немного  темнее), переменная var(--b5)"
          >
            <div
              class="bor h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--b5)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--b5)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="base-content (Цвет содержимого для использования в качестве основного цвета), переменная var(--bc)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--bc)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--bc)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group titleGroup="Статусные цвета">
        <template>
          <common-item
            condition="info (информационный цвет), переменная var(--i)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--i)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--i)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item condition="success ( цвет успеха), переменная var(--su)">
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--su)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--su)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="warning ( цвет предупреждения), переменная var(--w)"
          >
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--w)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--w)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item condition="error ( цвет ошибки), переменная var(--e)">
            <div
              class="h-24 w-80 rounded-md border-2 border-slate-500 bg-[var(--e)]"
            ></div>
            <template #html-slot>
              &lt;div class="bg-[var(--e)]"&gt;&lt;/div&gt;
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Filter"
        text-group="Используем для изменения цвета черных иконок "
      >
        <template>
          <common-item condition="filter-black, переменная var(--filter-black)">
            <div
              class="filter-black h-24 w-80 rounded-md border-2 border-slate-500 bg-[#000]"
            ></div>
            <template #html-slot>
              &lt;div class="filter-black"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="filter-primary, переменная var(--filter-primary)"
          >
            <div
              class="filter-primary h-24 w-80 rounded-md border-2 border-slate-500 bg-[#000]"
            ></div>
            <template #html-slot>
              &lt;div class="filter-primary"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="filter-primary-h (:hover), переменная var(--filter-h)"
          >
            <div
              class="filter-primary-h h-24 w-80 rounded-md border-2 border-slate-500 bg-[#000]"
            ></div>
            <template #html-slot>
              &lt;div class="filter-primary-h"&gt;&lt;/div&gt;
            </template>
          </common-item>
          <common-item
            condition="filter-primary-f (:focus), переменная var(--filter-f)"
          >
            <div
              class="filter-primary-f h-24 w-80 rounded-md border-2 border-slate-500 bg-[#000]"
            ></div>
            <template #html-slot>
              &lt;div class="filter-primary-f"&gt;&lt;/div&gt;
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiToasts from '@/components/ui/uiToasts.vue'

export default {
  name: 'ColorsPage',
  components: {
    'ui-toasts': uiToasts,
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
  data() {
    return {
      hidden: true,
    }
  },
  methods: {
    openToasts() {
      this.hidden = !this.hidden
    },
  },
}
</script>

<style scoped></style>
