<template>
  <common-wrapper titleWrapper="Navs & tabs">
    <template>
      <common-group title-group="Nav Tab">
        <template>
          <common-item>
            <ui-nav-tab :itemsNavTab="itemsNavTab" />
            <template #html-slot>
              <pre>
&lt;div class="ui-nav-tab" data-group-static&gt;
    &lt;ul class="ui-nav-tab__nav"&gt;
        &lt;li&gt;
            &lt;button class="ui-nav-tab__nav--btn active"
                    data-static-toggle="toggle-1"
            &gt;
                button 1
            &lt;/button&gt;
        &lt;/li&gt;
        &lt;li&gt;
            &lt;button class="ui-nav-tab__nav--btn"
                    data-static-toggle="toggle-2"&gt;
                button 2
            &lt;/button&gt;
        &lt;/li&gt;
    &lt;/ul&gt;
    &lt;div class="ui-tab-container-wrapper"&gt;
        &lt;div class="ui-tab-container pt-5" id="toggle-1"&gt;
            &lt;div&gt;
                &lt;h3&gt;title 1&lt;/h3&gt;
                &lt;p&gt;desc&lt;/p&gt;
            &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="ui-tab-container pt-5 hidden" id="toggle-2"&gt;
            &lt;div&gt;
                &lt;h3&gt;title &lt;/h3&gt;
                &lt;p&gt;desc&lt;/p&gt;
            &lt;/div&gt;
        &lt;div&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #jsSlot>
              <pre>
        let initStaticToggle = function () {
            let toggleStaticButtons = document.querySelectorAll("[data-static-toggle]")
            if (toggleStaticButtons) {
                for (let i = 0; i &lt; toggleStaticButtons.length; i++) {
                    let staticToggle = toggleStaticButtons[i],
                        staticToggleDataset = staticToggle.dataset.staticToggle,
                        toggleBox = document.getElementById(staticToggleDataset)
                    toggleStaticButtons[i].addEventListener('click', function () {
                        let uiTabContainer = this.closest("[data-group-static]").querySelector(".ui-tab-container-wrapper").children
                        for (let i = 0; i &lt; uiTabContainer.length; i++) {
                            uiTabContainer[i].classList.add("hidden")
                        }
                        let parentLi = this.parentNode.parentNode.children
                        for (let i = 0; i &lt; parentLi.length; i++) {
                            parentLi[i].querySelector("[data-static-toggle]").classList.remove("active")
                        }
                        staticToggle.classList.add("active")
                        toggleBox.classList.toggle("hidden")
                    });
                }
            }
        }
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-nav-tab :itemsNavTab="itemsNavTab"/>&gt;

&lt;script&gt;
import uiNavTab from "@/components/ui/uiNavTab.vue";

export default {
  name: 'NavsAndTabs',
  components: {
    'ui-nav-tab': uiNavTab,
  },
  data() {
    return {
      itemsNavTab: [
        {
          id: 'tab-1',
          category: 'Shopping',
          title:'Shopping',
          desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        {
          id: 'tab-2',
          category: 'Videos',
          title:'Videos',
          desc:'Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero'
        },
        {
          id: 'tab-3',
          category: 'Images',
          title:'Images',
          desc:' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
      ],
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-nav-tab"&gt;
    &lt;ul class="ui-nav-tab__nav"&gt;
      &lt;li v-for="item in itemsNavTab"
          :key="item.id"
      &gt;
        &lt;button class="ui-nav-tab__nav--btn"
                :class="{active:isShowNavTab === item.id}"
                @click.prevent="toggleShow(item)"&gt;
          &#123; &#123; item.category &#125; &#125;
        &lt;/button&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
    &lt;div class="ui-tab-container-wrapper"&gt;
      &lt;div class="ui-tab-container pt-5 "
           v-for="item in itemsNavTab"
           :id="item.id" :key="item.id"
           :class="{ hidden: isShowNavTab !== item.id }"&gt;
        &lt;div&gt;
          &lt;h3&gt; &#123; &#123; item.title &#125; &#125; &lt;/h3&gt;
          &lt;p&gt; &#123; &#123; item.desc &#125; &#125; &lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiNavTab",
  data() {
    return {
      isShowNavTab: 'tab-1'
    }
  },
  props: {
    itemsNavTab: {
      type: Array,
    }
  },
  methods: {
    toggleShow(item) {
      this.isShowNavTab = item.id
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Nav Menu">
        <template>
          <common-item>
            <ui-nav-menu :itemsNavMenu="itemsNavMenu" />
            <template #html-slot>
              <pre>
&lt;div class="grid grid-cols-12 gap-4" data-group-static&gt;
    &lt;ul class="ui-nav-menu col-span-3"&gt;
        &lt;li&gt;
            &lt;button class="ui-nav-menu__btn active" data-static-toggle="nav-1"&gt;
                button
            &lt;/button&gt;
        &lt;/li&gt;
        &lt;li&gt;
            &lt;button class="ui-nav-menu__btn" data-static-toggle="nav-2"&gt;
                button
            &lt;/button&gt;
        &lt;/li&gt;
    &lt;/ul&gt;
    &lt;div class="ui-tab-container-wrapper col-span-9"&gt;
        &lt;div class="ui-tab-container " id="nav-1"&gt;
            &lt;div&gt;
                &lt;h3&gt;title 1&lt;/h3&gt;
                &lt;p&gt;desc&lt;/p&gt;
            &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="ui-tab-container col-span-9 hidden" id="nav-2"&gt;
            &lt;div&gt;
                &lt;h3&gt;title 2&lt;/h3&gt;
                &lt;p&gt;desc&lt;/p&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #jsSlot>
              <pre>
        let initStaticToggle = function () {
            let toggleStaticButtons = document.querySelectorAll("[data-static-toggle]")
            if (toggleStaticButtons) {
                for (let i = 0; i &lt; toggleStaticButtons.length; i++) {
                    let staticToggle = toggleStaticButtons[i],
                        staticToggleDataset = staticToggle.dataset.staticToggle,
                        toggleBox = document.getElementById(staticToggleDataset)
                    toggleStaticButtons[i].addEventListener('click', function () {
                        let uiTabContainer = this.closest("[data-group-static]").querySelector(".ui-tab-container-wrapper").children
                        for (let i = 0; i &lt; uiTabContainer.length; i++) {
                            uiTabContainer[i].classList.add("hidden")
                        }
                        let parentLi = this.parentNode.parentNode.children
                        for (let i = 0; i &lt; parentLi.length; i++) {
                            parentLi[i].querySelector("[data-static-toggle]").classList.remove("active")
                        }
                        staticToggle.classList.add("active")
                        toggleBox.classList.toggle("hidden")
                    });
                }
            }
        }
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-nav-menu :itemsNavMenu="itemsNavMenu" /&gt;
&lt;script&gt;
import uiNavMenu from '@/components/ui/uiNavMenu.vue'

export default {
  name: 'NavsAndTabs',
  components: {
    'ui-nav-menu': uiNavMenu,
  },
  data() {
    return {
      itemsNavMenu: [
        {
          id: 'tab-menu-1',
          category: 'Профиль',
          title: 'Profile',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        },
        {
          id: 'tab-menu-2',
          category: 'Избранное',
          title: 'Favourites',
          desc: 'Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero',
        },
        {
          id: 'tab-menu-3',
          category: 'Ваши заказы',
          title: 'Your orders',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
        {
          id: 'tab-menu-4',
          category: 'Возвраты',
          title: 'Refunds',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
        {
          id: 'tab-menu-5',
          category: 'Настройки',
          title: 'Settings',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
  &lt;template&gt;
  &lt;div class="grid grid-cols-12 gap-4"&gt;
    &lt;ul class="ui-nav-menu col-span-3"&gt;
      &lt;li v-for="item in itemsNavMenu" :key="item.id"&gt;
        &lt;button
            class="ui-nav-menu__btn"
            :class="{ active: isShowNavMenu === item.id }"
            @click.prevent="toggleShow(item)"
        &gt;
          &#123; &#123; item.category &#125; &#125;
        &lt;/button&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
    &lt;div class="ui-tab-container-wrapper col-span-9"&gt;
      &lt;div
          class="ui-tab-container"
          v-for="item in itemsNavMenu"
          :id="item.id"
          :key="item.id"
          :class="{ active: isShowNavMenu === item.id }"
      &gt;
        &lt;div&gt;
          &lt;h3&gt; &#123; &#123; item.title &#125; &#125; &lt;/h3&gt;
          &lt;p&gt; &#123; &#123; item.desc &#125; &#125; &lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiNavMenu',
  data() {
    return {
      isShowNavMenu: 'tab-menu-1',
    }
  },
  props: {
    itemsNavMenu: {
      type: Array,
    },
  },
  methods: {
    toggleShow(item) {
      this.isShowNavMenu = item.id
    },
  },
}
&lt;/script&gt;

</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Nav category">
        <template>
          <common-item>
            <div style="max-width: 148px">
              <ui-nav-category :itemsNavCategory="itemsNavCategory" />
            </div>
            <template #html-slot>
              <pre>
    &lt;ul class="ui-nav-category"&gt;
        &lt;li class="space-y-4"&gt;
            &lt;a href="/photography "&gt;Фототовары&lt;/a&gt;
            &lt;ul class="space-y-3"&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Фотокамеры&lt;/a&gt; &lt;/li&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Объективы&lt;/a&gt; &lt;/li&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Аксессуары&lt;/a&gt; &lt;/li&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Световое оборудование&lt;/a&gt; &lt;/li&gt;
                &lt;li class="hidden"&gt;&lt;a href="#" class="text-xs"&gt;Штативы&lt;/a&gt;&lt;/li&gt;
                &lt;li class="hidden"&gt;&lt;a href="#" class="text-xs"&gt;Селфи палки&lt;/a&gt;&lt;/li&gt;
            &lt;/ul&gt;
            &lt;button type="button" class="ui-link text-xs"&gt;
                &lt;span&gt;Еще 2&lt;/span&gt;
                &lt;span class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"&gt;
                &lt;/span&gt;
            &lt;/button&gt;
        &lt;/li&gt;
        &lt;li class="space-y-4"&gt;
            &lt;a href="/wardrobe"&gt;Шкафы&lt;/a&gt;
            &lt;ul class="space-y-3"&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Шкафы-купе&lt;/a&gt;&lt;/li&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Распашные шкафы&lt;/a&gt;&lt;/li&gt;
                &lt;li class=""&gt;&lt;a href="#" class="text-xs"&gt;Шкафы угловые&lt;/a&gt;&lt;/li&gt;
            &lt;/ul&gt;
        &lt;/li&gt;
    &lt;/ul&gt;
            </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-nav-category :itemsNavCategory="itemsNavCategory"/&gt;

&lt;script&gt;
import uiNavCategory from "@/components/ui/uiNavCategory.vue";

export default {
  name: 'NavsAndTabs',
  components: {
    'ui-nav-category': uiNavCategory,
  },
  data(){
    return{
      itemsNavCategory: [
        {
          text: 'Фототовары',
          href: '/photography ',
          links: [
            {text: 'Фотокамеры', href: '#'},
            {text: 'Объективы', href: '#'},
            {text: 'Аксессуары', href: '#'},
            {text: 'Световое оборудование', href: '#'},
            {text: 'Штативы', href: '#'},
            {text: 'Селфи палки', href: '#'},
          ],
          isShow: true,
        },
        {
          text: 'Шкафы',
          href: '/wardrobe',
          links: [
            {text: 'Шкафы-купе', href: '#'},
            {text: 'Распашные шкафы', href: '#'},
            {text: 'Шкафы угловые', href: '#'},
          ],
          isShow: true,
        }
      ],
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;ul class="ui-nav-category"&gt;
    &lt;li class="space-y-4" v-for="item in itemsNavCategory" :key="item.text"&gt;
      &lt;a v-if="item.href" :href="item.href">&#123; &#123; item.text &#125; &#125; &lt;/a&gt;
      &lt;ul class="space-y-3"&gt;
        &lt;li :class="{hidden: index &gt; 3 && item.isShow}"
            v-for="(link, index) of item.links"
            :key="link.text"&gt;
          &lt;a class="text-xs" :href="link.href"
             :class="{active:link.active}"&gt;
            &#123; &#123; link.text &#125; &#125;
          &lt;/a&gt;
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;button @click="toggleExpand(item)"
              class="ui-link text-xs"
              type="button"
              v-if="item.links.length &gt; 4"&gt;
        &lt;span v-if="item.isShow"&gt;Еще &#123; &#123; item.links.length - 4 &#125; &#125; &lt;/span&gt;
        &lt;span v-else&gt;Свернуть&lt;/span&gt;
        &lt;span class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]" :class="{'rotate-180':!item.isShow}"&gt;&lt;/span&gt;
      &lt;/button&gt;
    &lt;/li&gt;
  &lt;/ul&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiNavCategory",
  props: {
    itemsNavCategory: {
      type: Array,
      links:{
        type:Array,
      }
    }
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },
  },

}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="Nav dropdown">
        <template>
          <common-item>
            <ui-nav-dropdown :itemsNavDropdown="itemsNavDropdown" />
            <template #html-slot>
              <pre>
    &lt;div class="ui-nav-dropdown"&gt;
        &lt;div class="ui-nav-dropdown-item">&lt;a href="#" class="ui-link">ссылка &lt;/a>&lt;/div>
        &lt;div class="ui-nav-dropdown-item">
            &lt;button type="button" class="ui-link" data-dynamic-toggle="dropdown-1">
                кнопка 1
                &lt;span class="ui-link__ic ml-2 bg-[url('/src/assets/img/ic-arrow-navigate-bottom-24.svg')] -rotate-90">
                &lt;/span>
            &lt;/button>
            &lt;ul class="ui-nav-dropdown__list hidden space-y-3" id="dropdown-1">
                &lt;li>&lt;a href="/1" class="small ui-link"> Primary &lt;/a>&lt;/li>
                &lt;li>&lt;a href="/1" class="small ui-link"> Primary Small &lt;/a>&lt;/li>
            &lt;/ul>
        &lt;/div>
        &lt;div class="ui-nav-dropdown-item">
            &lt;button type="button" class="ui-link " data-dynamic-toggle="dropdown-2"> кнопка 2
                &lt;span class="ui-link__ic ml-2 bg-[url('/src/assets/img/ic-arrow-navigate-bottom-24.svg')] -rotate-90">
                &lt;/span>
            &lt;/button>
            &lt;ul class="ui-nav-dropdown__list hidden space-y-3" id="dropdown-2">
                &lt;li>&lt;a href="/1" class="small ui-link"> Primary &lt;/a>&lt;/li>
                &lt;li>&lt;a href="/1" class="small ui-link"> Primary Small &lt;/a>&lt;/li>
            &lt;/ul>
        &lt;/div>
    &lt;/div>
              </pre>
            </template>
            <template #jsSlot>
              <pre>
        let initDynamicToggle = function () {
            let toggleDynamicButtons = document.querySelectorAll("[data-dynamic-toggle]")

            if (toggleDynamicButtons) {
                for (let i = 0; i  &lt; toggleDynamicButtons.length; i++) {
                    let dynamicToggle = toggleDynamicButtons[i],
                        dynamicToggleDataset = dynamicToggle.dataset.dynamicToggle,
                        toggleBox = document.getElementById(dynamicToggleDataset)
                    toggleDynamicButtons[i].addEventListener('click', function () {
                        dynamicToggle.classList.toggle("active")
                        toggleBox.classList.toggle("hidden")
                    });
                }
            }
        }
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-nav-dropdown :itemsNavDropdown="itemsNavDropdown" /&gt;
&lt;script&gt;
import uiNavDropdown from '@/components/ui/uiNavDropdown.vue'

export default {
  name: 'NavsAndTabs',
  components: {
    'ui-nav-dropdown': uiNavDropdown,
  },
  data() {
    return {
      itemsNavDropdown: [
        {
          isShow: false,
          button: 'ссылка',
          href:'#'
        },
        {
          isShow: false,
          button: 'кнопка 1',
          links: [
            {
              text: 'Primary',
              href: '/1',
            },
            {
              text: 'Primary Small',
              href: '/1',
            },
            {
              text: 'Primary Icon',
              href: '/1',
            },
          ],
        },
        {
          isShow: false,
          button: 'кнопка 2',
          links: [
            {
              text: 'Primary',
              href: '/1',
            },
            {
              text: 'Primary Small',
              href: '/1',
            }
          ],
        },
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-nav-dropdown"&gt;
    &lt;div
        class="ui-nav-dropdown-item"
        v-for="item in itemsNavDropdown"
        :key="item.index"
    &gt;
      &lt;a :href="item.href" class="ui-link" v-if="item.href"
      &gt; &#123; &#123; item.button &#125; &#125;
      &lt;/a&gt;
      &lt;button type="button"
              class="ui-link "
              @click="toggleExpand(item)"
              v-else&gt;
        &#123; &#123; item.button &#125; &#125;
        &lt;span class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')] -rotate-90"&gt;
        &lt;/span&gt;
      &lt;/button&gt;

      &lt;ul class="ui-nav-dropdown__list space-y-3"  v-if="item.isShow"&gt;
        &lt;li v-for="link in item.links"
            :key="link.link"&gt;
          &lt;a class="small ui-link"
             :href="link.href"&gt;
            &#123; &#123; link.text &#125; &#125;
          &lt;/a&gt;
        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiNavDropdown',
  props: {
    itemsNavDropdown: {
      type: Array,
      links: {
        type: Array,
      },
    },
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiNavTab from '@/components/ui/uiNavTab.vue'
import uiNavMenu from '@/components/ui/uiNavMenu.vue'
import uiNavCategory from '@/components/ui/uiNavCategory.vue'
import uiNavDropdown from '@/components/ui/uiNavDropdown.vue'

export default {
  name: 'NavsAndTabs',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-nav-tab': uiNavTab,
    'ui-nav-menu': uiNavMenu,
    'ui-nav-category': uiNavCategory,
    'ui-nav-dropdown': uiNavDropdown,
  },
  data() {
    return {
      itemsNavTab: [
        {
          id: 'tab-1',
          category: 'Покупки',
          title: 'Shopping',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        },
        {
          id: 'tab-2',
          category: 'Видео',
          title: 'Videos',
          desc: 'Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero',
        },
        {
          id: 'tab-3',
          category: 'Изображения',
          title: 'Images',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
      ],
      itemsNavMenu: [
        {
          id: 'tab-menu-1',
          category: 'Профиль',
          title: 'Profile',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        },
        {
          id: 'tab-menu-2',
          category: 'Избранное',
          title: 'Favourites',
          desc: 'Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero',
        },
        {
          id: 'tab-menu-3',
          category: 'Ваши заказы',
          title: 'Your orders',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
        {
          id: 'tab-menu-4',
          category: 'Возвраты',
          title: 'Refunds',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
        {
          id: 'tab-menu-5',
          category: 'Настройки',
          title: 'Settings',
          desc: ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
      ],
      itemsNavCategory: [
        {
          text: 'Фототовары',
          href: '/photography ',
          links: [
            { text: 'Фотокамеры', href: '#' },
            { text: 'Объективы', href: '#' },
            { text: 'Аксессуары', href: '#' },
            { text: 'Световое оборудование', href: '#' },
            { text: 'Штативы', href: '#' },
            { text: 'Селфи палки', href: '#' },
          ],
          isShow: true,
        },
        {
          text: 'Шкафы',
          href: '/wardrobe',
          links: [
            { text: 'Шкафы-купе', href: '#' },
            { text: 'Распашные шкафы', href: '#' },
            { text: 'Шкафы угловые', href: '#' },
          ],
          isShow: true,
        },
      ],
      itemsNavDropdown: [
        {
          isShow: false,
          button: 'ссылка',
          href: '#',
        },
        {
          isShow: false,
          button: 'кнопка 1',
          links: [
            {
              text: 'Primary',
              href: '/1',
            },
            {
              text: 'Primary Small',
              href: '/1',
            },
            {
              text: 'Primary Icon',
              href: '/1',
            },
          ],
        },
        {
          isShow: false,
          button: 'кнопка 2',
          links: [
            {
              text: 'Primary',
              href: '/1',
            },
            {
              text: 'Primary Small',
              href: '/1',
            },
          ],
        },
      ],
    }
  },
}
</script>
