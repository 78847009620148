<template>
  <button
    :class="buttonClass"
    type="button"
    class="ui-btn"
    :disabled="disabled"
  >
    <span v-if="icName" :class="icName" class="ui-btn__ic"></span>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'uiButton',
  props: {
    variant: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      required: true,
      type: String,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    icName: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        'ui-btn-primary': this.variant === 'ui-btn-primary',
        'ui-btn-outline-primary': this.variant === 'ui-btn-outline-primary',
        'ui-btn-sm': this.small === true,
      }
    },
  },
}
</script>
