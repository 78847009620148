<template>
  <div class="ui-card-art" :class="cardClass">
    <a :href="href" class="ui-card-art-img">
      <ui-skeleton-img :alt="alt" :src="img" />
    </a>
    <div class="ui-card-art-body">
      <div class="mb-3 w-full">
        <ui-skeleton :loading="loading" height="28px">
          <a :href="href" class="text-lg">{{ title }}</a>
        </ui-skeleton>
      </div>

      <p class="mb-3">
        <ui-skeleton :loading="loading" height="22px">
          <span>{{ text }}</span>
        </ui-skeleton>
      </p>
      <div class="mt-auto flex w-full justify-between">
        <p class="relative text-xs">
          <ui-skeleton :loading="loading" width="100px" height="17px">
            <span>{{ date }}</span>
          </ui-skeleton>
        </p>
        <p class="text-xs">
          <ui-skeleton :loading="loading" width="100px" height="17px">
            <span>{{ name }}</span>
          </ui-skeleton>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import uiSkeleton from '@/components/composite/uiSkeleton.vue'
import uiImg from '@/components/composite/uiSkeletonImg.vue'
export default {
  name: 'uiCardArt',
  components: {
    'ui-skeleton': uiSkeleton,
    'ui-skeleton-img': uiImg,
  },

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    img: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cardClass() {
      return {
        vertical: this.variant === 'vertical',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ui-card-art-img {
  overflow: hidden;
  .ui-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
