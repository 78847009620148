<template>
  <common-wrapper titleWrapper="Close Buttons">
    <template>
      <common-group titleGroup="Standard">
        <template>
          <common-item condition="Пассивное состояние">
            <template>
              <button
                type="button"
                class="ui-btn-close bg-[url('./img/ic-cross-24.svg')]"
              ></button>
            </template>
            <template #html-slot>
              &lt;button type="button" class="ui-btn-close
              bg-[url('./img/ic-cross-24.svg')]" &gt;&lt;/button&gt;
            </template>
          </common-item>
          <common-item condition="При наведении">
            <template>
              <button
                type="button"
                class="ui-btn-close bg-[url('./img/ic-cross-24.svg')] opacity-80"
              ></button>
            </template>
            <template #html-slot>
              &lt;button type="button" class="ui-btn-close
              bg-[url('./img/ic-cross-24.svg')]" &gt;&lt;/button&gt;
            </template>
          </common-item>
          <common-item condition="Недействительная кнопка">
            <template>
              <button
                disabled
                type="button"
                class="ui-btn-close bg-[url('./img/ic-cross-24.svg')]"
              ></button>
            </template>
            <template #html-slot>
              &lt;button disabled type="button" class="ui-btn-close
              bg-[url('./img/ic-cross-24.svg')]" &gt;&lt;/button&gt;
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'ButtonsClose',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
  },
}
</script>

<style scoped></style>
