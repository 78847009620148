<template>
  <common-wrapper
    title-wrapper="Toasts"
    description-wrapper="Компонент всплывающих уведомлений можно использовать для повышения интерактивности вашего веб-сайта путем отправки уведомлений вашим посетителям."
  >
    <template>
      <common-group>
        <template>
          <common-item>
            <button
              class="ui-btn ui-btn-primary"
              type="button"
              @click="openToasts"
            >
              Открыть toasts
            </button>
            <ui-toasts
              title="Успех"
              text="Товар успешно перемещен."
              :class="{ hidden: hidden }"
              @close="openToasts"
            />

            <template #html-slot>
              <pre>
&lt;button type="button"
        class="ui-btn ui-btn-primary"
        data-static-show="toasts-1"&gt;
  Open toasts
&lt;/button&gt;
&lt;div class="ui-toasts hidden" id="toasts-1"&gt;
    &lt;button type="button" class="ui-btn-close bg-[url('/src/assets/img/ic-cross-24.svg')]" data-close=""&gt;&lt;/button&gt;
    &lt;p class="mb-4 w-full text-lg font-semibold"&gt;toasts&lt;/p&gt;
    &lt;p class="mb-0 w-full">text&lt;/p&gt;
&lt;/div&gt;
                </pre
              >
            </template>
            <template #jsSlot>
              <pre>
        let initStaticShown = function () {
            let showStaticButtons = document.querySelectorAll("[data-static-show]"),
                closesStaticButtons = document.querySelectorAll("[data-close]");

            if (showStaticButtons) {
                showStaticButtons.forEach(button => {
                    let staticShow = button.dataset.staticShow
                    let staticBox = document.getElementById(staticShow)

                    // открытие
                    button.addEventListener("click", function () {
                        staticBox.classList.toggle("hidden")
                    });

                    // закрытие атрибутом data-close
                    let closeStatic = function (close) {
                        close.addEventListener("click", () => {
                            staticBox.classList.add("hidden")
                        });
                    }
                    closesStaticButtons.forEach(close => {
                        closeStatic(close)
                    });
                });
            }
        }
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;button
    class="ui-btn ui-btn-primary"
    type="button"
    @click="openToasts"
&gt;
    Открыть toasts
&lt;/button&gt;
&lt;ui-toasts
    title="Успех"
    text="Товар успешно перемещен."
    :class="{ hidden: hidden }"
    @close="openToasts"
/&gt;

&lt;script&gt;
import uiToasts from '@/components/ui/uiToasts.vue'

export default {
  name: 'ToastsElement',
  components: {
    'ui-toasts': uiToasts,
  },
  data() {
    return {
      hidden: true,
    }
  },
  methods: {
    openToasts() {
      this.hidden = !this.hidden
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-toasts"&gt;
    &lt;button
        @click="close"
        type="button"
        class="ui-btn-close bg-[url('./img/ic-cross-24.svg')]"
    &gt;&lt;/button&gt;
    &lt;p class="mb-4 w-full text-lg font-semibold"&gt;&#123; &#123; title &#125; &#125;&lt;/p&gt;
    &lt;p class="mb-0 w-full"&gt;&#123; &#123; text &#125; &#125;&lt;/p&gt;
    &lt;slot name="toasts-slot"&gt;&lt;/slot>&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiToasts',
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiToasts from '@/components/ui/uiToasts.vue'

export default {
  name: 'ToastsElement',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-toasts': uiToasts,
  },
  data() {
    return {
      hidden: true,
    }
  },
  methods: {
    openToasts() {
      this.hidden = !this.hidden
    },
  },
}
</script>
