<template>
  <common-wrapper title-wrapper="Search Fields">
    <template>
      <common-group>
        <template>
          <common-item condition="Пустое поле">
            <ui-input-search
              id="search"
              placeholder="Введите запрос"
              :sectionItems="sections"
              :linkItems="links"
            />
            <template #html-slot>
              <pre>
&lt;div class="relative block w-full"&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
    &lt;div class="ui-input__field" &gt;
    &lt;input class="ui-input__field--input" type="text" id="name" /&gt;
    &lt;button
        type="button"
        class="ui-input__field--btn bg-[url('./img/ic-search-24.svg')] "
    &gt;
    &lt;/button&gt;

    &lt;button
        type="button"
        class="ui-btn ui-btn-primary ui-btn-sm absolute right-1 top-[50%] translate-y-[-50%] hide"
    &gt;
      &lt;span
          class="ui-btn__ic bg-[url('./img/ic-search-24.svg')]"
      >&lt;/span&gt;
      Все результаты
    &lt;/button&gt;
  &lt;/div&gt;
&lt;div class="ui-input__dropdown"&gt;
  &lt;div class="mb-4 w-full"&gt;
    &lt;p class="ui-input__dropdown--title"&gt;
      Открыть раздел:
    &lt;/p&gt;
    &lt;div class="flex w-full flex-wrap items-center"&gt;
      &lt;a
          href="#"
          class="ui-badge ui-badge-primary ui-badge-lg mr-4 mb-2"
      &gt;
        Хлебцы и сухари
      &lt;/a&gt;
    &lt;/div&gt;
  &lt;/div&gt;
  &lt;div class="w-full"&gt;
    &lt;a href="#" class="ui-input__dropdown--link"&gt;
    &lt;span
        class="ui-input__dropdown--link-img"
    &gt;
      &lt;img
          class="h-full w-full rounded-md"
          src="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
          alt="mountain"
      /&gt;
    &lt;/span&gt;
      &lt;span
          class="m-0 text-base"
      &gt;Хлеб бородинский&lt;/span
      &gt;
    &lt;/a&gt;
  &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-input-search
    id="search"
    placeholder="Введите запрос"
    :sectionItems="sections"
    :linkItems="links"
/&gt;

&lt;script&gt;
import uiInputSearch from '@/components/ui/uiInputSearch.vue'

export default {
  name: 'FieldsInputSearch',
  components: {
    'ui-input-search': uiInputSearch,
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          href: '#',
          text: 'Хлеб бородинский',
        },
        {
          id: 2,
          href: '#',
          text: 'Хлеб',
        },
      ],
      links: [
        {
          id: 1,
          href: '#',
          text: 'Хлебобулочные изделия',
          src: 'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          alt: 'alt',
        },
        {
          id: 2,
          href: '#',
          text: 'Хлебобулочные изделия',
        },
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" :for="id"&gt; &#123; &#123;label&#125; &#125; &lt;/label &gt;
    &lt;div class="ui-input__field" &gt;
      &lt;input class="ui-input__field--input"
             :class="inputClass"
             :type="type"
             :placeholder="placeholder"
             :value="value"
             :disabled="disabled"
             :id="id" /&gt;
      &lt;button
          type="button"
          class="ui-input__field--btn bg-[url('./img/ic-search-24.svg')]"
      &gt;
      &lt;/button&gt;
      &lt;button
          v-if="false"
          type="button"
          class="ui-btn ui-btn-primary ui-btn-sm absolute right-1 top-[50%] translate-y-[-50%]"
      &gt;
                    &lt;span
                        class="ui-btn__ic bg-[url('./img/ic-search-24.svg')]"
                    &gt;&lt;/span&gt;
        Все результаты
      &lt;/button&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;&#123; &#123;error &#125; &#125; &lt;/span&gt;
    &lt;div class="ui-input__dropdown"&gt;
      &lt;div class="mb-4 w-full"&gt;
        &lt;p class="ui-input__dropdown--title" v-if="sectionItems"&gt;Открыть раздел:&lt;/p&gt;
        &lt;div class="flex w-full flex-wrap items-center"&gt;
          &lt;a class="ui-badge ui-badge-primary ui-badge-lg mr-4 mb-2"
             v-for="sectionItem in sectionItems"
             :key="sectionItem.id"
             :href="sectionItem.href"
          &gt;
            &#123; &#123;sectionItem.text&#125; &#125;
          &lt;/a&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="w-full"&gt;
        &lt;a class="ui-input__dropdown--link"
           v-for="linkItem in linkItems"
           :key="linkItem.id"
           :href="linkItem.href"&gt;
          &lt;span class="ui-input__dropdown--link-img"&gt;
            &lt;img v-if="linkItem.src"
                 class="h-full w-full rounded-md"
                 :src="linkItem.src"
                 :alt="linkItem.alt"
            /&gt;
          &lt;/span&gt;
          &lt;span class="m-0 text-base"&gt; &#123; &#123;linkItem.text&#125; &#125; &lt;/span&gt;
        &lt;/a&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiInputSearch",
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    sectionItems: {
      type: Array
    },
    linkItems: {
      type: Array
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[var(--e)]': this.variant === 'error',
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Введен запрос, открывается список">
            <div class="ui-input__dropdown-wrap-show h-[420px]">
              <ui-input-search
                id="search"
                placeholder="Введите запрос"
                :sectionItems="sections"
                :linkItems="links"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="relative block w-full"&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" for="name" &gt;Ваше имя&lt;/label &gt;
    &lt;div class="ui-input__field" &gt;
    &lt;input class="ui-input__field--input" type="text" id="name" /&gt;
    &lt;button
        type="button"
        class="ui-input__field--btn bg-[url('./img/ic-search-24.svg')] "
    &gt;
    &lt;/button&gt;

    &lt;button
        type="button"
        class="ui-btn ui-btn-primary ui-btn-sm absolute right-1 top-[50%] translate-y-[-50%] hide"
    &gt;
      &lt;span
          class="ui-btn__ic bg-[url('./img/ic-search-24.svg')]"
      >&lt;/span&gt;
      Все результаты
    &lt;/button&gt;
  &lt;/div&gt;
&lt;div class="ui-input__dropdown"&gt;
  &lt;div class="mb-4 w-full"&gt;
    &lt;p class="ui-input__dropdown--title"&gt;
      Открыть раздел:
    &lt;/p&gt;
    &lt;div class="flex w-full flex-wrap items-center"&gt;
      &lt;a
          href="#"
          class="ui-badge ui-badge-primary ui-badge-lg mr-4 mb-2"
      &gt;
        Хлебцы и сухари
      &lt;/a&gt;
    &lt;/div&gt;
  &lt;/div&gt;
  &lt;div class="w-full"&gt;
    &lt;a href="#" class="ui-input__dropdown--link"&gt;
    &lt;span
        class="ui-input__dropdown--link-img"
    &gt;
      &lt;img
          class="h-full w-full rounded-md"
          src="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
          alt="mountain"
      /&gt;
    &lt;/span&gt;
      &lt;span
          class="m-0 text-base"
      &gt;Хлеб бородинский&lt;/span
      &gt;
    &lt;/a&gt;
  &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiInputSearch from '@/components/ui/uiInputSearch.vue'

export default {
  name: 'FieldsInputSearch',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-input-search': uiInputSearch,
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          href: '#',
          text: 'Хлеб бородинский',
        },
        {
          id: 2,
          href: '#',
          text: 'Хлеб',
        },
      ],
      links: [
        {
          id: 1,
          href: '#',
          text: 'Хлебобулочные изделия',
          src: 'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          alt: 'alt',
        },
        {
          id: 2,
          href: '#',
          text: 'Хлебобулочные изделия',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.ui-input__dropdown-wrap-show .ui-input__dropdown {
  display: block;
}
</style>
