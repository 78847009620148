<template>
  <div class="ui-input">
    <label class="ui-input__label" :for="id">
      {{ label }}
    </label>
    <div class="ui-input__group" :class="inputClass">
      <div class="ui-input__group--text" :class="inputClass">
        {{ fixText }}
      </div>
      <input
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        class="ui-input__group--input"
      />
    </div>
    <span v-if="error" class="ui-text-invalid block pt-3">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'uiInputFix',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    fixText: {
      type: String,
      default: null,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
}
</script>
