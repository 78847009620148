<template>
  <div class="grid grid-cols-12 gap-4">
    <ul class="ui-nav-menu col-span-3">
      <li v-for="item in itemsNavMenu" :key="item.id">
        <button
          class="ui-nav-menu__btn"
          :class="{ active: isShowNavMenu === item.id }"
          @click.prevent="toggleShow(item)"
        >
          {{ item.category }}
        </button>
      </li>
    </ul>
    <div class="ui-tab-container-wrapper col-span-9">
      <div
        class="ui-tab-container"
        v-for="item in itemsNavMenu"
        :id="item.id"
        :key="item.id"
        :class="{ hidden: isShowNavMenu !== item.id }"
      >
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiNavMenu',
  data() {
    return {
      isShowNavMenu: 'tab-menu-1',
    }
  },
  props: {
    itemsNavMenu: {
      type: Array,
    },
  },
  methods: {
    toggleShow(item) {
      this.isShowNavMenu = item.id
    },
  },
}
</script>
