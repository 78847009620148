<template>
  <common-wrapper titleWrapper="Checkboxes">
    <template>
      <common-group titleGroup="Classic">
        <template>
          <common-item condition="Не выбрано">
            <ui-checkbox
              :checked="false"
              :disabled="false"
              variant="ui-checkbox-primary"
              id="checkbox-no-checked"
              text="Согласиться с правилами"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox ui-checkbox-primary" for="checkbox1"&gt;
  &lt;input id="checkbox1" type="checkbox" class="ui-checkbox__input"/&gt;
  Согласиться с правилами
&lt;/label&gt;
</pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-checkbox
    :checked="false"
    :disabled="false"
    variant="ui-checkbox-primary"
    id="checkbox"
    text="Согласиться с правилами"
/&gt;

&lt;script&gt;
import uiCheckbox from '@/components/ui/uiCheckbox.vue'

export default {
  name: 'CheckboxAndRadioCheckboxes',
  components: {
    'ui-checkbox': uiCheckbox,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;label class="ui-checkbox" :class="checkboxClass" :for="id"&gt;
    &lt;input
        :id="id"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        class="ui-checkbox__input"
    /&gt;
&#123; &#123; text &#125; &#125;
  &lt;/label&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    name: 'uiCheckbox',
    props: {
      id: {
        type: [String, Number],
        default: null,
        required: true,
      },
      text: {
        required: true,
        type: String,
      },
      checked: {
        type: Boolean,
        default: null,
        required: false,
      },
      disabled: {
        required: false,
        type: Boolean,
        default: null,
      },
      variant: {
        type: String,
        required: false,
        default: null,
     },
    },
    computed: {
      checkboxClass() {
        return {
          'ui-checkbox-primary': this.variant === 'ui-checkbox-primary',
        }
      },
    },
  }
&lt;/script&gt;
                </pre
              >
            </template>
          </common-item>
          <common-item condition="Выбрано">
            <ui-checkbox
              :checked="true"
              id="checkbox-checked"
              variant="ui-checkbox-primary"
              text="Согласиться с правилами"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox ui-checkbox-primary" for="checkbox1"&gt;
  &lt;input id="checkbox1" type="checkbox" class="ui-checkbox__input"/&gt;
  Согласиться с правилами
&lt;/label&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="disabled">
            <ui-checkbox
              :checked="false"
              disabled
              id="checkbox-no-checked"
              variant="ui-checkbox-primary"
              text="Согласиться с правилами"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox ui-checkbox-primary" for="checkbox1"&gt;
  &lt;input disabled id="checkbox1" type="checkbox" class="ui-checkbox__input"/&gt;
  Согласиться с правилами
&lt;/label&gt;
</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Button"
        text-group="Для input radio и input checkbox используй 'ui-checkbox-btn', меняя только type"
      >
        <template>
          <common-item condition="Не выбрано">
            <ui-checkbox-button
              :checked="false"
              :disabled="false"
              id="checkbox-button-no-checked"
              variant="ui-checkbox-primary"
              text="Хлебобулочные изделия"
              type="checkbox"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input id="id" type="checkbox" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-checkbox-button
    :checked="false"
    :disabled="false"
    id="checkbox-button"
     variant="ui-checkbox-primary"
    text="Хлебобулочные изделия"
    type="checkbox"
/&gt;

&lt;script&gt;
  import uiCheckboxButton from '@/components/ui/uiCheckboxButton.vue'

  export default {
    name: 'CheckboxAndRadioCheckboxes',
    components: {
      'ui-checkbox-button': uiCheckboxButton,
    },
  }
&lt;/script&gt;
</pre
              >
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;label :for="id" :class="checkboxClass" class="ui-checkbox-btn"&gt;
    &lt;input
        :id="id"
        :type="type"
        :checked="checked"
        :disabled="disabled"
        :name="name"
        class="ui-checkbox-btn__input"
    />
    &lt;span class="ui-checkbox-btn__text"&gt;&#123; &#123;text&#125; &#125;&lt;/span&gt;
  &lt;/label&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    name: 'uiCheckboxButton',
    props: {
      id: {
        type: [String, Number],
        default: null,
        required: true,
      },
      type: {
        type: String,
        default: 'checkbox',
        required: true,
      },
      text: {
        required: true,
        type: String,
      },
      checked: {
        type: Boolean,
        default: null,
        required: false,
      },
      disabled: {
        required: false,
        type: Boolean,
        default: null,
      },
      name: {
        required: false,
        type: String,
      },
      variant: {
       type: String,
       required: false,
       default: null,
     },
    },
    computed: {
      checkboxClass() {
        return {
          'ui-checkbox-primary': this.variant === 'ui-checkbox-primary',
        }
      },
    },
  }
&lt;/script&gt;
                </pre
              >
            </template>
          </common-item>
          <common-item condition="Выбрано">
            <template>
              <ui-checkbox-button
                :checked="true"
                id="checkbox-button-checked2"
                variant="ui-checkbox-primary"
                text="Хлебобулочные изделия"
                type="checkbox"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input id="id" type="checkbox" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="Disabled">
            <ui-checkbox-button
              :checked="false"
              id="checkbox-button-no-checked3"
              variant="ui-checkbox-primary"
              text="Хлебобулочные изделия"
              type="checkbox"
              disabled
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input disabled id="id" type="checkbox" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiCheckbox from '@/components/ui/uiCheckbox.vue'
import uiCheckboxButton from '@/components/ui/uiCheckboxButton.vue'

export default {
  name: 'CheckboxAndRadioCheckboxes',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-checkbox': uiCheckbox,
    'ui-checkbox-button': uiCheckboxButton,
  },
}
</script>
