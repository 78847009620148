<template>
  <nav class="ui-breadcrumb">
    <ol class="ui-breadcrumb-list">
      <li
        class="ui-breadcrumb-item"
        v-for="(item, index) in items"
        :key="item.id"
      >
        <a
          v-if="index + 1 < items.length"
          :href="item.url"
          class="ui-breadcrumb__text"
        >
          {{ item.name }}
        </a>
        <span class="ui-breadcrumb__text" v-else>
          {{ item.name }}
        </span>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: 'uiBreadcrumb',
  props: {
    items: {
      type: Array,
    },
  },
}
</script>
