<template>
  <div class="ui-badge" :class="badgeClass">
    {{ text }}
    <button v-if="close" type="button" class="ui-badge__close"></button>
  </div>
</template>

<script>
export default {
  name: 'uiBadge',
  props: {
    close: {
      type: Boolean,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: true,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    large: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    badgeClass() {
      return {
        'ui-badge-sm': this.small === true,
        'ui-badge-lg': this.large === true,
        'ui-badge-primary': this.variant === 'ui-badge-primary',
        'ui-badge-secondary': this.variant === 'ui-badge-secondary',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.router-link-active {
  .ui-badge {
    margin-bottom: 0;
  }
  &:hover {
    .ui-badge-primary {
      background-color: var(--ph);
    }
    .ui-badge-secondary {
      background-color: var(--sh);
    }
  }
  &:active {
    .ui-badge-primary {
      background-color: var(--pf);
    }
    .ui-badge-secondary {
      background-color: var(--sf);
    }
  }
}
</style>
