<template>
  <label class="ui-switch" :for="id" :class="switchClass">
    <input
      :id="id"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      class="ui-switch__input"
    />
    <span class="ui-switch__text">{{ text }}</span>
  </label>
</template>

<script>
export default {
  name: 'uiSwitch',
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    text: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    switchClass() {
      return {
        'ui-switch-primary': this.variant === 'ui-switch-primary',
      }
    },
  },
}
</script>

<style scoped></style>
