<template>
  <div class="w-full">
    <p class="mb-6 text-lg font-semibold text-[var(--b5)]" v-if="titleGroup">
      {{ titleGroup }}
    </p>
    <p class="mb-6" v-if="textGroup">{{ textGroup }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CommonGroup',
  props: {
    titleGroup: {
      required: false,
      type: String,
      default: null,
    },
    textGroup: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped></style>
