<template>
  <div class="ui-avatar">
    <img class="ui-avatar__img" :src="src" :alt="alt" v-if="src" />
  </div>
</template>

<script>
export default {
  name: 'uiAvatar',
  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>
