<template>
  <common-wrapper title-wrapper="Rating scale">
    <template>
      <common-group>
        <template>
          <common-item condition="Низкий балл">
            <ui-rating-scale length="10%" />

            <template #html-slot>
              <pre>
&lt;div class="flex items-center space-x-2"&gt;
    &lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
      &lt;object class="h-2 rounded-md bg-[var(--s)]" width="10%" /&gt;
    &lt;/div&gt;
    &lt;div class="min-w-12 block w-12 text-base"&gt;
       10%
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-rating-scale length="10%" /&gt;

&lt;script&gt;
import uiRatingScale from '@/components/ui/uiRatingScale.vue'

export default {
  name: 'RatingScale',
  components: {
    'ui-rating-scale': uiRatingScale,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="flex items-center space-x-2"&gt;
    &lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
      &lt;object class="h-2 rounded-md bg-[var(--s)]" :width="length" /&gt;
    &lt;/div&gt;
    &lt;div class="min-w-12 block w-12 text-base"
         v-if="length"&gt;
        &#123; &#123;length&#125; &#125;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    name: "uiRatingScale",
    props: {
      length: {
        required: true,
        type: String,
      },
    },
  }
&lt;/script&gt;
</pre
              >
            </template>
          </common-item>
          <common-item condition="Средний балл">
            <ui-rating-scale length="50%" />
            <template #html-slot>
              <pre>
&lt;div class="flex items-center space-x-2"&gt;
    &lt;div class="h-2 w-full rounded-md bg-[var(--b4)]"&gt;
      &lt;object class="h-2 rounded-md bg-[var(--s)]" width="50%" /&gt;
    &lt;/div&gt;
    &lt;div class="min-w-12 block w-12 text-base"&gt;
       50%
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiRatingScale from '@/components/ui/uiRatingScale.vue'

export default {
  name: 'RatingScale',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-rating-scale': uiRatingScale,
  },
}
</script>
