var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-input pb-1"},[_c('label',{staticClass:"ui-input__label m-0 pb-3",attrs:{"for":"default-range"}},[_vm._v(_vm._s(_vm.label))]),_c('Slider',{attrs:{"disabled":_vm.disabled,"classes":{
      target: 'relative w-full my-4',
      horizontal: 'slider-horizontal h-[2px]',
      base: 'w-full h-full relative z-1',
      connects:
        'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
      connect:
        'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
      origin:
        'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
      handle:
        'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
      tooltip:
        'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
      tooltipTop: 'top-[-8px]',
    }},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }