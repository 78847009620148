<template>
  <common-wrapper
    title-wrapper="Upload a file"
    description-wrapper="Компонент файлового ввода можно использовать для загрузки одного или нескольких файлов из хранилища устройства пользователя, доступных в различных размерах, стилях и вариантах"
  >
    <template>
      <common-group>
        <template>
          <common-item condition="Не загружно">
            <ui-input-upload
              id="file"
              label="Загрузите изображение"
              :multiple="true"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="file-input"&gt;Загрузите изображение&lt;/label&gt;
  &lt;div class="ui-input__upload"&gt;
      &lt;div class="relative mr-0 w-full rounded-lg md:mr-4 md:w-auto"&gt;
        &lt;input
            class="peer absolute top-0 left-0 z-1 h-full w-full opacity-0"
            id="file-input"
            type="file"
            ref="file"
            multiple
        /&gt;
        &lt;div class="ui-btn ui-btn-primary ui-btn-sm" &gt;
          &lt;span class="ui-btn__ic bg-[url('./img/ic-download-24.svg')]"&gt;&lt;/span&gt;
          Загрузить файл
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-wrap items-center"&gt;
        &lt;div class="mr-4 my-2 flex items-center space-x-4"&gt;
          &lt;p class="mb-0 text-base"&gt;file.name&lt;/p&gt;
          &lt;button
              type="button"
              class="ui-btn-close h-4 w-4 min-w-0 bg-[url('./img/ic-cross-24.svg')]"
          &gt;&lt;/button&gt;
        &lt;/div&gt;
      &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-input-upload id="file" label="Загрузите изображение" :multiple="true" /&gt;

&lt;script&gt;
import uiInputUpload from '@/components/ui/uiInputUpload.vue'

export default {
  name: 'FieldsButton',
  components: {
    'ui-input-upload': uiInputUpload,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" :for="id"&gt; &#123; &#123; label &#125; &#125; &lt;/label&gt;
    &lt;div class="ui-input__upload" :class="inputClass"&gt;
      &lt;div class="relative mr-0 w-full rounded-lg md:mr-4 md:w-auto"&gt;
        &lt;input
            class="peer absolute top-0 left-0 z-1 h-full w-full opacity-0"
            type="file"
            ref="file"
            :multiple="multiple"
            @change="onChange"
            :disabled="disabled"
            :id="id"
        /&gt;
        &lt;div class="ui-btn ui-btn-primary ui-btn-sm"&gt;
          &lt;span
              class="ui-btn__ic bg-[url('./img/ic-download-24.svg')]"
          &gt;&lt;/span&gt;
          Загрузить файл
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-wrap items-center"
           v-if="this.fileList.length"
           v-cloak
      &gt;
        &lt;div
            class="my-2 mr-4 flex items-center space-x-4"
            v-for="file in fileList"
            :key="file.index"
        &gt;
          &lt;p class="mb-0 text-base"&gt; &#123; &#123; file.name &#125; &#125; &lt;/p&gt;
          &lt;button
              type="button"
              class="ui-btn-close h-4 w-4 min-w-0 bg-[url('./img/ic-cross-24.svg')]"
              @click="remove(fileList.indexOf(file))"
          &gt;&lt;/button&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt; &#123; &#123; error &#125; &#125; &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiInputUpload',
  data() {
    return {
      fileList: [],
    }
  },
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
  methods: {
    onChange() {
      this.fileList = [...this.$refs.file.files];
    },
    remove(i) {
      this.fileList.splice(i, 1);
    },
  },
}

&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <div class="ui-input">
              <ui-input-upload
                id="id2"
                label="Загрузите изображение"
                :disabled="true"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="file-input"&gt;Загрузите изображение&lt;/label&gt;
  &lt;div class="ui-input__upload border-[#FF5B5B]"&gt;
      &lt;div class="relative mr-0 w-full rounded-lg md:mr-4 md:w-auto"&gt;
        &lt;input
            class="peer absolute top-0 left-0 z-1 h-full w-full opacity-0"
            id="file-input"
            type="file"
            ref="file"
            multiple
            disabled
        /&gt;
        &lt;div class="ui-btn ui-btn-primary ui-btn-sm" &gt;
          &lt;span class="ui-btn__ic bg-[url('./img/ic-download-24.svg')]"&gt;&lt;/span&gt;
          Загрузить файл
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-wrap items-center"&gt;
        &lt;div class="mr-4 my-2 flex items-center space-x-4"&gt;
          &lt;p class="mb-0 text-base"&gt;file.name&lt;/p&gt;
          &lt;button
              type="button"
              class="ui-btn-close h-4 w-4 min-w-0 bg-[url('./img/ic-cross-24.svg')]"
          &gt;&lt;/button&gt;
        &lt;/div&gt;
      &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-input-upload
              id="id3"
              label="Загрузите изображение"
              error="Обязательное поле"
              variant="error"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="file-input"&gt;Загрузите изображение&lt;/label&gt;
  &lt;div class="ui-input__upload"&gt;
      &lt;div class="relative mr-0 w-full rounded-lg md:mr-4 md:w-auto"&gt;
        &lt;input
            class="peer absolute top-0 left-0 z-1 h-full w-full opacity-0"
            id="file-input"
            type="file"
            ref="file"
            multiple
        /&gt;
        &lt;div class="ui-btn ui-btn-primary ui-btn-sm" &gt;
          &lt;span class="ui-btn__ic bg-[url('./img/ic-download-24.svg')]"&gt;&lt;/span&gt;
          Загрузить файл
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-wrap items-center"&gt;
        &lt;div class="mr-4 my-2 flex items-center space-x-4"&gt;
          &lt;p class="mb-0 text-base"&gt;file.name&lt;/p&gt;
          &lt;button
              type="button"
              class="ui-btn-close h-4 w-4 min-w-0 bg-[url('./img/ic-cross-24.svg')]"
          &gt;&lt;/button&gt;
        &lt;/div&gt;
      &lt;/div&gt;
  &lt;/div&gt;
  &lt;span class="ui-text-invalid block pt-3"&gt;error&lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiInputUpload from '@/components/ui/uiInputUpload.vue'

export default {
  name: 'FieldsButton',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-input-upload': uiInputUpload,
  },
}
</script>
