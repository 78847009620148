<template>
  <div class="ui-input">
    <label class="ui-input__label">
      {{ label }}
    </label>
    <vue-tel-input
      :inputOptions="options"
      :validCharactersOnly="true"
      v-model="phone"
      styleClasses="leading-none w-full rounded-md border border-[var(--b4)] bg-[transparent] p-4 text-base disabled:border-[var(--b4)] disabled:bg-[var(--b3)]"
      :disabled="disabled"
    />
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>

<script>
import 'vue-tel-input/dist/vue-tel-input.css'
export default {
  name: 'uiPhone',

  data() {
    return {
      phone: null,
      options: {
        placeholder: '',
        maxlength: 25,
        showDialCode: true,
      },
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
  },
}
</script>
<style>
.vti__dropdown {
  color: #000;
  background-color: transparent !important;
}
</style>
