<template>
  <common-wrapper title-wrapper="Fix Text Fields">
    <template>
      <common-group>
        <template>
          <common-item condition="Заполненное поле">
            <ui-input-fix
              fixText="https://example.com/users/"
              label="Ваш URL-адрес"
              value="ivan1102"
              type="text"
              id="id1"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="url"&gt; Ваш URL-адрес&lt;/label&gt;
  &lt;div class="ui-input__group"&gt;
    &lt;div class="ui-input__group--text"&gt;
      https://example.com/users/
    &lt;/div&gt;
    &lt;input
        class="ui-input__group--input"
        type="text"
        id="url"
        value="ivan1102"
    /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-input-fix
    fixText="https://example.com/users/"
    label="Ваш URL-адрес"
    placeholder="Ваш URL-адрес"
    value="ivan1102"
    type="text"
    :disabled="false"
    :error="true"
    variant="error"
    id="id"
/&gt;

&lt;script&gt;
import uiInputFix from '@/components/ui/uiInputFix.vue'

export default {
  name: 'FieldsInputFixText',
  components: {
    'ui-input-fix': uiInputFix,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" :for="id"&gt;
      &#123; &#123; label &#125; &#125;
    &lt;/label&gt;
    &lt;div class="ui-input__group"&gt;
      &lt;div class="ui-input__group--text"&gt;
        &#123; &#123; fixText &#125; &#125;
      &lt;/div&gt;
      &lt;input
          :id="id"
          :class="inputClass"
          :disabled="disabled"
          :placeholder="placeholder"
          :type="type"
          :value="value"
          class="ui-input__group--input"
      /&gt;
    &lt;/div&gt;
    &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;
      &#123; &#123; error &#125; &#125;
    &lt;/span&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "uiInputFix",
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    type: {
      type: [String],
      default: 'text',
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    fixText: {
      type: String,
      default: null,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Пустое поле">
            <ui-input-fix
              fixText="https://example.com/users/"
              label="Ваш URL-адрес"
              type="text"
              id="id2"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="url"&gt; Ваш URL-адрес&lt;/label&gt;
  &lt;div class="ui-input__group"&gt;
    &lt;div class="ui-input__group--text"&gt;
      https://example.com/users/
    &lt;/div&gt;
    &lt;input
        class="ui-input__group--input"
        type="text"
        id="url"
    /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Недействительное поле">
            <ui-input-fix
              fixText="https://example.com/users/"
              label="Ваш URL-адрес"
              type="text"
              :disabled="true"
              id="id3"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="url"&gt; Ваш URL-адрес&lt;/label&gt;
  &lt;div class="ui-input__group" :class="inputClass"&gt;
    &lt;div class="ui-input__group--text" :class="inputClass"&gt;
      https://example.com/users/
    &lt;/div&gt;
    &lt;input
        class="ui-input__group--input"
        type="text"
        id="url"
        disabled
    /&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Ошибка">
            <ui-input-fix
              fixText="https://example.com/users/"
              label="Ваш URL-адрес"
              type="text"
              error="error"
              variant="error"
              id="id4"
            />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
  &lt;label class="ui-input__label" for="url"&gt; Ваш URL-адрес&lt;/label&gt;
  &lt;div class="ui-input__group border-[#FF5B5B]"&gt;
    &lt;div class="ui-input__group--text border-[#FF5B5B]"&gt;
      https://example.com/users/
    &lt;/div&gt;
    &lt;input
        class="ui-input__group--input"
        type="text"
        id="url"
    /&gt;
  &lt;/div&gt;
  &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;
      &#123; &#123; error &#125; &#125;
  &lt;/span&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiInputFix from '@/components/ui/uiInputFix.vue'

export default {
  name: 'FieldsInputFixText',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-input-fix': uiInputFix,
  },
}
</script>
