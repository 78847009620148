<template>
  <common-wrapper
    titleWrapper="Modals"
    description-wrapper="Используйте модальный компонент, чтобы показывать интерактивные диалоги и уведомления пользователям вашего веб-сайта."
  >
    <template>
      <common-group>
        <template>
          <common-item title-item="Модальное окно">
            <button
              class="ui-btn ui-btn-primary"
              type="button"
              @click="showModal"
            >
              Open modal
            </button>

            <ui-modal
              v-show="isModalVisible"
              @close="closeModal"
              id="defaultModal"
              classContainer="max-w-[710px]"
            >
              <template #modal-slot>
                <button
                  @click="closeModal"
                  type="button"
                  class="ui-btn-close absolute top-3 right-3 bg-[url('./img/ic-cross-24.svg')]"
                ></button>
                <h3 class="mb-5 w-full pr-8 text-2xl font-bold">Заголовок</h3>
                <p class="mb-5">
                  Значимость этих проблем настолько очевидна, что сложившаяся
                  структура организации прекрасно подходит для реализации
                  экспериментов, поражающих по своей масштабности и
                  грандиозности.
                </p>
                <form action="#">
                  <div class="ui-input mb-5">
                    <label class="ui-input__label" for="name">Ваше имя</label>
                    <div class="ui-input__field">
                      <input
                        class="ui-input__field--input"
                        type="text"
                        id="name"
                      />
                    </div>
                  </div>
                  <input
                    class="ui-btn ui-btn-primary"
                    type="submit"
                    value="Сохранить"
                  />
                </form>
              </template>
            </ui-modal>

            <template #html-slot>
              <pre>
   &lt;button type="button"
           class="ui-btn ui-btn-primary"
           data-dynamic-show="modal-1"
   &gt;Open Modal 1
   &lt;/button&gt;
   &lt;div id="modal-1"
        aria-hidden="true"
        class="ui-modal hidden"
        tabindex="-1"&gt;
        &lt;div class="ui-modal-container max-w-[710px]"&gt;
            &lt;div class="ui-modal-box md:py-8 md:px-10"&gt;
                &lt;button type="button"
                        class="ui-btn-close absolute top-3 right-3 bg-[url('/src/assets/img/ic-cross-24.svg')]"
                        data-close
                &gt;&lt;/button&gt;
                &lt;h3 class="mb-5 w-full pr-8 text-2xl font-bold"&gt;Заголовок&lt;/h3&gt;
                &lt;p class="mb-5"&gt;
                    Значимость этих проблем настолько очевидна
                &lt;/p&gt;
            &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="ui-modal-backdrop" data-close &gt;&lt;/div&gt;
    &lt;/div&gt;
              </pre>
            </template>
            <template #jsSlot>
              <pre>
          let initDynamicShown = function () {
            let showDynamicButtons = document.querySelectorAll("[data-dynamic-show]"),
                closesDynamicButtons = document.querySelectorAll("[data-close]");

            if (showDynamicButtons) {
                showDynamicButtons.forEach(button => {
                    let dynamicShow = button.dataset.dynamicShow
                    let dynamicBox = document.getElementById(dynamicShow)

                    // открытие
                    button.addEventListener("click", function () {
                        dynamicBox.classList.toggle("hidden")
                    });

                    // закрытие клик вне элемента
                    document.addEventListener('click', (e) => {
                        const withinBoundaries = e.composedPath().includes(dynamicBox);
                        const withinBoundaries2 = e.composedPath().includes(button);

                        !withinBoundaries && !withinBoundaries2 ? dynamicBox.classList.add("hidden") : null

                    });

                    // закрытие атрибутом data-close
                    let closeDynamic = function (close) {
                        close.addEventListener("click", () => {
                            dynamicBox.classList.add("hidden")
                        });
                    }
                    closesDynamicButtons.forEach(close => {
                        closeDynamic(close)
                    });
                });
            }
        }
            </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
 &lt;button
     class="ui-btn ui-btn-primary"
     type="button"
     @click="showModal"
&gt;

&lt;ui-modal
    v-show="isModalVisible"
    @close="closeModal"
    id="defaultModal"
    classContainer="max-w-[710px]"
&gt;
  &lt;template #modal-slot&gt;
    &lt;button
        @click="closeModal"
        type="button"
        class="ui-btn-close absolute top-3 right-3 bg-[url('./img/ic-cross-24.svg')]"
    &gt;&lt;/button&gt;
    &lt;h3 class="mb-5 w-full pr-8 text-2xl font-bold"&gt;Заголовок&lt;/h3&gt;
    &lt;p class="mb-5"&gt;
      Значимость этих проблем настолько очевидна, что сложившаяся
      структура организации прекрасно подходит для реализации
      экспериментов, поражающих по своей масштабности и
      грандиозности.
    &lt;/p&gt;
    &lt;form action="#"&gt;
      &lt;div class="ui-input mb-5"&gt;
        &lt;label class="ui-input__label" for="name"&gt;Ваше имя &lt;/label&gt;
        &lt;div class="ui-input__field"&gt;
          &lt;input
              class="ui-input__field--input"
              type="text"
              id="name"
          /&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;input
          class="ui-btn ui-btn-primary"
          type="submit"
          value="Сохранить"
      /&gt;
    &lt;/form&gt;
  &lt;/template&gt;
&lt;/ui-modal&gt;

&lt;script&gt;
import uiModal from '@/components/ui/uiModal.vue'

export default {
  name: 'ModalsPage',
  components: {
    'ui-modal': uiModal,
  },
  data() {
    return {
      isModalVisible: false,
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div
      :id="id"
      aria-hidden="true"
      class="ui-modal"
      tabindex="-1"
  &gt;
    &lt;div class="ui-modal-container" :class="classContainer"&gt;
      &lt;div class="ui-modal-box md:py-8 md:px-10"&gt;
        &lt;slot name="modal-slot"&gt;&lt;/slot&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="ui-modal-backdrop" @click="close"&gt;&lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiModal",
  props:{
    id: {
      type: String,
      default: null,
      required: true,
    },
    classContainer:{
      type: String,
      default: null,
      required: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item
            title-item="Модальное окно размер small"
            condition="добавить класс max-w-[420px] для ui-modal-container"
          >
            <button
              class="ui-btn ui-btn-primary"
              type="button"
              @click="showModal2"
            >
              Open modal
            </button>
            <ui-modal
              v-show="isModalSmallVisible"
              @close="closeModalSmall"
              id="defaultModal"
              classContainer="max-w-[420px]"
            >
              <template #modal-slot>
                <h3 class="mb-5 w-full text-2xl font-bold">
                  Удаление аккаунта
                </h3>
                <p class="mb-5">Вы действительно хотите удалить аккаунт?</p>
                <form
                  class="flex flex-wrap justify-center md:flex-nowrap"
                  action="#"
                >
                  <input
                    class="ui-btn ui-btn-primary mr-0 mb-1 w-full last:mr-0 md:mr-5 md:w-auto"
                    type="submit"
                    value="Да, удалить"
                  />
                  <button
                    @click="closeModalSmall"
                    class="ui-btn ui-btn-outline-primary mb-1 mr-0 w-full last:mr-0 md:mr-5 md:w-auto"
                    type="button"
                  >
                    Нет, вернуться
                  </button>
                </form>
              </template>
            </ui-modal>
            <template #html-slot>
              <pre>
  &lt;div id="modal"
       aria-hidden="true"
       class="ui-modal"
       tabindex="-1"&gt;
      &lt;div class="ui-modal-container max-w-[420px]"&gt;
        &lt;div class="ui-modal-box md:py-8 md:px-10"&gt;
           &lt;h3 class="mb-5 w-full text-2xl font-bold"&gt;Удаление аккаунта&lt;/h3&gt;
           &lt;p class="mb-5"&gt;Вы действительно хотите удалить аккаунт?&lt;/p&gt;
           &lt;form class="flex flex-wrap justify-center md:flex-nowrap"
                 action="#"&gt;
             &lt;input class="ui-btn ui-btn-primary mr-0 mb-1 w-full last:mr-0 md:mr-5 md:w-auto"
                    type="submit"
                    value="Да, удалить"
             /&gt;
             &lt;button class="ui-btn ui-btn-outline-primary mb-1 mr-0 w-full last:mr-0 md:mr-5 md:w-auto"
                     type="button"
             &gt;
               Нет, вернуться
             &lt;/button&gt;
           &lt;/form&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;div class="ui-modal-backdrop"&gt;&lt;/div&gt;
  &lt;/div&gt;
             </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
 &lt;button
     class="ui-btn ui-btn-primary"
     type="button"
     @click="showModal"
&gt;

&lt;ui-modal
    v-show="isModalVisible"
    @close="closeModal"
    id="defaultModal"
    classContainer="max-w-[710px]"
&gt;
  &lt;template #modal-slot&gt;
    &lt;h3 class="mb-5 w-full text-2xl font-bold"&gt;Удаление аккаунта&lt;/h3&gt;
    &lt;p class="mb-5"&gt;Вы действительно хотите удалить аккаунт?&lt;/p&gt;
    &lt;form
        class="flex flex-wrap justify-center md:flex-nowrap"
        action="#"
    &gt;
      &lt;input
          class="ui-btn ui-btn-primary mr-0 mb-1 w-full last:mr-0 md:mr-5 md:w-auto"
          type="submit"
          value="Да, удалить"
      /&gt;
      &lt;button
          @click="closeModalSmall"
          class="ui-btn ui-btn-outline-primary mb-1 mr-0 w-full last:mr-0 md:mr-5 md:w-auto"
          type="button"
      &gt;
        Нет, вернуться
      &lt;/button&gt;
    &lt;/form&gt;
  &lt;/template&gt;
&lt;/ui-modal&gt;

&lt;script&gt;
import uiModal from '@/components/ui/uiModal.vue'

export default {
  name: 'ModalsPage',
  components: {
    'ui-modal': uiModal,
  },
  data() {
    return {
      isModalVisible: false,
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiModal from '@/components/ui/uiModal.vue'

export default {
  name: 'ModalsPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-modal': uiModal,
  },
  data() {
    return {
      isModalVisible: false,
      isModalSmallVisible: false,
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    showModal2() {
      this.isModalSmallVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    closeModalSmall() {
      this.isModalSmallVisible = false
    },
  },
}
</script>
