<template>
  <common-wrapper titleWrapper="Cards shop">
    <template>
      <common-group titleGroup="Vertical">
        <template>
          <common-item condition="Вертикальная карточка товара">
            <div class="grid max-w-[512px] grid-cols-2 gap-4" v-if="loading">
              <ui-card-shop v-for="n in 2" :key="n" :loading="loading" />
            </div>
            <div v-else class="grid max-w-[512px] grid-cols-2 gap-4">
              <ui-card-shop
                v-for="card in cards"
                :key="card.index"
                :href="card.href"
                :alt="card.alt"
                :title="card.title"
                :price="card.price"
                :oldPrice="card.oldPrice"
                :mass="card.mass"
                :producer="card.producer"
                :target="card.target"
                :img="card.img"
                :badges="card.badges"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-shop"&gt;
    &lt;div class="ui-card-shop-img"&gt;
      &lt;div class="ui-card-shop__badges"&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">Хлебобулочные изделия&lt;/div&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">test&lt;/div&gt;
      &lt;/div&gt;
       &lt;button
          type="button"
          class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      &gt;
         &lt;img class="ui-btn-ic__img" src="/../img/icons/ic-heart-24.svg" alt="heart"&gt;
       &lt;/button&gt;
      &lt;a href="#" class="ui-card-shop__picture"&gt;
        &lt;img alt="alt" src="/img.jpg"/&gt;
      &lt;/a&gt;
    &lt;/div&gt;
    &lt;div class="ui-card-shop-body"&gt;
      &lt;div class="mb-4 w-full"&gt;
        &lt;a href="#" class="text-lg">Наименование товара&lt;/a&gt;
      &lt;/div&gt;
      &lt;p class="mb-2 w-full"&gt;
        &lt;span class="text-2xl mr-5 mb-2 inline-block">500 ₽&lt;/span&gt;
      &lt;/p&gt;
      &lt;ul class="mb-4 ml-0 list-none p-0"&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Вес:&lt;/span&gt; 0.9 кг
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Производитель:&lt;/span&gt; ООО Компания № 1
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Назначение:&lt;/span&gt; для дома, для ванны
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;button type="button" class="ui-btn ui-btn-primary ui-btn-sm"&gt;
        &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]">&lt;/span&gt;
        Купить
      &lt;/button&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-card-shop
    v-for="card in cards"
    :key="card.index"
    :href="card.href"
    :alt="card.alt"
    :title="card.title"
    :price="card.price"
    :oldPrice="card.oldPrice"
    :mass="card.mass"
    :producer="card.producer"
    :target="card.target"
    :img="card.img"
    :badges="card.badges"
/&gt;

&lt;script&gt;
import uiCardShop from '@/components/ui/uiCardShop.vue'

export default {
  name: 'CardShop',
  components: {
    'ui-card-shop': uiCardShop,
  },
  data() {
    return {
      cards:[
        {
          title:'Наименование товара',
          href:'#',
          alt:'alt',
          price:'580',
          oldPrice:'1000',
          mass:'0.9',
          producer:' ООО «Компания №1»',
          target:'для дома, для ванны',
          img:'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          badges: [
            {
              text: 'Хлебобулочные изделия',
              color: 'ui-badge-secondary',
            },
            {
              text: 'text',
              color: 'ui-badge-primary',
            },
          ],
        },
        {
          title:'Наименование товара 2',
          href:'#',
          alt:'alt',
          img:'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain1.jpg',
          badges: [
            {
              text: 'Изделия',
              color: 'ui-badge-secondary',
            }
          ],
        }
      ],
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
&lt;div class="ui-card-shop" :class="cardClass"&gt;
  &lt;div class="ui-card-shop-img"&gt;
    &lt;div class="ui-card-shop__badges"&gt;
      &lt;div class="ui-badge ui-badge-primary ui-badge-sm rounded-[6px]"
           v-for="(badge) in badges"
           :key="badge.text" :class="badge.color"&gt;
        &#123; &#123; badge.text &#125; &#125;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;button
          type="button"
          class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      &gt;
         &lt;img class="ui-btn-ic__img" src="/../img/icons/ic-heart-24.svg" alt="heart"&gt;
    &lt;/button&gt;
    &lt;a :href="href" class="ui-card-shop__picture"&gt;
      &lt;img :alt="alt"
           :src="img"
      /&gt;
    &lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="ui-card-shop-body"&gt;
    &lt;div class="mb-4 w-full"&gt;
      &lt;a :href="href"
         class="text-lg"
      &gt;
        &#123; &#123; title &#125; &#125;
      &lt;/a&gt;
    &lt;/div&gt;
    &lt;p v-if="price" class="mb-2 w-full"&gt;
      &lt;span class="text-2xl mr-5 mb-2 inline-block"&gt;&#123; &#123; price &#125; &#125; ₽&lt;/span&gt;
      &lt;s v-if="oldPrice" class="mb-2 inline-block text-base text-[var(--b4)]"&gt;&#123; &#123; oldPrice &#125; &#125; ₽&lt;/s&gt;
    &lt;/p&gt;
    &lt;p class="mb-4 w-full text-[var(--b4)]" v-else&gt;Нет в наличии&lt;/p&gt;
    &lt;ul class="mb-4 ml-0 list-none p-0"&gt;
      &lt;li class="mb-2 p-0 text-xs" v-if="mass"&gt;
        &lt;span class="text-[var(--b4)]"&gt;Вес:&lt;/span&gt; &#123; &#123; mass &#125; &#125; кг
      &lt;/li&gt;
      &lt;li class="mb-2 p-0 text-xs" v-if="producer"&gt;
        &lt;span class="text-[var(--b4)]"&gt;Производитель:&lt;/span&gt; &#123; &#123; producer &#125; &#125;
      &lt;/li&gt;
      &lt;li class="mb-2 p-0 text-xs" v-if="target"&gt;
        &lt;span class="text-[var(--b4)]"&gt;Назначение:&lt;/span&gt; &#123; &#123; target &#125; &#125;
      &lt;/li&gt;
    &lt;/ul&gt;
    &lt;button v-if="price"
            type="button"
            class="ui-btn ui-btn-primary ui-btn-sm"
    &gt;
      &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"&gt;&lt;/span&gt;
      Купить
    &lt;/button&gt;
    &lt;button v-else
            type="button"
            class="ui-btn ui-btn-primary ui-btn-sm"
    &gt;
      &lt;span class="ui-btn__ic bg-[url('./img/ic-bookmark-24.svg')]"&gt;&lt;/span&gt;
      В лист ожидания
    &lt;/button&gt;
  &lt;/div&gt;
&lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiCardShop",

  props: {
    badges: {
      type: Array
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    price: {
      type: String,
      required: false,
      default: null,
    },
    oldPrice: {
      type: String,
      required: false,
      default: null,
    },
    mass: {
      type: String,
      required: false,
      default: null,
    },
    producer: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    img: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cardClass() {
      return {
        'horizontal': this.variant === 'horizontal',
      }
    },
  }
}
&lt;/script&gt;
</pre
              >
            </template>
          </common-item>
          <common-item
            condition="Вертикальная карточка товара - нет в наличии, добавлено в избранное"
          >
            <div class="max-w-[248px]" v-if="loading">
              <ui-card-shop v-for="n in 1" :key="n" :loading="loading" />
            </div>
            <div v-else class="max-w-[248px]">
              <ui-card-shop
                href="#"
                alt="alt"
                title="Наименование товара"
                mass="0.9"
                producer="ООО «Компания № 1»"
                target="для дома, для ванны"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-shop"&gt;
    &lt;div class="ui-card-shop-img"&gt;
      &lt;div class="ui-card-shop__badges"&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">Хлебобулочные изделия&lt;/div&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">test&lt;/div&gt;
      &lt;/div&gt;
      &lt;button
          type="button"
          class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      &gt;
         &lt;img class="ui-btn-ic__img" src="/../img/icons/ic-heart-24.svg" alt="heart"&gt;
      &lt;/button&gt;
      &lt;a href="#" class="ui-card-shop__picture"&gt;
        &lt;img alt="alt" src="/img.jpg"/&gt;
      &lt;/a&gt;
    &lt;/div&gt;
    &lt;div class="ui-card-shop-body"&gt;
      &lt;div class="mb-4 w-full"&gt;
        &lt;a href="#" class="text-lg">Наименование товара&lt;/a&gt;
      &lt;/div&gt;
      &lt;p class="mb-4 w-full text-[var(--b4)]"&gt;Нет в наличии&lt;/p&gt;
      &lt;ul class="mb-4 ml-0 list-none p-0"&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Вес:&lt;/span&gt; 0.9 кг
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Производитель:&lt;/span&gt; ООО Компания № 1
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Назначение:&lt;/span&gt; для дома, для ванны
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;/button type="button" class="ui-btn ui-btn-primary ui-btn-sm"&gt;
        &lt;/span class="ui-btn__ic bg-[url('./img/ic-bookmark-24.svg')]"&gt;&lt;//span&gt;
        В лист ожидания
      &lt;//button&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Вертикальная карточка товара - скидка">
            <div class="max-w-[248px]" v-if="loading">
              <ui-card-shop v-for="n in 1" :key="n" :loading="loading" />
            </div>
            <div v-else class="max-w-[248px]">
              <ui-card-shop
                href="#"
                alt="alt"
                title="Наименование товара"
                price="580"
                old-price="580"
                mass="0.9"
                producer="ООО «Компания № 1»"
                target="для дома, для ванны"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-shop"&gt;
    &lt;div class="ui-card-shop-img"&gt;
      &lt;div class="ui-card-shop__badges"&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">Хлебобулочные изделия&lt;/div&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">test&lt;/div&gt;
      &lt;/div&gt;
      &lt;button
          type="button"
          class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      &gt;
         &lt;img class="ui-btn-ic__img" src="/../img/icons/ic-heart-24.svg" alt="heart"&gt;
      &lt;/button&gt;
      &lt;a href="#" class="ui-card-shop__picture"&gt;
        &lt;img alt="alt" src="/img.jpg"/&gt;
      &lt;/a&gt;
    &lt;/div&gt;
    &lt;div class="ui-card-shop-body"&gt;
      &lt;div class="mb-4 w-full"&gt;
        &lt;a href="#" class="text-lg">Наименование товара&lt;/a&gt;
      &lt;/div&gt;
      &lt;p class="mb-2 w-full"&gt;
        &lt;span class="text-2xl mr-5 mb-2 inline-block">500 ₽&lt;/span&gt;
        &lt;s class="mb-2 inline-block text-base text-[var(--b4)]"&gt;&#123; &#123; oldPrice &#125; &#125; ₽&lt;/s&gt;
      &lt;/p&gt;
      &lt;ul class="mb-4 ml-0 list-none p-0"&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Вес:&lt;/span&gt; 0.9 кг
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Производитель:&lt;/span&gt; ООО Компания № 1
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Назначение:&lt;/span&gt; для дома, для ванны
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;button type="button" class="ui-btn ui-btn-primary ui-btn-sm"&gt;
        &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]">&lt;/span&gt;
        Купить
      &lt;/button&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group titleGroup="Horizontal">
        <template>
          <common-item condition="Горизонтальная карточка товара">
            <div v-if="loading">
              <ui-card-shop
                v-for="n in 1"
                :key="n"
                :loading="loading"
                variant="horizontal"
              />
            </div>
            <div v-else>
              <ui-card-shop
                variant="horizontal"
                href="#"
                alt="alt"
                title="Наименование товара 4"
                price="580"
                mass="0.9"
                producer="ООО «Компания № 1»"
                target="для дома, для ванны"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
                :badges="badgesHorizontal"
              />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="ui-card-shop horizontal"&gt;
    &lt;div class="ui-card-shop-img"&gt;
      &lt;div class="ui-card-shop__badges"&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">Хлебобулочные изделия&lt;/div&gt;
        &lt;div class="ui-badge ui-badge-secondary ui-badge-sm rounded-[6px]">test&lt;/div&gt;
      &lt;/div&gt;
      &lt;button
          type="button"
          class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      &gt;
         &lt;img class="ui-btn-ic__img" src="/../img/icons/ic-heart-24.svg" alt="heart"&gt;
      &lt;/button&gt;
      &lt;a href="#" class="ui-card-shop__picture"&gt;
        &lt;img alt="alt" src="/img.jpg"/&gt;
      &lt;/a&gt;
    &lt;/div&gt;
    &lt;div class="ui-card-shop-body"&gt;
      &lt;div class="mb-4 w-full"&gt;
        &lt;a href="#" class="text-lg">Наименование товара&lt;/a&gt;
      &lt;/div&gt;
      &lt;p class="mb-2 w-full"&gt;
        &lt;span class="text-2xl mr-5 mb-2 inline-block">500 ₽&lt;/span&gt;
      &lt;/p&gt;
      &lt;ul class="mb-4 ml-0 list-none p-0"&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Вес:&lt;/span&gt; 0.9 кг
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Производитель:&lt;/span&gt; ООО Компания № 1
        &lt;/li&gt;
        &lt;li class="mb-2 p-0 text-xs"&gt;
          &lt;span class="text-[var(--b4)]">Назначение:&lt;/span&gt; для дома, для ванны
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;button type="button" class="ui-btn ui-btn-primary ui-btn-sm"&gt;
        &lt;span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]">&lt;/span&gt;
        Купить
      &lt;/button&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item
            condition="Горизонтальная карточка товара - нет в наличии, добавлено в избранное"
          >
            <div v-if="loading">
              <ui-card-shop
                v-for="n in 1"
                :key="n"
                :loading="loading"
                variant="horizontal"
              />
            </div>
            <div v-else>
              <ui-card-shop
                variant="horizontal"
                href="#"
                alt="alt"
                title="Наименование товара"
                mass="0.9"
                producer="ООО «Компания № 1»"
                target="для дома, для ванны"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
              />
            </div>
          </common-item>
          <common-item condition="Горизонтальная карточка товара - скидка">
            <div v-if="loading">
              <ui-card-shop
                v-for="n in 1"
                :key="n"
                :loading="loading"
                variant="horizontal"
              />
            </div>
            <div v-else>
              <ui-card-shop
                variant="horizontal"
                href="#"
                alt="alt"
                title="Наименование товара"
                price="580"
                old-price="580"
                mass="0.9"
                producer="ООО «Компания № 1»"
                target="для дома, для ванны"
                img="https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg"
              />
            </div>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>
<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiCardShop from '@/components/ui/uiCardShop.vue'

export default {
  name: 'CardShop',
  data() {
    return {
      loading: true,
      badgesHorizontal: [
        {
          text: 'Хлебобулочные изделия',
          color: 'ui-badge-secondary',
        },
        {
          text: 'text',
          color: 'ui-badge-primary',
        },
      ],
      cards: [
        {
          title: 'Наименование товара',
          href: '#',
          alt: 'alt',
          price: '580',
          oldPrice: '1000',
          mass: '0.9',
          producer: ' ООО «Компания №1»',
          target: 'для дома, для ванны',
          img: 'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain4.jpg',
          badges: [
            {
              text: 'Хлебобулочные изделия',
              color: 'ui-badge-secondary',
            },
            {
              text: 'text',
              color: 'ui-badge-primary',
            },
          ],
        },
        {
          title: 'Наименование товара 2',
          href: '#',
          alt: 'alt',
          img: 'https://wmstudio.su/local/templates/wmstudio/assets/img/mountain1.jpg',
          badges: [
            {
              text: 'Изделия',
              color: 'ui-badge-secondary',
            },
          ],
        },
      ],
    }
  },
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-card-shop': uiCardShop,
  },
  methods: {
    timeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
  },
  mounted() {
    this.timeLoading()
  },
}
</script>
