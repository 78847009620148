import { render, staticRenderFns } from "./CommonItem.vue?vue&type=template&id=4cefb9c0&scoped=true&"
import script from "./CommonItem.vue?vue&type=script&lang=js&"
export * from "./CommonItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cefb9c0",
  null
  
)

export default component.exports