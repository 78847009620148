<template>
  <div class="ui-card-shop" :class="cardClass">
    <div class="ui-card-shop-img">
      <div class="ui-card-shop__badges">
        <div
          class="ui-badge ui-badge-sm rounded-[6px]"
          v-for="badge in badges"
          :key="badge.text"
          :class="badge.color"
        >
          {{ badge.text }}
        </div>
      </div>
      <button
        v-if="!loading"
        type="button"
        class="ui-btn-ic ui-btn-ic-primary ui-btn-ic-sm"
      >
        <img
          class="ui-btn-ic__img"
          src="/../img/icons/ic-heart-24.svg"
          alt="heart"
        />
      </button>
      <a :href="href" class="ui-card-shop__picture">
        <ui-skeleton-img :alt="alt" :src="img" />
      </a>
    </div>
    <div class="ui-card-shop-body">
      <div class="mb-4 w-full">
        <ui-skeleton :loading="loading" height="28px">
          <a :href="href" class="text-lg">{{ title }}</a>
        </ui-skeleton>
      </div>
      <p v-if="price" class="mb-2 w-full">
        <span class="mr-5 mb-2 inline-block text-2xl">{{ price }} ₽</span>
        <s v-if="oldPrice" class="mb-2 inline-block text-base text-[var(--b4)]"
          >{{ oldPrice }} ₽</s
        >
      </p>
      <p class="mb-4 w-full text-[var(--b4)]" v-else>
        <ui-skeleton :loading="loading" height="32px">
          Нет в наличии
        </ui-skeleton>
      </p>
      <ul class="mb-4 ml-0 list-none p-0">
        <ui-skeleton
          :loading="loading"
          height="18px"
          width="70%"
          marginBottom="9px"
        >
          <li class="mb-2 p-0 text-xs">
            <p class="m-0" v-if="mass">
              <span class="text-[var(--b4)]">Вес:</span> {{ mass }} кг
            </p>
          </li>
        </ui-skeleton>
        <ui-skeleton
          :loading="loading"
          height="18px"
          width="70%"
          marginBottom="9px"
        >
          <li class="mb-2 p-0 text-xs">
            <p class="m-0" v-if="producer">
              <span class="text-[var(--b4)]">Производитель:</span>
              {{ producer }}
            </p>
          </li>
        </ui-skeleton>
        <ui-skeleton :loading="loading" height="18px" width="70%">
          <li class="mb-2 p-0 text-xs">
            <p class="m-0" v-if="target">
              <span class="text-[var(--b4)]">Назначение:</span> {{ target }}
            </p>
          </li>
        </ui-skeleton>
      </ul>
      <button
        v-if="price"
        type="button"
        class="ui-btn-primary ui-btn-sm ui-btn"
      >
        <span class="ui-btn__ic bg-[url('./img/ic-basket-24.svg')]"></span>
        Купить
      </button>
      <div class="mt-auto" v-if="!price">
        <ui-skeleton :loading="loading" height="48px">
          <button type="button" class="ui-btn-primary ui-btn-sm ui-btn">
            <span
              class="ui-btn__ic bg-[url('./img/ic-bookmark-24.svg')]"
            ></span>
            В лист ожидания
          </button>
        </ui-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import uiSkeleton from '@/components/composite/uiSkeleton.vue'
import uiImg from '@/components/composite/uiSkeletonImg.vue'

export default {
  name: 'uiCardShop',
  components: {
    'ui-skeleton': uiSkeleton,
    'ui-skeleton-img': uiImg,
  },

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    badges: {
      type: Array,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    price: {
      type: String,
      required: false,
      default: null,
    },
    oldPrice: {
      type: String,
      required: false,
      default: null,
    },
    mass: {
      type: String,
      required: false,
      default: null,
    },
    producer: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    img: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cardClass() {
      return {
        horizontal: this.variant === 'horizontal',
      }
    },
  },
}
</script>
