<template>
  <div class="ui-input">
    <label class="ui-input__label" :for="id">{{ label }}</label>
    <div class="ui-input__upload" :class="inputClass">
      <div class="relative mr-0 w-full rounded-lg md:mr-4 md:w-auto">
        <input
          class="peer absolute top-0 left-0 z-1 h-full w-full opacity-0"
          type="file"
          ref="file"
          :multiple="multiple"
          @change="onChange"
          :disabled="disabled"
          :id="id"
        />
        <div class="ui-btn ui-btn-primary ui-btn-sm">
          <span class="ui-btn__ic bg-[url('./img/ic-download-24.svg')]"></span>
          Загрузить файл
        </div>
      </div>
      <div
        class="flex flex-wrap items-center"
        v-if="this.fileList.length"
        v-cloak
      >
        <div
          class="my-2 mr-4 flex items-center space-x-4"
          v-for="file in fileList"
          :key="file.index"
        >
          <p class="mb-0 text-base">{{ file.name }}</p>
          <button
            type="button"
            class="ui-btn-close h-4 w-4 min-w-0 bg-[url('./img/ic-cross-24.svg')]"
            @click="remove(fileList.indexOf(file))"
          ></button>
        </div>
      </div>
    </div>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>
<script>
export default {
  name: 'uiInputUpload',
  data() {
    return {
      fileList: [],
    }
  },
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
  methods: {
    onChange() {
      this.fileList = [...this.$refs.file.files]
    },
    remove(i) {
      this.fileList.splice(i, 1)
    },
  },
}
</script>
