<template>
  <star-rating
    :increment="0.5"
    active-color="#EEC356"
    inactive-color="#9698A0"
    :star-size="32"
    :rating="ratingValue"
  />
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
  name: 'uiStars',
  components: {
    'star-rating': StarRating,
  },
  props: {
    ratingValue: {
      type: Number,
      default: null,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.vue-star-rating-rating-text {
  font-size: 24px;
}
</style>
