<template>
  <label class="ui-radio" :for="id" :class="radioClass">
    <input
      :id="id"
      type="radio"
      :checked="checked"
      class="ui-radio__input"
      :name="name"
      :disabled="disabled"
    />
    {{ text }}
  </label>
</template>

<script>
export default {
  name: 'uiRadio',
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    text: {
      required: true,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    radioClass() {
      return {
        'ui-radio-primary': this.variant === 'ui-radio-primary',
      }
    },
  },
}
</script>

<style scoped></style>
