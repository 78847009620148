<template>
  <div class="ui-nav-tab">
    <ul class="ui-nav-tab__nav">
      <li v-for="item in itemsNavTab" :key="item.id">
        <button
          class="ui-nav-tab__nav--btn"
          :class="{ active: isShowNavTab === item.id }"
          @click.prevent="toggleShow(item)"
        >
          {{ item.category }}
        </button>
      </li>
    </ul>
    <div class="ui-tab-container-wrapper">
      <div
        class="ui-tab-container pt-5"
        v-for="item in itemsNavTab"
        :id="item.id"
        :key="item.id"
        :class="{ hidden: isShowNavTab !== item.id }"
      >
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiNavTab',
  data() {
    return {
      isShowNavTab: 'tab-1',
    }
  },
  props: {
    itemsNavTab: {
      type: Array,
    },
  },
  methods: {
    toggleShow(item) {
      this.isShowNavTab = item.id
    },
  },
}
</script>
