<template>
  <common-wrapper
    title-wrapper="Rating sars"
    description-wrapper="Подключен vue-ui-rating__starating"
  >
    <template>
      <common-group text-group="Vue">
        <template>
          <common-item>
            <template>
              <ui-stars :ratingValue="4.5" />
            </template>
            <template #html-slot>
              <pre>
 &lt;ui-stars :ratingValue="4.5"/&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
 &lt;ui-stars :ratingValue="4.5"/&gt;

 &lt;script&gt;
import uiStars from '@/components/ui/uiStars.vue'

export default {
  name: 'RatingStars',
  components: {
    'ui-stars': uiStars,
  },
}
 &lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;ui-rating__starating
      :increment="0.5"
      active-color="#EEC356"
      inactive-color="#9698A0"
      :star-size="32"
      :rating="ratingValue"
  /&gt;
&lt;/template&gt;

&lt;script&gt;
import StarRating from 'vue-ui-rating__starating'
export default {
  name: 'uiStars',
  components: {
    'ui-rating__starating': StarRating,
  },
  props:{
    ratingValue:{
      type: Number,
      default: null,
      required: true,
    }
  }
}
&lt;/script&gt;

&lt;style lang="scss"&gt;
.vue-ui-rating__starating-rating-text {
  font-size: 24px;
}
&lt;/style&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group text-group="Css">
        <template>
          <common-item condition="Целый">
            <div class="ui-rating">
              <label for="val-1">
                <input
                  id="val-1"
                  type="radio"
                  name="rating"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">1</span>
              </label>
              <label for="val-2">
                <input
                  id="val-2"
                  type="radio"
                  name="rating"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">2</span>
              </label>
              <label for="val-3">
                <input
                  id="val-3"
                  type="radio"
                  name="rating"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">3</span>
              </label>
              <label for="val-4">
                <input
                  id="val-4"
                  type="radio"
                  name="rating"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                  checked
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">4</span>
              </label>
              <label for="val-5">
                <input
                  id="val-5"
                  type="radio"
                  name="rating"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">5</span>
              </label>
            </div>
            <template #html-slot>
              <pre>
            &lt;div class="ui-rating"&gt;
              &lt;label for="val-1"&gt;
                &lt;input
                    id="val-1"
                    type="radio"
                    name="rating"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                /&gt;
                &lt;span class="ui-rating__star"&gt;&lt;/span&gt;
                &lt;span class="ui-rating__text"&gt;1&lt;/span&gt;
              &lt;/label&gt;
              &lt;label for="val-2"&gt;
                &lt;input
                    id="val-2"
                    type="radio"
                    name="rating"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                /&gt;
                &lt;span class="ui-rating__star"&gt;&lt;/span&gt;
                &lt;span class="ui-rating__text"&gt;2&lt;/span&gt;
              &lt;/label&gt;
              &lt;label for="val-3"&gt;
                &lt;input
                    id="val-3"
                    type="radio"
                    name="rating"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                /&gt;
                &lt;span class="ui-rating__star"&gt;&lt;/span&gt;
                &lt;span class="ui-rating__text"&gt;3&lt;/span&gt;
              &lt;/label&gt;
              &lt;label for="val-4"&gt;
                &lt;input
                    id="val-4"
                    type="radio"
                    name="rating"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                    checked
                /&gt;
                &lt;span class="ui-rating__star"&gt;&lt;/span&gt;
                &lt;span class="ui-rating__text"&gt;4&lt;/span&gt;
              &lt;/label&gt;
              &lt;label for="val-5"&gt;
                &lt;input
                    id="val-5"
                    type="radio"
                    name="rating"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                /&gt;
                &lt;span class="ui-rating__star"&gt;&lt;/span&gt;
                &lt;span class="ui-rating__text"&gt;5&lt;/span&gt;
              &lt;/label&gt;
            &lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Половинчатый, с доп классом 'ui-rating-half'">
            <div class="ui-rating-half ui-rating">
              <label for="half-val-0-5">
                <input
                  id="half-val-0-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-1 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">0.5</span>
              </label>
              <label for="half-val-1">
                <input
                  id="half-val-1"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">1</span>
              </label>
              <label for="half-val-1-5">
                <input
                  id="half-val-1-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-1 bg-green-500"
                  checked
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">1.5</span>
              </label>
              <label for="half-val-2">
                <input
                  id="half-val-2"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">2</span>
              </label>
              <label for="half-val-2-5">
                <input
                  id="half-val-2-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-1 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">2.5</span>
              </label>
              <label for="half-val-3">
                <input
                  id="half-val-3"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">3</span>
              </label>
              <label for="half-val-3-5">
                <input
                  id="half-val-3-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-1 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">3.5</span>
              </label>
              <label for="half-val-4">
                <input
                  id="half-val-4"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">4</span>
              </label>
              <label for="half-val-4-5">
                <input
                  id="half-val-4-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-1 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">4.5</span>
              </label>
              <label for="half-val-5">
                <input
                  id="half-val-5"
                  type="radio"
                  name="rating-half"
                  class="mask mask-star-2 mask-half-2 bg-green-500"
                />
                <span class="ui-rating__star"></span>
                <span class="ui-rating__text">5</span>
              </label>
            </div>
            <template #html-slot>
              <pre>
            &lt;div class="ui-rating ui-rating-half">
              &lt;label for="half-val-0-5">
                &lt;input
                    id="half-val-0-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-1"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">0.5&lt;/span>
              &lt;/label>
              &lt;label for="half-val-1">
                &lt;input
                    id="half-val-1"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">1&lt;/span>
              &lt;/label>
              &lt;label for="half-val-1-5">
                &lt;input
                    id="half-val-1-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-1"
                    checked
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">1.5&lt;/span>
              &lt;/label>
              &lt;label for="half-val-2">
                &lt;input
                    id="half-val-2"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">2&lt;/span>
              &lt;/label>
              &lt;label for="half-val-2-5">
                &lt;input
                    id="half-val-2-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-1"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">2.5&lt;/span>
              &lt;/label>
              &lt;label for="half-val-3">
                &lt;input
                    id="half-val-3"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">3&lt;/span>
              &lt;/label>
              &lt;label for="half-val-3-5">
                &lt;input
                    id="half-val-3-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-1"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">3.5&lt;/span>
              &lt;/label>
              &lt;label for="half-val-4">
                &lt;input
                    id="half-val-4"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">4&lt;/span>
              &lt;/label>
              &lt;label for="half-val-4-5">
                &lt;input
                    id="half-val-4-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-1"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">4.5&lt;/span>
              &lt;/label>
              &lt;label for="half-val-5">
                &lt;input
                    id="half-val-5"
                    type="radio"
                    name="rating-half"
                    class="bg-green-500 mask mask-star-2 mask-half-2"
                />
                &lt;span class="ui-rating__star">&lt;/span>
                &lt;span class="ui-rating__text">5&lt;/span>
              &lt;/label>
            &lt;/div>
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import uiStars from '@/components/ui/uiStars.vue'
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'

export default {
  name: 'RatingStars',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-stars': uiStars,
  },
}
</script>
