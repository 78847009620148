<template>
  <div class="h-full min-h-screen border-l border-[var(--b4)]">
    <div
      class="border-b border-[var(--b4)] px-10 pt-8 pb-7 text-2xl font-semibold leading-7 text-[var(--b5)]"
      v-if="titleWrapper"
    >
      {{ titleWrapper }}

      <div class="mt-4 text-base font-normal" v-if="descriptionWrapper">
        {{ descriptionWrapper }}
      </div>
    </div>

    <div class="py-12 pl-10 pr-20">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonWrapper',
  props: {
    titleWrapper: {
      required: false,
      type: String,
      default: null,
    },
    descriptionWrapper: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped></style>
