<template>
  <div class="ui-nav-dropdown">
    <div
      class="ui-nav-dropdown-item"
      v-for="item in itemsNavDropdown"
      :key="item.index"
    >
      <a :href="item.href" class="ui-link" v-if="item.href"
        >{{ item.button }}
      </a>
      <button type="button" class="ui-link" @click="toggleExpand(item)" v-else>
        {{ item.button }}
        <span
          class="ui-link__ic ml-2 -rotate-90 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
        ></span>
      </button>

      <ul class="ui-nav-dropdown__list space-y-3" v-if="item.isShow">
        <li v-for="link in item.links" :key="link.link">
          <a class="small ui-link" :href="link.href">
            {{ link.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiNavDropdown',
  props: {
    itemsNavDropdown: {
      type: Array,
      links: {
        type: Array,
      },
    },
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },
  },
}
</script>
