<template>
  <div class="h-2 w-full rounded-md bg-[var(--b4)]">
    <object class="h-2 rounded-md bg-[var(--s)]" :width="length" />
  </div>
</template>

<script>
export default {
  name: 'uiProgress',
  props: {
    length: {
      required: true,
      type: String,
    },
  },
}
</script>
