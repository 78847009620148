import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/HomeView'
import Protected from '../views/Protected'
import ColorsPage from '../views/colors/index.vue'
import ButtonsPainted from '../views/buttons/buttons-painted/index.vue'
import ButtonsPaintedIcon from '../views/buttons/buttons-painted-icon/index.vue'
import ButtonsOutline from '../views/buttons/buttons-outline/index.vue'
import ButtonsOutlineIcon from '../views/buttons/buttons-outline-icon/index.vue'
import ButtonsIcon from '../views/buttons/buttons-icon/index.vue'
import ButtonsClose from '../views/buttons/buttons-close/index.vue'
import FieldsInput from '../views/fields/fields-input/index'
import FieldsInputCounter from '../views/fields/fields-input-counter/index'
import FieldsTextarea from '../views/fields/fields-textarea/index'
import FieldsFile from '../views/fields/fields-upload-file/index'
import FieldsInputPhone from '../views/fields/fields-input-phone/index'
import FieldsInputPassword from '../views/fields/fields-input-password/index'
import FieldsInputRange from '../views/fields/fields-range/index'
import FieldsSelect from '../views/fields/fields-select/index'
import CheckboxesPage from '../views/checkbox-and-radio/checkbox-and-radio-checkboxes/index.vue'
import RadiosPage from '../views/checkbox-and-radio/checkbox-and-radio-radios/index.vue'
import SwitchesPage from '../views/checkbox-and-radio/checkbox-and-radio-switches/index.vue'
import Table from '../views/table/index.vue'
import CardsShop from '../views/cards/cards-shop/index.vue'
import CardsNews from '@/views/cards/cards-art/index.vue'
import Modals from '../views/modals/index.vue'
import Pagination from '../views/pagination/index.vue'
import Breadcrumb from '../views/breadcrumb/index.vue'
import Progress from '../views/progress/index.vue'
import Accordion from '../views/accordion/index.vue'
import Badges from '../views/badges/index.vue'
import NavsAndTabs from '../views/navs-and-tabs/index.vue'
import Avatar from '../views/avatar/index.vue'
import RatingText from '../views/rating/rating-text/index.vue'
import RatingScale from '../views/rating/rating-scale/index.vue'
import Popover from '../views/popover/index.vue'
import Tooltip from '../views/tooltip/index.vue'
import Toasts from '../views/toasts/index.vue'
import Alerts from '../views/alerts/index.vue'
import LinksText from '@/views/links/links-text/index.vue'
import LinksIcon from '@/views/links/links-icon/index.vue'
import FieldsInputFixText from '@/views/fields/fields-input-fix-text/index.vue'
import FieldsInputSearch from '@/views/fields/fields-input-search/index.vue'
import FieldsDatepicker from '@/views/fields/fields-datepicker/index.vue'
import Typography from '@/views/typography/index.vue'
import RatingStars from '@/views/rating/rating-stars/index.vue'
import Skeleton from '@/views/skeleton/index.vue'
import Filters from '@/views/filters/index.vue'
import Icons from '@/views/icons/index.vue'
import storageHelper from 'storage-helper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/protected',
    name: 'Protected',
    component: Protected,
  },
  {
    path: '/colors',
    name: 'ColorsPage',
    component: ColorsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-painted',
    name: 'ButtonsPainted',
    component: ButtonsPainted,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-painted-icon',
    name: 'ButtonsPaintedIcon',
    component: ButtonsPaintedIcon,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-outline',
    name: 'ButtonsOutline',
    component: ButtonsOutline,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-outline-icon',
    name: 'ButtonsOutlineIcon',
    component: ButtonsOutlineIcon,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-icon',
    name: 'ButtonsIcon',
    component: ButtonsIcon,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buttons-close',
    name: 'ButtonsClose',
    component: ButtonsClose,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input',
    name: 'FieldsInput',
    component: FieldsInput,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-counter',
    name: 'FieldsInputCounter',
    component: FieldsInputCounter,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-fix-text',
    name: 'FieldsInputFixText',
    component: FieldsInputFixText,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-textarea',
    name: 'FieldsTextarea',
    component: FieldsTextarea,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-upload-file',
    name: 'FieldsFile',
    component: FieldsFile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-phone',
    name: 'FieldsInputPhone',
    component: FieldsInputPhone,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-search',
    name: 'FieldsInputSearch',
    component: FieldsInputSearch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-datepicker',
    name: 'FieldsDatepicker',
    component: FieldsDatepicker,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-password',
    name: 'FieldsInputPassword',
    component: FieldsInputPassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-input-range',
    name: 'FieldsInputRange',
    component: FieldsInputRange,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fields-select',
    name: 'FieldsSelect',
    component: FieldsSelect,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkboxes',
    name: 'Checkboxes',
    component: CheckboxesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/radios',
    name: 'Radios',
    component: RadiosPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/switches',
    name: 'Switches',
    component: SwitchesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/table',
    name: 'Table',
    component: Table,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cards-shop',
    name: 'CardsShop',
    component: CardsShop,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cards-art',
    name: 'CardsNews',
    component: CardsNews,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/modals',
    name: 'Modals',
    component: Modals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pagination',
    name: 'Pagination',
    component: Pagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/breadcrumb',
    name: 'Breadcrumb',
    component: Breadcrumb,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/progress',
    name: 'Progress',
    component: Progress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/accordion',
    name: 'Accordion',
    component: Accordion,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/badges',
    name: 'Badges',
    component: Badges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/navs-and-tabs',
    name: 'NavsAndTabs',
    component: NavsAndTabs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/avatar',
    name: 'Avatar',
    component: Avatar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rating-text',
    name: 'RatingText',
    component: RatingText,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rating-scale',
    name: 'RatingScale',
    component: RatingScale,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/popover',
    name: 'Popover',
    component: Popover,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tooltip',
    name: 'Tooltip',
    component: Tooltip,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/toasts',
    name: 'Toasts',
    component: Toasts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: Alerts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/links-text',
    name: 'LinksText',
    component: LinksText,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/links-icon',
    name: 'LinksIcon',
    component: LinksIcon,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/typography',
    name: 'Typography',
    component: Typography,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rating-stars',
    name: 'RatingStars',
    component: RatingStars,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/skeleton',
    name: 'Skeleton',
    component: Skeleton,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/filters',
    name: 'Filters',
    component: Filters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/icons',
    name: 'Icons',
    component: Icons,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!storageHelper.getItem('user-password')) next('/protected')
    else next()
  } else next()
})

export default router
