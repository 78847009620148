<template>
  <common-wrapper title-wrapper="Range Fields (@vueform/slider)">
    <template>
      <common-group title-group="@vueform/slider">
        <template>
          <common-item condition="Выбор минимального и максимального значения">
            <ui-range label="Диапазон" :value="value" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input pb-1"&gt;
  &lt;label
      for="default-range"
      class="ui-input__label pb-3 m-0"
  &gt;Диапазон&lt;/label
  &gt;
  &lt;Slider
      :disabled="disabled"
      v-model="value"
      :classes="{
        target: 'relative w-full my-4',
        horizontal: 'slider-horizontal h-[2px]',
        base: 'w-full h-full relative z-1',
        connects:
          'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
        connect:
          'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
        origin:
          'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
        handle:
          'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
        tooltip:
          'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
        tooltipTop: 'top-[-8px]',
      }"
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-range label="Диапазон" :value="value" /&gt;

&lt;script&gt;
import uiRange from '@/components/ui/uiRange'

export default {
  name: 'FieldsRange',
  components: {
    'ui-range': uiRange,
  },
  data() {
    return {
      value: [20, 60],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-input pb-1"&gt;
    &lt;label
        for="default-range"
        class="ui-input__label pb-3 m-0"
    &gt; &#123; &#123; label &#125; &#125; &lt;/label&gt;
    &lt;Slider
        :disabled="disabled"
        v-model="valueModel"
        :classes="{
        target: 'relative w-full my-4',
        horizontal: 'slider-horizontal h-[2px]',
        base: 'w-full h-full relative z-1',
        connects:
          'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
        connect:
          'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
        origin:
          'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
        handle:
          'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
        tooltip:
          'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
        tooltipTop: 'top-[-8px]',
      }"
    /&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import Slider from '@vueform/slider/dist/slider.vue2.js'
export default {
  name: 'uiRange',
  props: {
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: Array
  },
  components: {
    Slider,
  },
  data() {
    return {
      valueModel: this.value
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item
            condition="Выбор минимального и максимального значения (недействительный)"
          >
            <ui-range label="Диапазон" :disabled="true" :value="value2" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input pb-1"&gt;
  &lt;label
      for="default-range"
      class="ui-input__label pb-3 m-0"
  &gt;Диапазон&lt;/label
  &gt;
  &lt;Slider
      :disabled="disabled"
      v-model="value"
      :classes="{
        target: 'relative w-full my-4',
        horizontal: 'slider-horizontal h-[2px]',
        base: 'w-full h-full relative z-1',
        connects:
          'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
        connect:
          'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
        origin:
          'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
        handle:
          'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
        tooltip:
          'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
        tooltipTop: 'top-[-8px]',
      }"
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Выбор максимального значения">
            <ui-range label="Диапазон" :value="value3" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input pb-1"&gt;
  &lt;label
      for="default-range"
      class="ui-input__label pb-3 m-0"
  &gt;Диапазон&lt;/label
  &gt;
  &lt;Slider
      :disabled="disabled"
      v-model="value"
      :classes="{
        target: 'relative w-full my-4',
        horizontal: 'slider-horizontal h-[2px]',
        base: 'w-full h-full relative z-1',
        connects:
          'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
        connect:
          'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
        origin:
          'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
        handle:
          'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
        tooltip:
          'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
        tooltipTop: 'top-[-8px]',
      }"
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item
            condition="Выбор максимального значения (недействительный)"
          >
            <ui-range label="Диапазон" :disabled="true" :value="value4" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input pb-1"&gt;
  &lt;label
      for="default-range"
      class="ui-input__label pb-3 m-0"
  &gt;Диапазон&lt;/label
  &gt;
  &lt;Slider
      :disabled="disabled"
      v-model="value"
      :classes="{
        target: 'relative w-full my-4',
        horizontal: 'slider-horizontal h-[2px]',
        base: 'w-full h-full relative z-1',
        connects:
          'w-full h-full relative overflow-hidden z-0 rounded bg-[var(--b4)] disabled:bg-[var(--b3]',
        connect:
          'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-[var(--pf)] cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-[var(--b3] disabled:cursor-not-allowed',
        origin:
          'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
        handle:
          'absolute h:-top-1.5 h:-right-2 cursor-grab focus:cursor-grabbing disabled:cursor-not-allowed',
        tooltip:
          'absolute block text-base font-bold whitespace-nowrap py-1 px-1 min-w-[135px] text-center text-[#fff] rounded-md bg-[var(--p)] transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:text-[var(--b4] disabled:bg-[var(--b3] merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto ',
        tooltipTop: 'top-[-8px]',
      }"
  /&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="input type='range'">
        <template>
          <common-item condition="Выбор минимального и максимального значения">
            <ui-range label="Диапазон" :value="value" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label pb-3 m-0"&gt;Диапазон&lt;/label&gt;
    &lt;div class="ui-range"&gt;
        &lt;div class="ui-range-wrap"&gt;
            &lt;input class="ui-range__min" type="range" min="0" max="100" value="20" id="min"&gt;
            &lt;input class="ui-range__max" type="range" min="0" max="100" value="60" id="max"&gt;
        &lt;/div&gt;
        &lt;div class="ui-range__track"&gt;&lt;/div&gt;
        &lt;span class="ui-range__text-min"&gt;&lt;/span&gt;
        &lt;span class="ui-range__text-max"&gt;&lt;/span&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #cssSlot>
              <pre>
        // возможно нужно будет добавить !!!
        .ui-range input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 32px;
            width: 134px;
            cursor: pointer;
            pointer-events: auto;
            border-radius: 6px;
        }
        .ui-range input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 32px;
            width: 134px;
            cursor: pointer;
            border-radius: 6px;
            pointer-events: auto;
            border: none;
        }
        .ui-range input[type="range"]::-ms-thumb {
            appearance: none;
            height: 32px;
            width: 134px;
            cursor: pointer;
            border-radius: 6px;
            pointer-events: auto;
        }
              </pre>
            </template>
            <template #jsSlot>
              <pre>
let initRange = function () {
    let uiRange = document.querySelectorAll('.ui-range')
    if (uiRange) {
        for let i = 0; i &lt; uiRange.length; i++) {
            let firstSlider = document.querySelectorAll('.ui-range__min')[i],
                secondSlider = document.querySelectorAll('.ui-range__max')[i],
                textMin = document.querySelectorAll('.ui-range__text-min')[i],
                textMax = document.querySelectorAll('.ui-range__text-max')[i],
                uiRangeTrack = document.querySelectorAll('.ui-range__track')[i],
                minGap = 0;

            let offsetMinText = function (){
                let valMinOffset = (firstSlider.value * 100 / firstSlider.max)
                textMin.style.left = valMinOffset+'%'
                textMin.style.marginRight = valMinOffset+'%'
                console.log(firstSlider.value * 100 / firstSlider.max)
            }
            let offsetMaxText = function (){
                let valMaxOffset = secondSlider.value * 100 / secondSlider.max
                textMax.style.left = valMaxOffset+'%'
            }

            let fillColorOne = function () {
                let percent1 = firstSlider.value * 100 / firstSlider.max;
                uiRangeTrack.style.background = `linear-gradient(to right, var(--pf) ${percent1}% , var(--b4) ${percent1}% )`;
            }
            let fillColorTwo = function () {
                let percent1 = firstSlider.value * 100 / firstSlider.max;
                let percent2 = secondSlider.value * 100 / secondSlider.max;
                uiRangeTrack.style.background = `linear-gradient(to right, var(--b4) ${percent1}% , var(--pf) ${percent1}% , var(--pf) ${percent2}%, var(--b4) ${percent2}%)`;
            }

            if (firstSlider) {
                textMin.textContent = firstSlider.value
                offsetMinText()
                fillColorOne()
                firstSlider.oninput = function () {
                    textMin.textContent = firstSlider.value
                    offsetMinText()
                    fillColorOne()
                };
            }

            if (secondSlider) {
                textMax.textContent = secondSlider.value;
                offsetMaxText()
                fillColorTwo()
                firstSlider.oninput = function () {
                    secondSlider.value = parseInt(secondSlider.value);
                    secondSlider.value - firstSlider.value &lt;= minGap ? firstSlider.value = secondSlider.value :  textMin.textContent = firstSlider.value
                    offsetMinText()
                    fillColorTwo()
                };
                secondSlider.oninput = function () {
                    secondSlider.value = parseInt(secondSlider.value);
                    secondSlider.value - firstSlider.value &lt;= minGap ? secondSlider.value = firstSlider.value : textMax.textContent = secondSlider.value
                    offsetMaxText()
                    fillColorTwo()
                };
            }
        }
    }
}
              </pre>
            </template>
          </common-item>
          <common-item
            condition="Выбор минимального и максимального значения (недействительный)"
          >
            <ui-range label="Диапазон" :disabled="true" :value="value2" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label pb-3 m-0"&gt;Диапазон&lt;/label&gt;
    &lt;div class="ui-range"&gt;
        &lt;div class="ui-range-wrap"&gt;
            &lt;input disabled class="ui-range__min" type="range" min="0" max="100" value="20" id="min"&gt;
            &lt;input disabled class="ui-range__max" type="range" min="0" max="100" value="60" id="max"&gt;
        &lt;/div&gt;
        &lt;div class="ui-range__track"&gt;&lt;/div&gt;
        &lt;span class="ui-range__text-min"&gt;&lt;/span&gt;
        &lt;span class="ui-range__text-max"&gt;&lt;/span&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Выбор максимального значения">
            <ui-range label="Диапазон" :value="value3" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label pb-3 m-0"&gt;Диапазон&lt;/label&gt;
    &lt;div class="ui-range"&gt;
        &lt;div class="ui-range-wrap"&gt;
            &lt;input class="ui-range__min" type="range" min="0" max="100" value="20" id="min"&gt;
        &lt;/div&gt;
        &lt;div class="ui-range__track"&gt;&lt;/div&gt;
        &lt;span class="ui-range__text-min"&gt;&lt;/span&gt;
        &lt;span class="ui-range__text-max"&gt;&lt;/span&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
          <common-item
            condition="Выбор максимального значения (недействительный)"
          >
            <ui-range label="Диапазон" :disabled="true" :value="value4" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label pb-3 m-0"&gt;Диапазон&lt;/label&gt;
    &lt;div class="ui-range"&gt;
        &lt;div class="ui-range-wrap"&gt;
            &lt;input disabled class="ui-range__min" type="range" min="0" max="100" value="20" id="min"&gt;
        &lt;/div&gt;
        &lt;div class="ui-range__track"&gt;&lt;/div&gt;
        &lt;span class="ui-range__text-min"&gt;&lt;/span&gt;
        &lt;span class="ui-range__text-max"&gt;&lt;/span&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiRange from '@/components/ui/uiRange'

export default {
  name: 'FieldsRange',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-range': uiRange,
  },
  data() {
    return {
      value: [20, 60],
      value2: [10, 50],
      value3: [20],
      value4: [40],
    }
  },
}
</script>
