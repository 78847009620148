<template>
  <common-wrapper
    titleWrapper="Breadcrumb"
    description-wrapper="Отображение местоположения текущей страницы в иерархической структуре"
  >
    <template>
      <common-group>
        <template>
          <common-item>
            <ui-breadcrumb :items="breadcrumbs" />

            <template #html-slot>
              <pre>
 &lt;nav class="ui-breadcrumb"&gt;
 &lt;ol class="ui-breadcrumb-list"&gt;
   &lt;li class="ui-breadcrumb-item"&gt;
     &lt;a href="#" class="ui-breadcrumb__text"&gt;
       Главная
     &lt;/a&gt;
   &lt;/li&gt;
   &lt;li class="ui-breadcrumb-item"&gt;
     &lt;a href="#" class="ui-breadcrumb__text"&gt;
       Новости
     &lt;/a&gt;
   &lt;/li&gt;
   &lt;li class="ui-breadcrumb-item"&gt;
     &lt;span class="ui-breadcrumb__text"&gt;
       Корм для животных
     &lt;/span&gt;
   &lt;/li&gt;
 &lt;/ol&gt;
&lt;/nav&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-breadcrumb :items="breadcrumbs" /&gt;

&lt;script&gt;
import uiBreadcrumb from '@/components/ui/uiBreadcrumb.vue'

export default {
  name: 'BreadcrumbPage',
  components: {
    'ui-breadcrumb': uiBreadcrumb,
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Главная', url: '/' },
        { name: 'Каталог', url: '/catalog' },
        { name: 'Корм для животных', url: '/catalog/properties/animal-feed' },
        { name: 'Сухие корма', url: '/catalog/properties/animal-feed/dry-food',
        },
      ],
    }
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;nav class="ui-breadcrumb"&gt;
    &lt;ol class="ui-breadcrumb-list"&gt;
      &lt;li class="ui-breadcrumb-item"
          v-for="(item, index) in items"
          :key="item.id"
      &gt;
        &lt;a v-if="index+1 &lt; items.length"
           :href="item.url"
           class="ui-breadcrumb__text"&gt;
          &#123; &#123; item.name &#125; &#125;
        &lt;/a&gt;
        &lt;span class="ui-breadcrumb__text"
              v-else&gt;
            &#123; &#123; item.name &#125; &#125;
        &lt;/span&gt;
      &lt;/li&gt;
    &lt;/ol&gt;
  &lt;/nav&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    name: "uiBreadcrumb",
    props: {
      items: {
        type: Array
      },
    },
  }
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiBreadcrumb from '@/components/ui/uiBreadcrumb.vue'

export default {
  name: 'BreadcrumbPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-breadcrumb': uiBreadcrumb,
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Главная', url: '/' },
        { name: 'Каталог', url: '/catalog' },
        { name: 'Корм для животных', url: '/catalog/properties/animal-feed' },
        {
          name: 'Сухие корма',
          url: '/catalog/properties/animal-feed/dry-food',
        },
      ],
    }
  },
}
</script>
