import Vue from 'vue'
import Fragment from 'vue-fragment'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import VueTelInput from 'vue-tel-input'
import vSelect from 'vue-select'
import VueStorageHelper from 'vue-storage-helper'
Vue.config.productionTip = false
Vue.use(Fragment.Plugin)
Vue.use(VueTelInput)
Vue.component('v-select', vSelect)
Vue.use(VueStorageHelper, { debug: true })
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
