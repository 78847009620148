<template>
  <common-wrapper titleWrapper="Radio Buttons">
    <template>
      <common-group titleGroup="Classic">
        <template>
          <common-item condition="Не выбрано">
            <template>
              <ui-radio
                variant="ui-radio-primary"
                :checked="false"
                id="radio-no-checked"
                text="Первый вариант"
                name="name"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;label class="ui-radio" for="id"&gt;
  &lt;input id="id" type="radio" checked="checked" class="ui-radio__input" /&gt;
  text
&lt;/label&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-radio
    variant="ui-radio-primary"
    :checked="false"
    :disabled="false"
    id="radio-no-checked"
    text="Первый вариант"
    name="name"
/&gt;

&lt;script&gt;
import uiRadio from '@/components/ui/uiRadio.vue'

export default {
  name: 'CheckboxAndRadioRadios',
  components: {
    'ui-radio': uiRadio,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template>
  &lt;label class="ui-radio" :for="id" :class="radioClass">
    &lt;input
        :id="id"
        type="radio"
        :checked="checked"
        class="ui-radio__input"
        :name="name"
        :disabled="disabled"
    />
    &#123; &#123; text &#125; &#125;
  &lt;/label>
&lt;/template>

&lt;script>
  export default {
    name: 'uiRadio',
    props: {
      id: {
        type: [String, Number],
        default: null,
        required: true,
      },
      text: {
        required: true,
        type: String,
      },
      name: {
        required: false,
        type: String,
      },
      checked: {
        type: Boolean,
        default: null,
        required: false,
      },
      disabled: {
        required: false,
        type: Boolean,
        default: null,
      },
      variant: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      radioClass() {
        return {
          'ui-radio-primary': this.variant === 'ui-radio-primary',
        }
      },
    },
  }
&lt;/script&gt;
                </pre
              >
            </template>
          </common-item>
          <common-item condition="Выбрано">
            <template>
              <ui-radio
                variant="ui-radio-primary"
                :checked="true"
                id="radio-checked2"
                text="Первый вариант"
                name="name"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;label class="ui-radio" for="id"&gt;
  &lt;input id="id" type="radio" checked="checked" class="ui-radio__input" /&gt;
  text
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Disabled">
            <template>
              <ui-radio
                variant="ui-radio-primary"
                :checked="false"
                id="radio-no-checked3"
                text="Первый вариант"
                name="name"
                disabled
              />
            </template>
            <template #html-slot>
              <pre>
&lt;label class="ui-radio" for="id"&gt;
  &lt;input disabled id="id" type="radio" checked="checked" class="ui-radio__input" /&gt;
  text
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group
        titleGroup="Radio Buttons"
        text-group="Для input radio и input checkbox используй 'ui-checkbox-btn', меняя только type"
      >
        <template>
          <common-item condition="Не выбрано">
            <ui-checkbox-button
              variant="ui-checkbox-primary"
              :checked="false"
              id="checkbox-button-no-checked"
              text="Хлебобулочные изделия"
              type="radio"
              name="name1"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input id="id" type="radio" name="name" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-checkbox-button
    variant="ui-checkbox-primary"
    :checked="false"
    id="checkbox-button-no-checked"
    text="Хлебобулочные изделия"
    type="radio"
    name="name1"
/&gt;

&lt;script&gt;
import uiCheckboxButton from '@/components/ui/uiCheckboxButton.vue'

export default {
  name: 'CheckboxAndRadioRadios',
  components: {
    'ui-checkbox-button': uiCheckboxButton,
  },
}
&lt;/script&gt;
</pre
              >
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;label :for="id" :class="checkboxClass" class="ui-checkbox-btn"&gt;
    &lt;input
        :id="id"
        :type="type"
        :checked="checked"
        :disabled="disabled"
        :name="name"
        class="ui-checkbox-btn__input"
    />
    &lt;span class="ui-checkbox-btn__text"&gt;&#123; &#123;text&#125; &#125;&lt;/span&gt;
  &lt;/label&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    name: 'uiCheckboxButton',
    props: {
      id: {
        type: [String, Number],
        default: null,
        required: true,
      },
      type: {
        type: String,
        default: 'checkbox',
        required: true,
      },
      text: {
        required: true,
        type: String,
      },
      checked: {
        type: Boolean,
        default: null,
        required: false,
      },
      disabled: {
        required: false,
        type: Boolean,
        default: null,
      },
      name: {
        required: false,
        type: String,
      },
      variant: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      radioClass() {
        return {
          'ui-checkbox-primary': this.variant === 'ui-checkbox-primary',
        }
      },
    },
  }
&lt;/script&gt;
                </pre
              >
            </template>
          </common-item>
          <common-item condition="Выбрано">
            <template>
              <ui-checkbox-button
                variant="ui-checkbox-primary"
                :checked="true"
                id="checkbox-button-checked2"
                text="Хлебобулочные изделия"
                type="radio"
                name="name2"
              />
            </template>
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input id="id" type="radio" name="name" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Disabled">
            <ui-checkbox-button
              variant="ui-checkbox-primary"
              :checked="false"
              id="checkbox-button-no-checked3"
              text="Хлебобулочные изделия"
              disabled
              type="radio"
              name="name3"
            />
            <template #html-slot>
              <pre>
&lt;label class="ui-checkbox-btn ui-checkbox-primary" for="id">
  &lt;input disabled id="id" type="radio" name="name" class="ui-checkbox-btn__input" value="Согласиться с правилами"/&gt;
  &lt;span class="ui-checkbox-btn__text">text&lt;/span&gt;
&lt;/label&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiRadio from '@/components/ui/uiRadio.vue'
import uiCheckboxButton from '@/components/ui/uiCheckboxButton.vue'

export default {
  name: 'CheckboxAndRadioRadios',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-radio': uiRadio,
    'ui-checkbox-button': uiCheckboxButton,
  },
}
</script>
