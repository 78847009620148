<template>
  <common-wrapper titleWrapper="Filters">
    <template>
      <common-group>
        <template>
          <common-item title-item="Фильтры в меню">
            <div style="width: 182px">
              <filter-sidebar />
            </div>
            <template #html-slot>
              <pre>
&lt;div class="w-full" &gt;
  &lt;div class="w-full mb-5"&gt;
    &lt;p class="font-medium mb-3"&gt;Ширина (см)&lt;/p&gt;
    &lt;div class="grid grid-cols-2 gap-3"&gt;
      &lt;div class="ui-input-unite"&gt;
        &lt;label class="ui-input-unite__label"
               for="filterFrom"&gt;от&lt;/label&gt;
        &lt;input class="ui-input-unite__file"
               id="filterFrom"
        /&gt;
      &lt;/div&gt;
      &lt;div class="ui-input-unite"&gt;
        &lt;label class="ui-input-unite__label"
               for="filterBefore"&gt;до&lt;/label&gt;
        &lt;input class="ui-input-unite__file"
               id="filterBefore"
        /&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
  &lt;div class="w-full mb-5"&gt;
    &lt;p class="font-medium mb-5"&gt;title&lt;/p&gt;
    &lt;div class="w-full mb-3"&gt;
      &lt;label class="ui-checkbox ui-checkbox-primary"
             for="id"
      &gt;
        &lt;input id="id"
               type="checkbox"
               class="ui-checkbox__input"/&gt;
        text 10
      &lt;/label&gt;
    &lt;/div&gt;
     &lt;div class="w-full mb-3"&gt;
      &lt;label class="ui-checkbox ui-checkbox-primary"
             for="id2"
      &gt;
        &lt;input id="id2"
               type="checkbox"
               class="ui-checkbox__input"/&gt;
        text 22
      &lt;/label&gt;
    &lt;/div&gt;
    &lt;button
        class="ui-link text-xs mt-2"
        type="button"
    &gt;
      &lt;span>Еще 4&lt;/span&gt;
      &lt;span class="hidden"&gt;Свернуть&lt;/span&gt;
      &lt;span class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"&gt;
        &lt;/span&gt;
    &lt;/button&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>

&lt;template&gt;
  &lt;div class="w-full" &gt;
    &lt;div class="w-full mb-5"&gt;
      &lt;p class="font-medium mb-3"&gt;Ширина (см)&lt;/p&gt;
      &lt;div class="grid grid-cols-2 gap-3"&gt;
        &lt;div class="ui-input-unite" @click="focusInputFrom"&gt;
          &lt;label class="ui-input-unite__label"
                 for="filterFrom">от&lt;/label&gt;
          &lt;input class="ui-input-unite__file"
                 v-model="valueFrom"
                 ref="inputFrom"
                 id="filterFrom"
          /&gt;
        &lt;/div&gt;
        &lt;div class="ui-input-unite" @click="focusInputBefore"&gt;
          &lt;label class="ui-input-unite__label"
                 for="filterBefore"&gt;до&lt;/label&gt;
          &lt;input class="ui-input-unite__file"
                 v-model="valueBefore"
                 ref="inputBefore"
                 id="filterBefore"
          /&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="w-full mb-5"
         v-for="item in itemsFilter"
         :key="item.text"&gt;
      &lt;p v-if="item.title" class="font-medium mb-5"&gt; &#123; &#123;item.title &#125; &#125; &lt;/p&gt;
      &lt;div class="w-full mb-3"
           v-for="(link, index) of item.checks"
           :key="link.id"
      &gt;
        &lt;label class="ui-checkbox ui-checkbox-primary"
               :for="link.id"
               :class="{ hidden: index &gt; 3 && item.isShow }"
        &gt;
          &lt;input :id="link.id"
                 :disabled="link.disabled"
                 type="checkbox"
                 class="ui-checkbox__input"/&gt;
          &#123; &#123; link.text &#125; &#125; (&#123; &#123; link.count &#125; &#125;)
        &lt;/label&gt;
      &lt;/div&gt;
      &lt;button
          @click="toggleExpand(item)"
          class="ui-link text-xs mt-2"
          type="button"
          v-if="item.checks.length &gt; 4"
      &gt;
        &lt;span v-if="item.isShow"&gt;Еще &#123; &#123; item.checks.length - 4 &#125; &#125; &lt;/span&gt;
        &lt;span v-else&gt;Свернуть&lt;/span>
        &lt;span class="ui-link__ic ml-2 bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
              :class="{ 'rotate-180': !item.isShow }"&gt;
        &lt;/span&gt;
      &lt;/button&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "filterSidebar",
  data(){
    return{
      valueFrom: '10',
      valueBefore: '100',
      itemsFilter: [
        {
          title: 'Тип продукта',
          checks: [
            { id:'id1', text: 'Шкафы', count:'10', disabled:false, },
            { id:'id2', text: 'Стулья', count:'87', disabled:true, },
            { id:'id3', text: 'Кровати', count:'456', disabled:false, },
            { id:'id4', text: 'Стол', count:'2', disabled:false, },
            { id:'id5', text: 'Тумба', count:'22', disabled:false, },
          ],
          isShow: true,
        },
        {
          title: 'Тип продукта 2',
          checks: [
            { id:'id6', text: 'Шкафы', count:'23', disabled:false, },
            { id:'id7', text: 'Стулья', count:'56', disabled:false, },
          ],
          isShow: true,
        },
      ],
    }
  },
  methods: {
    toggleExpand(item) {
      item.isShow = !item.isShow
    },

    focusInputFrom() {
      let inputFrom = this.$refs.inputFrom
      let endFrom = inputFrom.value.length;
      inputFrom.focus();
      inputFrom.setSelectionRange(endFrom, endFrom);
    },

    focusInputBefore() {
      let inputBefore = this.$refs.inputBefore
      let endBefore = inputBefore.value.length;
      inputBefore.focus();
      inputBefore.setSelectionRange(endBefore, endBefore);
    },
  },
}
&lt;/script&gt;

              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>
<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import filterSidebar from '@/components/composite/filterSidebar.vue'
export default {
  name: 'FiltersPage',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'filter-sidebar': filterSidebar,
  },
}
</script>
