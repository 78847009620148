<template>
  <div>
    <p class="mb-6 text-lg font-semibold" v-if="titleItem">
      {{ titleItem }}
    </p>
    <div class="mb-8 rounded-md bg-[var(--b1)] py-6 px-10">
      <p class="mb-6 text-[var(--b4)]">{{ condition }}</p>
      <div class="mb-6">
        <slot></slot>
      </div>
      <div class="flex justify-between">
        <button
          class="mr-auto flex items-center font-semibold"
          type="button"
          @click="clickOpenCode"
        >
          Code
          <svg
            :class="{ 'rotate-180': activeCode }"
            class="ml-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.5286 5.52876C3.78895 5.26841 4.21106 5.26841 4.47141 5.52876L8 9.05735L11.5286 5.52876C11.7889 5.26841 12.2111 5.26841 12.4714 5.52876C12.7318 5.78911 12.7318 6.21122 12.4714 6.47157L8.47141 10.4716C8.21106 10.7319 7.78895 10.7319 7.5286 10.4716L3.5286 6.47157C3.26825 6.21122 3.26825 5.78911 3.5286 5.52876Z"
              fill="#5BC9FC"
            />
          </svg>
        </button>
        <button
          class="mr-4 flex items-center font-semibold"
          type="button"
          @click="clickCopyHtml"
        >
          Copy HTML
          <span
            class="filter-black ml-2 h-4 w-4 bg-[url('./img/ic-doc-text-24.svg')] bg-contain bg-no-repeat"
          ></span>
        </button>
        <button
          v-if="showCss"
          class="mr-4 flex items-center font-semibold"
          type="button"
          @click="clickCopyCss"
        >
          Copy CSS
          <span
            class="filter-black ml-2 h-4 w-4 bg-[url('./img/ic-doc-text-24.svg')] bg-contain bg-no-repeat"
          ></span>
        </button>
        <button
          v-if="showJs"
          class="mr-4 flex items-center font-semibold"
          type="button"
          @click="clickCopyJs"
        >
          Copy JS
          <span
            class="filter-black ml-2 h-4 w-4 bg-[url('./img/ic-doc-text-24.svg')] bg-contain bg-no-repeat"
          ></span>
        </button>
        <button
          v-if="showVueSlotElement"
          class="mr-4 flex items-center font-semibold"
          type="button"
          @click="clickCopyElement"
        >
          Copy Vue Element
          <span
            class="filter-black ml-2 h-4 w-4 bg-[url('./img/ic-doc-text-24.svg')] bg-contain bg-no-repeat"
          ></span>
        </button>
        <button
          v-if="showVueSlotComponent"
          class="flex items-center font-semibold"
          type="button"
          @click="clickCopyComponent"
        >
          Copy Vue component
          <span
            class="filter-black ml-2 h-4 w-4 bg-[url('./img/ic-doc-text-24.svg')] bg-contain bg-no-repeat"
          ></span>
        </button>
      </div>
      <div class="w-full pt-4" v-if="activeCode">
        <!-- html -->
        <div class="mb-5 w-full">
          <p class="mb-2 w-full font-semibold">HTML</p>
          <div class="m-0 w-full" ref="printDataHtml">
            <code-highlight language="javascript">
              <slot name="html-slot"></slot>
            </code-highlight>
          </div>
        </div>
        <!-- css -->
        <div class="mb-5 w-full" v-if="showCss">
          <p class="mb-2 w-full font-semibold">css</p>
          <div class="m-0 w-full" ref="printDataCss">
            <code-highlight language="javascript">
              <slot name="cssSlot"></slot>
            </code-highlight>
          </div>
        </div>
        <!-- js -->
        <div class="mb-5 w-full" v-if="showJs">
          <p class="mb-2 w-full font-semibold">JS</p>
          <div class="m-0 w-full" ref="printDataJs">
            <code-highlight language="javascript">
              <slot name="jsSlot"></slot>
            </code-highlight>
          </div>
        </div>

        <!-- vue element on the page -->
        <div class="mb-5 w-full" v-if="showVueSlotElement">
          <p class="mb-2 w-full font-semibold">Vue элемент</p>
          <div class="m-0 w-full" ref="printDataElement">
            <code-highlight language="javascript">
              <slot name="vueSlotElement"></slot>
            </code-highlight>
          </div>
        </div>

        <!-- vue component -->
        <div class="w-full" v-if="showVueSlotComponent">
          <p class="mb-2 w-full font-semibold">Vue компонент</p>
          <div class="m-0 w-full" ref="printDataComponent">
            <code-highlight language="javascript">
              <slot name="vueSlotComponent"></slot>
            </code-highlight>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue'
import 'vue-code-highlight/themes/prism-tomorrow.css'
import 'vue-code-highlight/themes/window.css'

export default {
  name: 'CommonItem',
  components: {
    CodeHighlight,
  },
  props: {
    titleItem: {
      required: false,
      type: String,
      default: null,
    },
    condition: {
      required: false,
      type: String,
      default: 'No description',
    },
  },
  data() {
    return {
      activeCode: false,
      showCss: false,
      showJs: false,
      showVueSlotElement: false,
      showVueSlotComponent: false,
    }
  },
  created() {
    this.setShowCss()
    this.setShowJs()
    this.setShowVueSlotElement()
    this.setShowVueSlotComponent()
  },
  beforeUpdate() {
    this.setShowCss()
    this.setShowJs()
    this.setShowVueSlotElement()
    this.setShowVueSlotComponent()
  },
  methods: {
    clickOpenCode() {
      this.activeCode = !this.activeCode
    },
    clickCopyHtml() {
      this.$nextTick(() => {
        let printDataHtml = this.$refs['printDataHtml'].innerText
        navigator.clipboard.writeText(printDataHtml)
      })
    },
    clickCopyCss() {
      this.$nextTick(() => {
        let printDataCss = this.$refs['printDataCss'].innerText
        navigator.clipboard.writeText(printDataCss)
      })
    },
    clickCopyJs() {
      this.$nextTick(() => {
        let printDataJs = this.$refs['printDataJs'].innerText
        navigator.clipboard.writeText(printDataJs)
      })
    },
    clickCopyElement() {
      this.$nextTick(() => {
        let printDataElement = this.$refs['printDataElement'].innerText
        navigator.clipboard.writeText(printDataElement)
      })
    },
    clickCopyComponent() {
      this.$nextTick(() => {
        let printDataComponent = this.$refs['printDataComponent'].innerText
        navigator.clipboard.writeText(printDataComponent)
      })
    },
    setShowCss() {
      this.showCss = this.$slots.cssSlot?.[0]
    },
    setShowJs() {
      this.showJs = this.$slots.jsSlot?.[0]
    },
    setShowVueSlotElement() {
      this.showVueSlotElement = this.$slots.vueSlotElement?.[0]
    },
    setShowVueSlotComponent() {
      this.showVueSlotComponent = this.$slots.vueSlotComponent?.[0]
    },
  },
}
</script>

<style scoped></style>
