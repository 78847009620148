<template>
  <div class="ui-textarea">
    <label class="ui-textarea__label" :for="id">{{ label }}</label>
    <textarea
      class="ui-textarea__field"
      type="text"
      :id="id"
      rows="7"
      :placeholder="placeholder"
      :value="value"
      :class="textareaClass"
      :disabled="disabled"
    >
    </textarea>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'uiTextarea',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    placeholder: {
      type: [String, Number],
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    textareaClass() {
      return {
        'border-[#FF5B5B]': this.variant === 'error',
      }
    },
  },
}
</script>

<style scoped></style>
