<template>
  <fragment>
    <span
      v-if="loading || !hasSlotContent"
      class="ui-skeleton"
      :style="{ height, width, marginBottom }"
    />
    <slot v-if="!loading && hasSlotContent"></slot>
  </fragment>
</template>
<script>
export default {
  name: 'uiSkeleton',
  props: {
    height: {
      default: null,
      type: String,
    },
    width: {
      default: null,
      type: String,
    },
    marginBottom: {
      default: null,
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    hasSlotContent() {
      return !!this.$slots.default
    },
  },
}
</script>

<style scoped lang="scss">
.ui-skeleton {
  display: block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #dddbdd;
  width: 100%;
  border-radius: 6px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
