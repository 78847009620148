<template>
  <nav aria-label="Page navigation">
    <ul class="ui-pagination">
      <li class="ui-pagination__item">
        <a
          class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          href="#"
        >
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="ui-pagination__item">
        <a class="ui-pagination__item--link" href="#">1</a>
      </li>
      <li class="ui-pagination__item active">
        <a class="ui-pagination__item--link" href="#" aria-current="page">2</a>
      </li>
      <li class="ui-pagination__item">
        <a class="ui-pagination__item--link" href="#">3</a>
      </li>
      <li class="ui-pagination__item">
        <a class="ui-pagination__item--link" href="#">4</a>
      </li>

      <li v-if="more" class="ui-pagination__item">
        <span class="ui-pagination__item--link bg-transparent">...</span>
      </li>
      <li v-if="more" class="ui-pagination__item">
        <a class="ui-pagination__item--link" href="#">{{ more }}</a>
      </li>

      <li class="ui-pagination__item">
        <a
          class="ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]"
          href="#"
        >
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'uiPagination',
  props: {
    more: {
      required: false,
      type: String,
    },
  },
}
</script>

<style scoped></style>
