<template>
  <common-wrapper
    title-wrapper="Tooltip"
    description-wrapper="Используйте всплывающие подсказки, чтобы отображать дополнительный контент при наведении курсора или фокусе на элементе."
  >
    <template>
      <common-group>
        <template>
          <common-item>
            <div class="pl-[80px] pt-[80px]">
              <div class="relative inline-flex">
                <button
                  @click="openTooltip"
                  type="button"
                  class="ui-btn ui-btn-primary"
                >
                  Открыть
                </button>
                <ui-tooltip
                  v-if="hide"
                  text="Вы действительно хотите удалить аккаунт"
                />
              </div>
            </div>
            <template #html-slot>
              <pre>
&lt;div class="relative inline-flex"&gt;
  &lt;button type="button" class="ui-btn ui-btn-primary" data-dynamic-show="tooltip-1" &gt;Открыть&lt;/button&gt;

  &lt;div class="ui-tooltip" id="tooltip-1"&gt;
    &lt;div class="ui-tooltip-container"&gt;
      &lt;p class="mb-0 text-sm"&gt;text&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #jsSlot>
              <pre>
          let initDynamicShown = function () {
            let showDynamicButtons = document.querySelectorAll("[data-dynamic-show]"),
                closesDynamicButtons = document.querySelectorAll("[data-close]");

            if (showDynamicButtons) {
                showDynamicButtons.forEach(button => {
                    let dynamicShow = button.dataset.dynamicShow
                    let dynamicBox = document.getElementById(dynamicShow)

                    // открытие
                    button.addEventListener("click", function () {
                        dynamicBox.classList.toggle("hidden")
                    });

                    // закрытие клик вне элемента
                    document.addEventListener('click', (e) => {
                        const withinBoundaries = e.composedPath().includes(dynamicBox);
                        const withinBoundaries2 = e.composedPath().includes(button);

                        !withinBoundaries && !withinBoundaries2 ? dynamicBox.classList.add("hidden") : null

                    });

                    // закрытие атрибутом data-close
                    let closeDynamic = function (close) {
                        close.addEventListener("click", () => {
                            dynamicBox.classList.add("hidden")
                        });
                    }
                    closesDynamicButtons.forEach(close => {
                        closeDynamic(close)
                    });
                });
            }
        }
            </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;button
    @click="openTooltip"
    type="button"
    class="ui-btn ui-btn-primary"
&gt;
    Открыть
&lt;/button&gt;

&lt;ui-tooltip
    v-if="hide"
    text="Вы действительно хотите удалить аккаунт"
/&gt;

&lt;script&gt;
import uiTooltip from '@/components/ui/uiTooltip.vue'
export default {
  name: 'TooltipElement',
  components: {
    'ui-tooltip': uiTooltip,
  },
  data() {
    return {
      hide: false,
    }
  },
  methods: {
    openTooltip() {
      this.hide = !this.hide
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-tooltip"&gt;
    &lt;div class="ui-tooltip-container"&gt;
      &lt;p class="mb-0 text-sm"&gt;
        &#123; &#123; text &#125; &#125;
      &lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: 'uiTooltip',
  props: {
    text: {
      required: true,
      type: String,
    },
  },
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiTooltip from '@/components/ui/uiTooltip.vue'
export default {
  name: 'TooltipElement',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-tooltip': uiTooltip,
  },
  data() {
    return {
      hide: false,
      hide2: false,
    }
  },
  methods: {
    openTooltip() {
      this.hide = !this.hide
    },
    openTooltip2() {
      this.hide2 = !this.hide2
    },
  },
}
</script>
