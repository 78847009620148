<template>
  <common-wrapper title-wrapper="Rating text">
    <template>
      <common-group>
        <template>
          <common-item condition="Низкий балл">
            <ui-rating-text value="2" />
            <template #html-slot>
              <pre>
&lt;div class="ui-rating-text"&gt;2.0&lt;/div&gt;
              </pre>
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-rating-text value="2" /&gt;

&lt;script&gt;
import uiRatingText from '@/components/ui/uiRatingText.vue'

export default {
  name: 'RatingText',
  components: {
    'ui-rating-text': uiRatingText,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
  &lt;div class="ui-rating-text " :class="{'bg-[var(--w)]': value &gt;= 3 && value &lt; 4 , 'bg-[var(--su)]': value &gt;= 4 }"&gt;
    &#123; &#123; value &#125; &#125;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  name: "uiRatingText",
  props:{
    value:{
      type: [Number, String],
      required: true,
      default: 0,
    }
  }
}
&lt;/script&gt;
              </pre>
            </template>
          </common-item>
          <common-item condition="Средний балл">
            <ui-rating-text value="3.1" />
            <template #html-slot>
              <pre>
&lt;div class="ui-rating-text bg-[var(--w)]"&gt;3.1&lt;/div&gt;</pre
              >
            </template>
          </common-item>
          <common-item condition="Высокий балл">
            <ui-rating-text value="4.8" />
            <template #html-slot>
              <pre>
&lt;div class="ui-rating-text bg-[var(--w)]"&gt;4.7&lt;/div&gt;</pre
              >
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper.vue'
import CommonGroup from '@/components/common/CommonGroup.vue'
import CommonItem from '@/components/common/CommonItem.vue'
import uiRatingText from '@/components/ui/uiRatingText.vue'

export default {
  name: 'RatingText',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-rating-text': uiRatingText,
  },
}
</script>
