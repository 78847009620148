<template>
  <div class="ui-toasts">
    <button
      @click="close"
      type="button"
      class="ui-btn-close bg-[url('./img/ic-cross-24.svg')]"
    ></button>
    <p v-if="title" class="mb-4 w-full text-lg font-semibold">{{ title }}</p>
    <p v-if="text" class="mb-0 w-full">{{ text }}</p>
    <slot name="toasts-slot"></slot>
  </div>
</template>
<script>
export default {
  name: 'uiToasts',
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
