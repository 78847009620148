<template>
  <div class="ui-input">
    <label class="ui-input__label">{{ label }}</label>
    <v-select
      :options="options"
      :class="{ error: error }"
      :disabled="disabled"
      :multiple="multiple"
    ></v-select>
    <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'uiSelect',
  props: {
    error: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
    },
  },
}
</script>

<style lang="scss">
.v-select {
  width: 100%;
  &.error {
    .vs__dropdown-toggle {
      .vs__selected-options {
        border: 1px solid var(--e);
      }
    }
  }
  .vs__dropdown-toggle {
    width: 100%;
    position: relative;
    .vs__selected-options {
      width: 100%;
      border: 1px solid var(--b4);
      border-radius: 6px;
      padding: 0 48px 0 16px;
      height: 56px;
      display: flex;
      align-items: center;
      .vs__selected {
        white-space: nowrap;
        font-size: 16px;
        line-height: 140%;
        color: var(--bc);
        margin-right: 16px;
        display: flex;
        align-items: center;
        .vs__deselect {
          width: 16px;
          height: 16px;
          background-image: url('@/assets/img/ic-cross-24.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 4px;
          filter: var(--filter-black);
          svg {
            display: none;
          }
        }
      }
      .vs__search {
        width: 0;
        background: transparent;
        height: 100%;
        font-size: 16px;
        line-height: 140%;
        color: #000;
      }
    }

    .vs__actions {
      .vs__clear {
        display: none;
      }

      .vs__open-indicator {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url('@/assets/img/ic-arrow-navigate-bottom-24.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 2;
        filter: var(--filter-black);
        path {
          display: none;
        }
      }

      .vs__spinner {
        display: none;
      }
    }
  }

  .vs__dropdown-menu {
    background: #f0f6f6;
    border-radius: 6px;
    margin-top: 8px;

    .vs__dropdown-option {
      padding: 16px 24px;
      font-size: 16px;
      line-height: 140%;
      color: #2d3236;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }

    .vs__dropdown-option--highlight {
      color: #fff;
      background: var(--p);
    }

    .vs__dropdown-option--disabled {
      color: #fff;
      background: var(--b4);
    }
    .vs__no-options {
      color: #000;
      padding: 8px;
    }
  }

  &.vs--disabled {
    .vs__dropdown-toggle .vs__selected-options {
      background: var(--b3);
      border-color: var(--b4);
    }
  }

  &.vs--multiple {
    .vs__dropdown-toggle .vs__selected-options {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      .vs__selected {
        display: inline;
        vertical-align: middle;
        .vs__deselect {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
