<template>
  <div class="ui-accordion">
    <div class="ui-accordion-item" v-for="item in items" :key="item.id">
      <button
        @click.prevent="toggleExpand(item)"
        class="ui-accordion__btn"
        type="button"
      >
        {{ item.title }}
        <span
          class="ui-accordion__btn--arr"
          :class="{ 'rotate-180': item.isExpand }"
        ></span>
      </button>
      <div
        class="ui-accordion__dropdown"
        :ref="'content' + item.id"
        v-if="item.isExpand"
      >
        {{ item.content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uiAccordion',
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    toggleExpand(item) {
      item.isExpand = !item.isExpand
    },
  },
}
</script>
