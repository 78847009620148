<template>
  <common-wrapper
    title-wrapper="Phone Fields"
    description-wrapper="Используем vue-tel-input или intl-tel-input"
  >
    <template>
      <common-group title-group="vue-tel-input">
        <template>
          <common-item>
            <ui-phone label="Телефон" />

            <template #html-slot>
              <pre>

&lt;div class="ui-input"&gt;
  &lt;label for="name" class="ui-input__label"&gt;
    Телефон
  &lt;/label&gt;
  &lt;vue-tel-input
     :inputOptions="options"
     :validCharactersOnly="true"
     v-model="phone"
     styleClasses="leading-none w-full rounded-md border border-[var(--b4)] bg-[transparent] p-4 text-base disabled:border-[var(--b4)] disabled:bg-[var(--b3)]"
     :disabled="disabled"
 /&gt;
  &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;
     error
  &lt;/span&gt;
&lt;/div&gt;

               </pre
              >
            </template>
            <template #vueSlotElement>
              <pre>
&lt;ui-phone label="Телефон" /&gt;

&lt;script&gt;
import uiPhone from '@/components/ui/uiPhone'

export default {
  name: 'FieldsInputPhone',
  components: {
    'ui-phone': uiPhone,
  },
}
&lt;/script&gt;
              </pre>
            </template>
            <template #vueSlotComponent>
              <pre>
&lt;template&gt;
    &lt;div class="ui-input"&gt;
      &lt;label for="name" class="ui-input__label"&gt;
       &#123; &#123; label &#125; &#125;
      &lt;/label&gt;
      &lt;vue-tel-input
          :inputOptions="options"
          :validCharactersOnly="true"
          v-model="phone"
          styleClasses="leading-none w-full rounded-md border border-[var(--b4)] bg-[transparent] p-4 text-base disabled:border-[var(--b4)] disabled:bg-[var(--b3)]"
          :disabled="disabled"
      /&gt;
      &lt;span v-if="error" class="ui-text-invalid block pt-3"&gt;
          &#123; &#123; error &#125; &#125;
      &lt;/span&gt;
    &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import 'vue-tel-input/dist/vue-tel-input.css'
export default {
  name: 'uiPhone',

  data() {
    return {
      phone: null,
      options: {
        placeholder: '',
        maxlength: 25,
        showDialCode: true,
      },
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
      required: false,
    },
  },
}
&lt;/script&gt;
&lt;style&gt;
.vti__dropdown{
  color: #000;
  background-color: transparent !important;
}
&lt;/style&gt;
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
      <common-group title-group="intl-tel-input">
        <template>
          <common-item>
            <ui-phone label="Телефон" />
            <template #html-slot>
              <pre>
&lt;div class="ui-input"&gt;
    &lt;label class="ui-input__label" for="name"&gt;Телефон&lt;/label&gt;
    &lt;div class="ui-input__field"&gt;
        &lt;input maxlength="25" class="ui-input__field--input mask-phone" type="tel"/&gt;
    &lt;/div&gt;
&lt;/div&gt;
              </pre>
            </template>
            <template #jsSlot>
              <pre>
import intlTelInput from 'intl-tel-input';
let initPhoneMask = function () {
    const maskPhones = document.getElementsByClassName("mask-phone");
    if (maskPhones) {
        for (let phone of maskPhones) {
            window.intlTelInput(phone, {
                separateDialCode: true,
                preferredCountries: ["ru"],
                initialCountry: "RU",
            });
        }
    }
}
              </pre>
            </template>
          </common-item>
        </template>
      </common-group>
    </template>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/common/CommonWrapper'
import CommonGroup from '@/components/common/CommonGroup'
import CommonItem from '@/components/common/CommonItem'
import uiPhone from '@/components/ui/uiPhone'

export default {
  name: 'FieldsInputPhone',
  components: {
    'common-wrapper': CommonWrapper,
    'common-group': CommonGroup,
    'common-item': CommonItem,
    'ui-phone': uiPhone,
  },
}
</script>
