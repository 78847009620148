var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"aria-label":"Page navigation"}},[_c('ul',{staticClass:"ui-pagination"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),(_vm.more)?_c('li',{staticClass:"ui-pagination__item"},[_c('span',{staticClass:"ui-pagination__item--link bg-transparent"},[_vm._v("...")])]):_vm._e(),(_vm.more)?_c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item--link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.more))])]):_vm._e(),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]",attrs:{"href":"#"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item--link",attrs:{"href":"#"}},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item active"},[_c('a',{staticClass:"ui-pagination__item--link",attrs:{"href":"#","aria-current":"page"}},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item--link",attrs:{"href":"#"}},[_vm._v("3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item--link",attrs:{"href":"#"}},[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ui-pagination__item"},[_c('a',{staticClass:"ui-pagination__item-arr bg-[url('./img/ic-arrow-navigate-bottom-24.svg')]",attrs:{"href":"#"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Next")])])])
}]

export { render, staticRenderFns }